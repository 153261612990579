const processPhoneNumber = (phoneNumber) => {
    // Проверяем, соответствует ли номер условиям
    const regex = /^(8|7)\d{10}$/; // номер начинается с 8 или 9 и длина 11 символов
    const regex10 = /^\d{10}$/; // номер длиной 10 символов

    if (regex.test(phoneNumber)) {
        return phoneNumber.slice(1);
    } else if (regex10.test(phoneNumber)) {
        return phoneNumber;
    } else {
        return phoneNumber;
    }
}

export const getContactsToVerify = (setVerifyData, contacts) => {
    if (contacts) {
        if (!contacts.email_verified && !contacts.phone_verified) {
            setVerifyData({
                type: 'all',
                nextStep: 2,
                contacts: {
                    phone: contacts.new_phone,
                    email: contacts.new_email,
                },
            });
        }
        if (contacts.email_verified && !contacts.phone_verified) {
            setVerifyData({
                type: 'phone',
                nextStep: 3,
                contacts: {
                    phone: contacts.new_phone,
                    email: null,
                },
            });
        }
        if (!contacts.email_verified && contacts.phone_verified) {
            setVerifyData({
                type: 'email',
                nextStep: 3,
                contacts: {
                    phone: null,
                    email: contacts.new_email,
                },
            });
        }
    }
    if (contacts.new_phone && !contacts.new_email) {
        if (!contacts.phone_verified) {
            // setIsVerify(true);
            setVerifyData({
                type: 'phone',
                nextStep: 3,
                contacts: {
                    phone: contacts.new_phone,
                    email: null,
                },
            });
        }
    }
    if (!contacts.new_phone && contacts.new_email) {
        if (!contacts.email_verified) {
            // setIsVerify(true);
            setVerifyData({
                type: 'email',
                nextStep: 3,
                contacts: {
                    phone: null,
                    email: contacts.new_email,
                },
            });
        }
    }
};

export const changeUserInfo = (
    contactsPayload,
    setError,
    setChangeContactsPayload,
    setUserProfile,
    dispatch,
    setRefetch,
) => {
    const accessToken = localStorage.getItem('access_token');
    const userAvatar = contactsPayload.avatar;
    const userData = { ...contactsPayload };
    delete userData.avatar;
    if (contactsPayload.phone) {
        userData.phone = processPhoneNumber(contactsPayload.phone);
    }

    const isUserDataNoneEmpty = Object.keys(userData).length > 0;

    if (isUserDataNoneEmpty) {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my`, {
            method: 'PUT',
            headers: {
                authorization: `Bearer ${accessToken}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
            .then((res) => {
                return res.json();
            })
            .then((response) => {
                if (response.msg) {
                    setError(response.msg);
                }
                if (response) {
                    console.log(response, 'response');
                    dispatch(setUserProfile(response));
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setChangeContactsPayload({});
                setRefetch(true);
            });
    }

    if (userAvatar) {
        const formData = new FormData();
        formData.append('image', userAvatar);

        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my/add_image`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            },
        )
            .then((response) => response.json())
            .then((data) => {
                console.log('Успех:', data);
            })
            .catch((error) => {
                console.error('Ошибка:', error);
            })
            .finally(() => {
                setRefetch(true);
            });
    }
};

// if (response.new_phone && response.new_email) {
//     setIsVerify(true);
//     setError('');
//     if (!response.email_verified && !response.phone_verified) {
//         setVerifyData({
//             type: 'all',
//             nextStep: 2,
//             contacts: {
//                 phone: response.new_phone,
//                 email: response.new_email,
//             },
//         });
//     }
//     if (response.email_verified && !response.phone_verified) {
//         setVerifyData({
//             type: 'phone',
//             nextStep: 3,
//             contacts: {
//                 phone: response.new_phone,
//                 email: null,
//             },
//         });
//     }
//     if (!response.email_verified && response.phone_verified) {
//         setVerifyData({
//             type: 'email',
//             nextStep: 3,
//             contacts: {
//                 phone: null,
//                 email: response.new_email,
//             },
//         });
//     }
// }
// if (response.phone && !response.email) {
//     if (!response.phone_verified) {
//         setIsVerify(true);
//         setVerifyData({
//             type: 'phone',
//             nextStep: 3,
//             contacts: {
//                 phone: response.new_phone,
//                 email: null,
//             },
//         });
//     }
// }
// if (!response.new_phone && response.new_email) {
//     if (!response.email_verified) {
//         setIsVerify(true);
//         setVerifyData({
//             type: 'email',
//             nextStep: 3,
//             contacts: {
//                 phone: null,
//                 email: response.new_email,
//             },
//         });
//     }
// }
