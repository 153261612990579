import React, { useEffect, useState } from 'react';
import moment from 'moment';

const formattedTime = (secs) => {
    const currentTime = moment();
    const endDay = moment().endOf('day');
    const difference = moment.duration(endDay.diff(currentTime));

    const hours = difference.hours().toString().padStart(2, '0');
    const minutes = difference.minutes().toString().padStart(2, '0');
    const seconds = difference.seconds().toString().padStart(2, '0');
    return `${hours}ч ${minutes}м ${seconds}с`;
};

export const Timer = () => {
    const [time, setTime] = useState(60);
    useEffect(() => {
        if (time <= 0) return;
        let timerId = setTimeout(function tick() {
            setTime((prev) => prev - 1);
            timerId = setTimeout(tick, 1000);
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, []);
    const timeFormatted = formattedTime(time);
    return <p>{timeFormatted}</p>;
};
