import React from 'react';
import cn from 'classnames';
import styles from '../index.module.css';
import { Link } from 'react-router-dom';
import { S3Context } from '../../../context/S3Context';
import { ShortenNumber } from '../../../utils/ShortenNumber';

const CertificatesItem = ({ certificate, view = 'big', invited = false, filterOpen = false }) => {
    const {
        id,
        name,
        price,
        file_1,
        investor_royalty: investorRoyalty,
        purchased,
        price_type,
        percent,
    } = certificate;
    const {
        actions: { getImageBacketPath },
    } = React.useContext(S3Context);

    return (
        <div
            className={cn(styles.certificateItemWrapper, styles[view], {
                [styles.invited]: invited,
                [styles.filterOpen]: filterOpen,
            })}
        >
            <div className={styles.brandItem}>
                {view !== 'small' && (
                    <img
                        src="/assets/img/element-fire.png"
                        alt="decor"
                        className={styles.brandItemDecor}
                    />
                )}

                <Link to={`/token/${id}`} className={styles.certificateItemImgInner}>
                    <img src={file_1} alt={name} />

                    {invited && (
                        <img
                            src="/assets/img/loading.svg"
                            alt="loading"
                            className={styles.certificateItemLoading}
                        />
                    )}
                </Link>

                {view !== 'small' && (
                    <div className={styles.certificateItemInfo}>
                        <p className={styles.brandItemInfoName}>{name}</p>

                        {!purchased && !invited && (
                            <div className={styles.certificateItemInfoTags}>
                                <p className={styles.brandItemInfoTag}>
                                    {percent
                                        ? `${percent}% стоимости авто`
                                        : `${Math.floor(price / 100).toLocaleString('ru-RU')} RUB`}
                                </p>
                            </div>
                        )}

                        {!purchased && !invited ? (
                            <p className={styles.certificateItemText}>Оформить на свой авто</p>
                        ) : (
                            <p className={styles.certificateItemTextBuyed}>
                                {invited ? 'Отправлен пользователю' : 'Куплен за'}
                            </p>
                        )}

                        {!purchased && !invited ? (
                            <Link className={styles.certificateItemButton} to={`/token/${id}`}>
                                Оформить
                            </Link>
                        ) : (
                            <div className={styles.certificateItemButtonBuyed}>
                                {Math.floor(price / 100).toLocaleString('ru-RU')} RUB
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CertificatesItem;
