import { PARENT_ENTITES } from '../consts/useGenerateRoutes.consts';
export const fetchByID = async (idArray,dispatch, setParentsList) => {
    console.log(idArray,dispatch, setParentsList)
    dispatch(
        setParentsList(
            await Promise.all(
                idArray.map(async (route, index) => {
                    if (route.id) {
                        const entity = await fetchEntity(route);
                        if (entity.name && entity.id) {
                            return {
                                name: entity.name,
                                id: entity.id,
                                type: PARENT_ENTITES[index].type,
                            };
                        }
                    }
                    if (!route.id) {
                        return {
                            name: route.title,
                            type: PARENT_ENTITES[index].type,
                        };
                    }
                }),
            ),
        ),
    );
};

const fetchEntity = async (route) => {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/${route.title}/${route.id}`,
        {
            headers: {
                authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        },
    );

    const res = await response.json();

    return { name: res.name, id: res.id };
};
