import React, {useContext, useEffect, useState} from 'react';
import {ReactComponent as Arrow } from '../../assets/img/Arrow.svg'

import './index.css';
import {copyText} from "../../functions/utils";
import NOTIFICATION_TYPES from "../../const/notifications/NOTIFICATION_TYPES";
import {NotificationContext} from "../../context/NotificationContext";
import {StartCooperationModal} from "../../components/StartCooperationModal/StartCooperationModal";
import {SuccessModal} from "../../components/StartCooperationModal/SuccessModal";
import {useNavigate} from "react-router-dom";
import {useGetPacksQuery, useGetTokenQuery, useGetTokensQuery} from "../../redux/api/dataService";
import Loader from "../../common/Loader";
import {Spinner} from "../../common/Spinner";
import { Footer } from './components/Footer';

const Main = () => {
  const [isAllTokensBusy, setIsAllTokensBusy] = useState(false)
  const [isStartCooperation, setIsStartCooperation] = useState(false)
  const [isSuccessModal, setIsSuccessModal] = useState(false)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [site, setSite] = useState('')

  const [randomToken, setRandomToken] = useState()


  const { data: tokens, isLoading } = useGetTokensQuery({
    page: 1,
    pageSize: 1000,
  });

  const getRandomObject = (arr) => {
    const randomIndex = Math.floor(Math.random() * arr.length);
    const randomObject = arr[randomIndex];
    setRandomToken(randomObject);
  };


  const naviagte = useNavigate()

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const copyAddress = () => {
        copyText("https://checkbrand.site/");
        addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            text: 'Адрес скопирован!',
        });
    }

    useEffect(() => {
      if(tokens && tokens.results && tokens.results.length) {
        const freeTokens = tokens.results.filter(el => !el.paid)

        if(freeTokens.length) {
          getRandomObject(freeTokens)
        } else {
          setIsAllTokensBusy(true)
          getRandomObject(tokens.results)
        }
      }
    }, [tokens])

    useEffect(() => {
      return () => {
        setIsAllTokensBusy(false)
      }
    }, [])

    return (
      <>
          <section className="main">
              <div className="main__banner">
                  <img src="/assets/img/main-img.jpg" alt="banner" className="main__banner--img" />
              </div>
            <button
              disabled={isLoading}
              onClick={() => {
                if (randomToken) {
                  if(!isAllTokensBusy) {
                    naviagte(`token/${randomToken.id}`)
                  } else {
                    naviagte(`collection/${randomToken.collection.id}`)
                  }
                }
              }}
              style={{
                background: 'hsla(210, 76%, 50%, 1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '40px',
                width: '260px',
                float: 'left',
                marginTop: '20px',
                border: "none",
                cursor: 'pointer'
              }}
            >
              {isLoading
                ? <Spinner />
                : <>
                    <img
                      src="/assets/img/arrowCertificate.png"
                      alt="banner"
                    />
                    <p
                      style={{
                        color: 'white',
                        fontWeight: 600,
                        marginLeft: '10px',
                      }}
                    >
                      В сертификат
                    </p>
                  </>
              }

            </button>
              <div className="container">

                  <div className="main__inner">
                      <h1 className="main__title">Платформа для выпуска цифровых сертификатов</h1>

                      {/*<Link to="modal" className="main__text">*/}
                      {/*    Модалки*/}
                      {/*</Link>*/}

                      <p className="main__text">Производите выпуск сертификатов для коллабораций включающих в себя:</p>

                      <div className="main__content first">
                          <div className="main__item">
                              <img
                                src="/assets/img/main-img1.jpg"
                                alt="banner"
                                className="main__item--img"
                              />

                              <p className="main__item--text">Проход на частные вечеринки</p>
                          </div>

                          <div className="main__item">
                              <img
                                src="/assets/img/main-img2.jpg"
                                alt="banner"
                                className="main__item--img"
                              />

                              <p className="main__item--text">Проход в бизнес залы аэропортов</p>
                          </div>

                          <div className="main__item">
                              <img
                                src="/assets/img/main-img3.jpg"
                                alt="banner"
                                className="main__item--img"
                              />

                              <p className="main__item--text">Проход на концерты любимых звёзд</p>
                          </div>
                      </div>

                      <div className="main__grid">
                          <div className="main__grid--content horiz">
                              <div className="main__grid--item text">
                                  <p className="text__orange main__grid--item--text">
                                      <span className="orange">ВИП</span> СЕРВИС
                                  </p>
                              </div>

                              <div className="main__grid--item">
                                  <img
                                    src="/assets/img/vip1.jpg"
                                    alt="banner"
                                    className="main__grid--img"
                                  />

                                  <p className="main__item--text">В кафе<br/> и ресторанах</p>
                              </div>

                              <div className="main__grid--item">
                                  <img
                                    src="/assets/img/vip2.jpg"
                                    alt="banner"
                                    className="main__grid--img"
                                  />

                                  <p className="main__item--text" style={{maxWidth: '200px'}}>
                                      В банках<br/> и страховых компаниях
                                  </p>
                              </div>
                          </div>

                          <div className="main__grid--content verti">
                              <div className="main__grid--item">
                                  <img
                                    src="/assets/img/discount1.jpg"
                                    alt="banner"
                                    className="main__grid--img"
                                  />

                                  <div className="main__grid--text--inner">
                                      <p className="main__grid--text--title">СКИДКИ</p>

                                      <p className="main__grid--text--text">На товары</p>
                                  </div>
                              </div>

                              <div className="main__grid--item">
                                  <img
                                    src="/assets/img/discount2.jpg"
                                    alt="banner"
                                    className="main__grid--img"
                                  />

                                  <div className="main__grid--text--inner">
                                      <p className="main__grid--text--title">СКИДКИ</p>

                                      <p className="main__grid--text--text">На контент</p>
                                  </div>
                              </div>

                              <div className="main__grid--item">
                                  <img
                                    src="/assets/img/discount3.jpg"
                                    alt="banner"
                                    className="main__grid--img"
                                  />

                                  <div className="main__grid--text--inner">
                                      <p className="main__grid--text--title">СКИДКИ</p>

                                      <p className="main__grid--text--text">На услуги</p>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="main__block">
                          <p className="main__subtitle">
                              Вместе с сертификатом пользователь получит QR-код
                          </p>

                          <p className="main__subtext">
                              Предоставляющий доступ ко всем вышеперечисленным возможностям
                          </p>

                          <div className="main__content">
                              <div className="main__item">
                                  <img
                                    src="/assets/img/main-img4.jpg"
                                    alt="banner"
                                    className="main__item--img"
                                  />

                                  <div className="main__item--text text__orange qr">
                                      <span className="orange">Сертификат</span> + QR
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="main__block">
                          <p className="main__subtitle">Бренды компаний</p>

                          <p className="main__subtext">Успешно выпустившие сертификаты на рынок:</p>
                          <div className={'brandWrapper'}>
                              <img
                                src="/assets/img/brand1.jpg"
                                alt="brand"
                                className="brand_img"
                              />
                              <img
                                src="/assets/img/brand2.jpg"
                                alt="brand"
                                className="brand_img"
                              />
                              <img
                                src="/assets/img/brand3.jpg"
                                alt="brand"
                                className="brand_img"
                              />
                              <img
                                src="/assets/img/brand4.jpg"
                                alt="brand"
                                className="brand_img"
                              />
                              <img
                                src="/assets/img/brand5.jpg"
                                alt="brand"
                                className="brand_img"
                              />
                              <img
                                src="/assets/img/brand6.jpg"
                                alt="brand"
                                className="brand_img"
                              />
                          </div>
                      </div>

                      <div className="main__block">
                          <p className="main__subtitle">Этапы действий для сотрудничества:</p>

                          <div className="main__three">
                              <div className="main__three--content">
                                  <p className="main__three--text">
                                      1. Определитесь с брендом
                                  </p>

                                  <div className="main__item--three">
                                      <img
                                        src="/assets/img/main-img5.jpg"
                                        alt="banner"
                                        className="main__item--img"
                                      />
                                  </div>
                              </div>

                              <div className="main__three--content">
                                  <p className="main__three--text">2. Включитесь в процесс</p>

                                  <div className="main__item--three">
                                      <img
                                        src="/assets/img/main-img6.jpg"
                                        alt="banner"
                                        className="main__item--img"
                                      />
                                  </div>
                              </div>

                              <div className="main__three--content">
                                  <p className="main__three--text">
                                      3. Отправьте заявление
                                  </p>

                                  <div className="main__item--three">
                                      <img
                                        src="/assets/img/main-img7.jpg"
                                        alt="banner"
                                        className="main__item--img"
                                      />

                                      <p className="main__item--text--banner" style={{  fontSize: '14px' }}>
                                          Модератор <br />
                                          свяжется с вами
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="main__collection--link">
                          <Arrow />
                          <button onClick={() => setIsStartCooperation(true)} style={{border: 'none'}}>
                              Начать сотрудничество
                          </button>
                          <span style={{fontSize: '14px'}}>Что бы узнать больше</span>
                      </div>


                      <div className="main__block">
                          <p className="main__subtitle">В ближайшем будущем:</p>

                          <p className="main__subtext">
                              Отдавайте сертификаты клиентам в магазине вместе с продукцией
                          </p>

                          <div className="main__content first">
                              <div className="main__item">
                                  <img
                                    src="/assets/img/main-img8.jpg"
                                    alt="banner"
                                    className="main__item--img"
                                  />
                              </div>
                              <span
                                style={{
                                    color:'hsla(210, 13%, 91%, 0.8)',
                                    marginTop: '12px',
                                    marginRight: 'auto',
                                    fontSize: '14px'
                                }}
                              >
                            Это увеличит вторичные продажи
                        </span>
                          </div>

                      </div>
                  </div>
              </div>
              <Footer copyAddress={copyAddress} className={'footer'} />
          </section>
          {/* <StartCooperationModal
            isStartCooperation={isStartCooperation}
            setIsStartCooperation={setIsStartCooperation}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            name={name}
            setName={setName}
            site={site}
            setSite={setSite}
            setIsSuccessModal={setIsSuccessModal}
          /> */}
          <SuccessModal
            isSuccessModal={isSuccessModal}
            setIsSuccessModal={setIsSuccessModal}
            name={name}
            email={email}
            setSite={setSite}
            setName={setName}
            setPhone={setPhone}
            setEmail={setEmail}
          />
      </>

    );
};

export default Main;
