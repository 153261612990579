const STATS_MENU_ITEMS = [
    {
        to: 'whitelist',
        label: 'White List',
    },
    {
        to: 'activity',
        label: 'Activity',
    },
    {
        to: 'statistics',
        label: 'Statistics',
    },
];

const CREATE_MENU_ITEMS = [
    {
        to: 'createpage',
        label: 'Страница',
    },
    {
        to: 'createaccount',
        label: 'Бренд',
    },
    {
        to: 'createcollection',
        label: 'Коллекция',
    },
    {
        to: 'createpack',
        label: 'Пакет',
    },
    {
        to: 'createitem?type=token',
        label: 'Токен',
    },
    {
        to: 'createcompany',
        label: 'Компания',
    },
];

const RESOURCES_MENU_ITEMS = [
    {
        to: '/rankings',
        label: 'Rankings',
    },
    {
        to: '/whithdraw',
        label: 'Whithdraw',
    },
    {
        to: '/opportunities',
        label: 'Opportunities',
    },
    {
        to: '/about',
        label: 'About',
    },
    {
        to: '/whitepaper',
        label: 'White Paper',
    },
    {
        to: '/cbcoin',
        label: 'Buy CBCOIN'
    }
];

export { STATS_MENU_ITEMS, CREATE_MENU_ITEMS, RESOURCES_MENU_ITEMS };
