import React, { useCallback, useState, useEffect, memo } from 'react';
import { InputNew } from '../../../../common/InputNew';
import { DescriptionBlock } from '../../../../components/DescriptionBlock';
import styles from '../../index.module.css';
import cn from 'classnames';
import ModalNew from '../../../../common/ModalNew';
import base from '../../../../styles/base.module.css';
import { handleFetchCodeByType } from '../../utils/utils';
import { handleUpdateUserContacts } from '../../utils/utils';
import { handleSendCodeByVerifyStep } from './utils';
import { shallowEqual, useSelector } from 'react-redux';
import { RepeatCode } from './components/RepeatCode';
export const VerifyContacts = ({ verifyProfileModal, setVerifyProfileModal }) => {
    const verifyData = useSelector((state) => state.auth.verifyData, shallowEqual);
    const { type, contacts } = verifyData;
    const [error, setError] = useState('');
    const [verifyStep, setVerifyStep] = useState(1);
    const [code, setCode] = useState({
        email: '',
        phone: '',
    });
    console.log(verifyData)
    useEffect(() => {
        if (type === 'all' || type === 'email') {
            setVerifyStep(1);
        }
        if (type === 'phone') {
            setVerifyStep(2);
        }
    }, [type]);

    const handleSetCode = (type, value) => {
        setCode((prev) => ({ ...prev, [type]: value }));
    };

    useEffect(() => {
        handleSendCodeByVerifyStep(verifyStep, contacts, setError);
    }, [contacts, verifyStep]);

    const handleSwitchNextStep = () => {
        const typeVariants = {
            all: 2,
            email: 3,
            phone: 3,
        };

        const nextStep = typeVariants[type];
        if (nextStep) {
            setVerifyStep(nextStep);
        }
    };

    const handleConfirmCode = () => {
        const codeTypeVariants = { 1: 'email', 2: 'phone' };
        const codeType = codeTypeVariants[verifyStep];

        if (codeType) {
            const access_token = localStorage.getItem('access_token');
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/confirm_${codeType}/${code[codeType]}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        'Content-type': 'application/json',
                    },
                },
            ).then((res) => {
                if (res.status < 210) {
                    setError('');
                    handleSwitchNextStep();
                }
                if (res.status > 400) {
                    setError('Произошла ошибка');
                }
            });
        }
    };

    return (
        <ModalNew active={verifyProfileModal} setActive={setVerifyProfileModal} title="Контакты">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    flexDirection: 'column',
                }}
            >
                {verifyStep === 1 && (
                    <>
                        <p className={styles.verifyModalTitle}>
                            Пожалуйста, подтвердите вашу эл. почту
                        </p>
      
                        <div className={styles.verifyModalWrapper}>
                            <InputNew
                                title={`Почта: ${contacts.email}`}
                                placeholder="Введите код"
                                center
                                value={code.email}
                                setValue={(value) => {
                                    handleSetCode('email', value);
                                }}
                                // isCode
                                codeLength={6}
                            />
                            <p style={{ color: 'red' }}>{error}</p>
                            <button
                                className={cn(base.orangeButton, styles.editProfileFormButton)}
                                onClick={() => {
                                    handleConfirmCode();
                                }}
                            >
                                Продолжить
                            </button>
                            <RepeatCode
                                verifyStep={verifyStep}
                                contacts={contacts}
                                setError={setError}
                            />
                        </div>
                    </>
                )}
                {verifyStep === 2 && (
                    <>
                        <p className={styles.verifyModalTitle}>
                            Пожалуйста, подтвердите ваш телефон
                        </p>

                        <div className={styles.verifyModalWrapper}>
                            <InputNew
                                title={`Номер: ${contacts.phone}`}
                                placeholder="Введите код"
                                center
                                value={code.phone}
                                setValue={(value) => {
                                    handleSetCode('phone', value);
                                }}
                                // isCode
                                codeLength={6}
                            />
                            <p style={{ color: 'red' }}>{error}</p>
                            <button
                                className={cn(base.orangeButton, styles.editProfileFormButton)}
                                onClick={() => {
                                    handleConfirmCode();
                                }}
                            >
                                Продолжить
                            </button>
                            <DescriptionBlock>
                                <p style={{ maxWidth: '300px' }}>
                                    1.Сейчас на ваш телефон поступит звонок.
                                </p>
                                <p style={{ maxWidth: '300px' }}>
                                    2.Прослушайте 4-х значный код или введите 4 последние цифры
                                    номера, с которого поступил звонок.
                                </p>
                                <p style={{ maxWidth: '300px' }}>3.Нажмите кнопку Продолжить.</p>
                                <p style={{ maxWidth: '300px' }}>
                                    4.Если в течении минуты звонок не поступит, запросите отправку
                                    повторно.
                                </p>
                            </DescriptionBlock>
                            <RepeatCode
                                verifyStep={verifyStep}
                                contacts={contacts}
                                setError={setError}
                            />
                        </div>
                    </>
                )}
                {verifyStep === 3 && (
                    <div className={styles.verifyScreenSuccess}>
                        <svg
                            width="66"
                            height="66"
                            viewBox="0 0 66 66"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="33" cy="33" r="31.75" stroke="#207CE2" strokeWidth="2.5" />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M43.7844 24.7098C44.352 25.2764 44.3525 26.1955 43.7854 26.7627L29.2571 41.2934C28.9847 41.5659 28.6151 41.719 28.2297 41.7191C27.8442 41.7192 27.4745 41.5663 27.202 41.2939L22.8435 36.9391C22.2761 36.3722 22.2761 35.4531 22.8435 34.8862C23.4109 34.3193 24.3307 34.3193 24.8981 34.8862L28.2288 38.2141L41.7298 24.7108C42.2969 24.1437 43.2167 24.1432 43.7844 24.7098Z"
                                fill="#207CE2"
                            />
                        </svg>

                        <p className={styles.verifyModalSuccessTitle}>верификация прошла успешно</p>

                        <button
                            className={cn(base.orangeButton, styles.editProfileFormButton)}
                            onClick={() => setVerifyProfileModal(false)}
                        >
                            Хорошо
                        </button>
                    </div>
                )}
            </div>
        </ModalNew>
    );
};
{
    /* {verifyStep === 0 && (
                    <div className={styles.contactForm}>
                        <div className={styles.contactFormTop}>
                            <p className={styles.contactFormTopTitle}>
                                CheckBrand приветствует Вас!
                            </p>

                            <p className={styles.contactFormTopText}>
                                Пожалуйста, укажите свои контакты
                            </p>
                        </div>

                        <div className={styles.editProfileFormWrapper}>
                            {!userProfile.email_verified && (
                                <InputNew
                                    title="Эл. почта*"
                                    placeholder="Введите ваш email"
                                    setValue={(value) => {
                                        handleSetContactsByType('email', value);
                                    }}
                                    value={contacts.email}
                                />
                            )}
                            {!userProfile.phone_verified && (
                                <InputNew
                                    title="Номер телефона*"
                                    placeholder="Введите ваш номер телефона"
                                    setValue={(value) => handleSetContactsByType('phone', value)}
                                    value={contacts.phone}
                                />
                            )}

                            <p className={styles.inputRequired}>*Обязательное поле</p>
                        </div>

                        <button
                            className={cn(base.orangeButton, styles.editProfileFormButton)}
                            onClick={() => {
                                handleNextStep();
                            }}
                        >
                            Продолжить
                        </button>

                        <DescriptionBlock>
                            <p>Приветствуем Вас на платформе токенизации брендовых вещей!</p>

                            <p>
                                Пожалуйста, верифицируйте свою электронную почту и номер телефона.
                            </p>

                            <p>
                                Это важно для безопасного получения, отправки и приобретения
                                токенов.
                            </p>

                            <p>
                                Ваша безопасность и удобство — наш приоритет. Давайте сделаем Ваш
                                опыт приятным и комфортным!
                            </p>
                        </DescriptionBlock>
                    </div>
                )} */
}
// const handleNextStep = useCallback(() => {
// const { email_verified, phone_verified } = userProfile;
// if (verifyStep === 0) {
//     if (contacts.email.length > 0 && contacts.phone.length > 0) {
//         if (!email_verified && !phone_verified) {
//             handleUpdateUserContacts(
//                 setVerifyStep,
//                 contacts.email,
//                 contacts.phone,
//                 1,
//                 'all',
//                 setError,
//             );
//         }
//         if (email_verified && !phone_verified) {
//             handleUpdateUserContacts(
//                 setVerifyStep,
//                 contacts.email,
//                 contacts.phone,
//                 2,
//                 'phone',
//                 setError,
//             );
//         }
//         if (!email_verified && phone_verified) {
//             handleUpdateUserContacts(
//                 setVerifyStep,
//                 contacts.email,
//                 contacts.phone,
//                 1,
//                 'email',
//                 setError,
//             );
//         }
//     }
//     if (contacts.email.length > 0 && contacts.phone.length === 0) {
//         handleUpdateUserContacts(
//             setVerifyStep,
//             contacts.email,
//             contacts.phone,
//             1,
//             'email',
//             setError,
//         );
//     }
//     if (contacts.email.length === 0 && contacts.phone.length > 0) {
//         handleUpdateUserContacts(
//             setVerifyStep,
//             contacts.email,
//             contacts.phone,
//             2,
//             'phone',
//             setError,
//         );
//     }
// }
// if (verifyStep === 1) {
//     handleFetchCodeByType(
//         'email',
//         code.email,
//         setVerifyStep,
//         contacts.phone.length > 0 ? 2 : 3,
//         setError,
//     );
// }
// if (verifyStep === 2) {
//     handleFetchCodeByType('phone', code.phone, setVerifyStep, 3, setError, null);
// }
// }, [verifyStep, contacts, code]);
