export const fetchUserByWallet = (
    id,
    setVerifyData,
    user,
    setTokenSum,
    setUserProfile,
    setUserTokens,
    dispatch,
) => {
    const access_token = localStorage.getItem('access_token');
    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/address/${id}`, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    })
        .then((res) => {
            if (Number(res.status) === 200) {
                return res.json();
            }
        })
        .then((response) => {
            if (response && !response.errors) {
                const profileResult = response;
                const tokensResult = response.tokens;
                const tokensSum = response.tokens_sum;
                setUserTokens(tokensResult);
                setTokenSum(tokensSum);
                setUserProfile(profileResult);
                if (response.profile.public_address === user.walletAddress) {
                    dispatch(setVerifyData(response.profile));
                }
            }
        });
};

export const handleFetchSMSCode = (code, setError, step) => {
    const accessToken = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/${
        step === 1 ? 'send_email' : 'send_sms'
    }/${step === 1 ? code : code}`;

    fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-type': 'application/json',
        },
    }).catch((error) => {
        setError(error.msg);
    });
};

export const handleFetchCodeByType = (type, code, setStep, nextStep, setError) => {
    const accessToken = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/confirm_${type}/${code}`;

    fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-type': 'application/json',
        },
    })
        .then((res) => {
            if (res.ok) {
                setStep(nextStep);
                setError('');
            } else {
                return res.json();
            }
        })
        .then((response) => {
            if (response && response.msg) {
                setError(response.msg);
            }
        })
        .catch((error) => {
            setError('Произошла ошибка при обработке запроса.');
        });
};

export const handleUpdateUserContacts = (setVerifyStep, email, phone, nextStep, type, setError) => {
    const requestBodyTypes = {
        all: { email, phone: phone.substring(1) },
        phone: { phone: phone.substring(1) },
        email: { email },
    };

    console.log(requestBodyTypes[type]);
    const accessToken = localStorage.getItem('access_token');

    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(requestBodyTypes[type]),
    })
        .then((res) => res.json())
        .then((response) => {
            if (response) {
                setVerifyStep(nextStep);
                setError('');
            }
        })
        .catch((err) => {
            setError(err.msg);
        });
};
