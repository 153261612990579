import React, { memo, useState } from 'react';
import { ModalNew2 } from '../../components/ModalNew2';
import cn from 'classnames';
import styles from './index.module.css';
import base from '../../styles/base.module.css';
import AddFile from '../../icons/AddFile';
import Pen from '../../icons/Pen';
import { InputNew } from '../../common/InputNew';

export const TokenEdit = memo(({ tokenId,  setRefetch }) => {
    const [urlModal, setUrlModal] = useState(false);
    const [descModal, setDescModal] = useState(false);
    const [tokenInfoPayload, setTokenInfoPayload] = useState({});
    // const [file, setFile] = useState(null);

    const sendFile = async (file, type) => {
        const formData = new FormData();
        formData.append(type, file);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/token/${tokenId}/`,
                {
                    method: 'PATCH',
                    body: formData,
                },
            );
       
            if (response.ok) {
                setRefetch((prev) => !prev);
                console.log('Файл успешно загружен!');
            } else {
                console.error('Ошибка загрузки файла');
            }
        } catch (error) {
            console.error('Ошибка сети:', error);
        }
    };

    const handleFileSelect = (event, type) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            sendFile(selectedFile, type);
        }
    };

    const handleSetInfoByType = (type, value) => {
        setTokenInfoPayload((prev) => ({ ...prev, [type]: value }));
    };

    const handleApplyTokenData = (type) => {
        const data = {
            description: tokenInfoPayload.description,
            url_1: tokenInfoPayload.url_1,
            url_2: tokenInfoPayload.url_2,
        };
        fetch(`${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/token/${tokenId}/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
            body: JSON.stringify({
                [type]: data[type],
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`error ${res.status}`);
                }
                if (res.status < 210 && type === 'description') {
                    setRefetch((prev) => !prev);
                    setDescModal(false);
                }
                if ((res.status < 210 && type === 'url_1') || res.status < 210 && type === 'url_2') {
                    setRefetch((prev) => !prev);
                    setUrlModal(false);
                }
                return res.json();
            })
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <>
            <button className={styles.tokenInfoDescButton} onClick={() => setUrlModal(true)}>
                + ссылку
            </button>

            <div>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileSelect(e, 'file_1')}
                    id="file-upload"
                />
                <label htmlFor="file-upload" className={styles.tokenInfoDescButton}>
                    <AddFile />
                    фото
                </label>
            </div>
            <div>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileSelect(e, 'file_2')}
                    id="file-upload2"
                />
                <label htmlFor="file-upload2" className={styles.tokenInfoDescButton}>
                    <AddFile />
                    фото 2
                </label>
            </div>

            <button className={styles.tokenInfoDescButton} onClick={() => setDescModal(true)}>
                <Pen />
                описание
            </button>

            <ModalNew2
                value={descModal}
                setValue={setDescModal}
                title="Редактор описания"
                size="small"
                contentClassName={styles.descModal}
            >
                <div className={styles.tokenModalDesc}>
                    <div className={styles.tokenModalDesc}>
                        <div className={styles.tokenModalDescTextareaInner}>
                            <textarea
                                className={styles.tokenModalDescTextarea}
                                value={tokenInfoPayload.description}
                                onChange={(e) => {
                                    if (e.target.value.length <= 500) {
                                        handleSetInfoByType('description', e.target.value);
                                    }
                                }}
                            />
                            {tokenInfoPayload && tokenInfoPayload.description && (
                                <p className={styles.textareaLength}>
                                    {tokenInfoPayload.description.length}/500
                                </p>
                            )}
                        </div>
                    </div>

                    <button
                        className={cn(base.orangeButton, styles.tokenModalDescButtonSmall)}
                        onClick={() => handleApplyTokenData('description')}
                    >
                        Сохранить
                    </button>
                </div>
            </ModalNew2>

            <ModalNew2
                value={urlModal}
                setValue={setUrlModal}
                size="small"
                contentClassName={styles.urlModal}
                title="URL ссылки"
            >
                <div className={styles.tokenModalUrl}>
                    <p className={styles.tokenModalUrlAdd}>Добавьте URL при необходимости</p>

                    <div className={styles.tokenModalUrlItems}>
                        <InputNew
                            title="URL 1"
                            placeholder="Введите https://"
                            setValue={(value) => {
                                handleSetInfoByType('url_1', value);
                            }}
                        />

                        <InputNew
                            title="URL 2"
                            setValue={(value) => {
                                handleSetInfoByType('url_2', value);
                            }}
                            placeholder="Введите https://"
                        />
                    </div>

                    <button
                        className={cn(base.orangeButton, styles.tokenModalDescButtonSmall)}
                        onClick={() => {
                            handleApplyTokenData('url_1')
                            handleApplyTokenData('url_2')
                        }}
                    >
                        Сохранить
                    </button>
                </div>
            </ModalNew2>
        </>
    );
});
