import React, { useState, useEffect } from 'react';
import { TABLE_ROUTING_NAME_VARIANTS } from '../../const/consts';

export const useGetTableView = (path, isFullList, setPage, title) => {
    useEffect(() => {
        if (path.includes(title)) {
            setPage({
                isFullListMode: isFullList,
                type: title,
                name: TABLE_ROUTING_NAME_VARIANTS[title],
            });
        }
    }, [path, isFullList, setPage, title]);
};
