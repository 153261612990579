import Input from "../../common/Input";
import Modal from "../../common/Modal";
import React, {useState} from "react";
import {emailRegex} from "../../pages/TokenCard/PayModal/PayModal";
import styles from '../WelcomeModal/modal.module.css'
import {useCooperationMutation} from "../../redux/api/dataService";

export const StartCooperationModal = (
  {
    isStartCooperation,
    setIsStartCooperation,
    setIsSuccessModal,
    email,
    setEmail,
    phone,
    setPhone,
    name,
    setName,
    site,
    setSite
  }
  ) => {
  const [isClicked, setIsClicked] = useState(false)
  const [sendCooperation] = useCooperationMutation()


  const handleBtnClick = () => {
    setIsClicked(true)
    if(email && phone && name && emailRegex.test(email)) {
      sendCooperation({
        body: {
          email:email,
          phone: phone,
          name: name,
          site: site
        }
      }).unwrap().then(() => {
        setIsSuccessModal(true)
        setIsStartCooperation(false)
        setIsClicked(false)
      })
    }
  }

  const handleCloseModal = () => {
    setEmail('')
    setPhone('')
    setName('')
    setSite('')
    setIsStartCooperation(false)
    setIsClicked(false)
  }

  return (
    <Modal active={false} title={'Начнём сотрудничество'} onClose={handleCloseModal}>
      <p className={styles.welcome}>CheckBrand приветствует Вас!</p>
      <p style={{margin: '20px 0 40px 0', color: 'white'}}>Пожалуйста укажите свои контакты</p>
      <Input
        placeholder="Сайт"
        value={site}
        setValue={setSite}
        className={styles.input}
      />
      <Input
        placeholder="Имя*"
        value={name}
        setValue={setName}
        className={styles.input}
        isError={isClicked ? !name : false}
      />
      <Input
        placeholder="Эл. почта*"
        value={email}
        setValue={setEmail}
        className={styles.input}
        isError={
          isClicked
            ? !emailRegex.test(email) || !email
            : email
              ? !emailRegex.test(email)
              : false
        }
      />
      <Input
        placeholder="Телефон*"
        value={phone}
        setValue={setPhone}
        isError={isClicked ? !phone : false}
      />
      <p className={styles.text}>*Обязательное поле</p>

      <button
        className={`button token__card--content--price--buy ${styles.btn}`}
        style={{height: '52px'}}
        onClick={handleBtnClick}
      >
        Готово
      </button>
      <div  style={{marginTop: '44px', minWidth: '100%'}}>
        <Input
          placeholder="Укажите адрес сайта компании, ваше имя, эл. почту и телефон. Менеджер свяжется с вами в ближайшее время. Обсудим условия сотрудничества: коллаборация или выпуск собственных сертификатов. Нажимая на кнопку, вы даёте согласие на обработку персональных данных."
          textarea
          disabled={true}
        />
      </div>
    </Modal>
  )
}