import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { scrollPage, fetchRequest } from './utils/useDynamicPagination.utils';
import { generateQueryParams } from '../../pages/utils';

export const useDynamicPagination = (
    url,
    queryParams,
    isAuth = true,
    isResetList = false,
    setIsResetList = () => 1,
    type = 'handler_service',
  
) => {
    const [count, setCount] = useState(1);
    const [fetching, setFetching] = useState(true);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);

    const filteredQueryDTO = useMemo(
        () => queryParams.filter((param) => param.value !== '' && param.value !== undefined),
        [queryParams],
    );

    const query = useMemo(
        () => generateQueryParams([...filteredQueryDTO, { title: 'page', value: page }]),
        [filteredQueryDTO, page, queryParams],
    );

    const handleScrollPage = useCallback(
        (e) => {
            if (page) {
                scrollPage(e, items, count, setFetching);
            } else setFetching(false);
        },
        [items, count, page],
    );

    useEffect(() => {
        const scrollHandler = (e) => handleScrollPage(e);
        document.addEventListener('scroll', scrollHandler);
        return () => {
            document.removeEventListener('scroll', scrollHandler);
        };
    }, [handleScrollPage]);

    useEffect(() => {
        if (isResetList) {
            setItems([]);
            setPage(1);
            setFetching(true);
            setIsResetList(false);
        }
    }, [isResetList]);

    useEffect(() => {
        if (fetching && page !== null) {
            fetchRequest(url, query, setItems, setCount, setPage, setFetching, type, isAuth);
        }
    }, [fetching, query, url, page, isAuth]);

    return { items: items, loading: fetching, count:count };
};
