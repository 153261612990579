import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './index.module.css';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import Share3 from '../../icons/Share3';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
export const TokenOwner = memo(({ ownerWalletAdress }) => {
    const [userProfile, setUserProfile] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (ownerWalletAdress) {
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/address/${ownerWalletAdress}`,
            )
                .then((res) => {
                    return res.json();
                })
                .then((response) => {
                    if (response && response.profile) {
                        setUserProfile(response.profile);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [ownerWalletAdress]);

    return userProfile ? (
        <Link to={`/profileNew/${ownerWalletAdress}`} className={styles.tokenOwner}>
            <img src="/assets/img/element-fire.png" alt="bg" className={styles.tokenOwnerBg} />

            <span className={styles.tokenOwnerInner}>
                <span className={styles.tokenOwnerContent}>
                    <UserAvatar avatar={`${userProfile.image_url}`} style="silver" />

                    <span className={styles.tokenOwnerTextInner}>
                        <span className={styles.tokenOwnerText}>Владелец</span>
                        <span className={styles.tokenOwnerText}>
                            {userProfile.username ? userProfile.username : '-'}
                        </span>
                        <span className={styles.tokenOwnerName}>
                            {userProfile.first_name ? userProfile.first_name : '-'}
                        </span>
                    </span>
                </span>

                <Share3 className={styles.tokenOwnerIcon} />
            </span>
        </Link>
    ) : (
        <CircularProgress />
    );
});
