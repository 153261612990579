import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import QRCode from 'react-qr-code';
import styles from './index.module.css';
import base from '../../styles/base.module.css';
import Document from '../../icons/Document';
import { SpoilerBlock } from '../../components/SpoilerBlock';
import Prop from '../../icons/Prop';
import { PropertyItem } from '../../components/PropertyItem';
import Stack from '../../icons/Stack';
import { SocialBlock, SocialLink } from '../../components/SocialBlock';
import Web2 from '../../icons/Web2';
import Telegram2 from '../../icons/Telegram2';
import Vk2 from '../../icons/Vk2';
import Instagram2 from '../../icons/Instagram2';
import { Tab, Tabs } from '../../common/Tabs';
import Reload from '../../icons/Reload';
import Share2 from '../../icons/Share2';
import Qr from '../../icons/Qr';
import { Link, useParams } from 'react-router-dom';
import Clock2 from '../../icons/Clock2';
import { Tooltip } from '../../components/Tooltip';
import Info2 from '../../icons/Info2';
import PartnersComing from '../../icons/PartnersComing';
import Picture from '../../icons/Picture';
import ShareLink from '../../icons/ShareLink';
import Verify from '../../icons/Verify';
import { TokenPartnerOffer } from '../../components/TokenPartnerOffer';
import { ModalNew2 } from '../../components/ModalNew2';
import { InputNew } from '../../common/InputNew';
import { Video2 } from '../../icons/Video2';
import { DescriptionBlock } from '../../components/DescriptionBlock';
import ModalNew from '../../common/ModalNew';
import TokenNewSkeletonPage from './TokenNewSkeletonPage';
import { Minus } from '../../icons/Minus';
import { Plus2 } from '../../icons/Plus2';
import { HistoricalRecordItem } from './HistoricalRecordItem';
import { TokenOwner } from './TokenOwner';
import { useSelector } from 'react-redux';
import { TokenEdit } from './TokenEdit';
import { TokenTransfer } from './TokenTransfer';
import { TokenFormalization } from './TokenFormalizatiom';
import { fetchTokenData } from './utils';
import { Timer } from './Timer';
import { CollectionSertificates } from './CollectionSertificates';
import { SertificateActivation } from './SertificateActivation';
import { TokenHistory } from './TokenHistory';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import { NotificationContext } from '../../context/NotificationContext';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import { copyText } from '../../functions/utils';
import { useContext } from 'react';
import { isVideo } from '../../utils/isVideo';
import { VideoPlayer } from '../../common/VideoPlayer';

const isUserOwnsToken = (token, user) => {
    if (token && user) {
        return token.wallet_owner === user.public_address;
    }
    return false;
};

const getCollection = (collectionId, setCollection) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/collection/${collectionId}`)
        .then((res) => res.json())
        .then((res) => {
            setCollection(res);
        })
        .catch((err) => {
            console.log(err);
        });
};

const TokenNew = () => {
    const { id } = useParams();
    const [qrModal, setQrModal] = React.useState(false);
    const url = new URL(window.location);
    const isTokenPaid = url.searchParams.get('payment');
    const [imgModal, setImgModal] = React.useState(false);
    const [carouselImgModal, setCarouselImgModal] = React.useState(false);
    const [totalCountImages, setTotalCountImages] = React.useState(2);
    const [activeSlideNumber, setActiveSlideNumber] = React.useState(1);
    const [refetch, setRefetch] = useState(true);
    const [formalizationModal, setFormalizationModal] = useState(false);
    const [showPartnersOffers, setShowPartnersOffers] = React.useState(true);
    const [modalSuccessPayment, setModalSuccessPayment] = React.useState(false);
    const [modalCertificate, setModalCertificate] = React.useState(false);
    const [modalCertificateSend, setModalCertificateSend] = React.useState(false);
    const [collection, setCollection] = useState(null);
    const [modalCertificateSuccess, setModalCertificateSuccess] = React.useState(false);
    const [modalCertificateCancel, setModalCertificateCancel] = React.useState(false);
    const [certSended, setCertSended] = React.useState(false);
    const [token, setToken] = useState(null);
    const user = useSelector((state) => state.auth.allUserData);

    useEffect(() => {
        if (isTokenPaid) {
            setModalSuccessPayment(true);
        }
    }, [isTokenPaid]);

    useEffect(() => {
        fetchTokenData(id, setToken);
        // setRefetch(false)
    }, [id, refetch]);

    useEffect(() => {
        const isCollectionIdExist = token && token.collection && token.collection.id;
        if (isCollectionIdExist) {
            if (!(collection && collection.id && collection.id === token.collection.id)) {
                getCollection(token.collection.id, setCollection);
            }
        }
    }, [token, id]);

    const isUserOwner = isUserOwnsToken(token, user);

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const copyAddress = () => {
        // if (userProfile.public_address) {
        copyText(window.location);
        addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            text: 'Адрес скопирован!',
        });
        // }
    };

    const handleChangeToken = (type, key) => {
        const newTokenPayload = {};
        if (type === 'unverifyed') {
            newTokenPayload.is_shown_for_unverified_user = false;
        }

        if (type === 'photo') {
            newTokenPayload[key] = null;
        }

        fetch(`${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/token/${id}/`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify(newTokenPayload),
        }).then((res) => {
            if (Number(res.status) < 210) {
                if (type === 'photo') {
                    setRefetch((prev) => !prev);
                    setCarouselImgModal(false);
                }
            }
        });
    };

    useEffect(() => {
        if (user && user.email_verified && user.phone_verified && isUserOwner) {
            handleChangeToken('unverifyed');
        }
    }, [user, id, isUserOwner]);
    console.log(token && !token.paid && !token.wallet_owner)

    return token ? (
        <div className={base.marginWrapper}>
            <section className={styles.token}>
                <div className={base.container}>
                    <div className={styles.tokenInner}>
                        <div className={styles.tokenContent}>
                            <div className={styles.tokenContentWrapper}>
                                <div className={styles.tokenContentMobile}>
                                    <div
                                        className={styles.tokenImgInner}
                                        // onClick={() => setImgModal(true)}
                                    >
                                        {/* <img src={`${token.file_1}`} alt="token img" /> */}
                                        {token && isVideo(token.file_2_name_ext) ? (
                                            <div className="player-wrapper token__card--container">
                                                <VideoPlayer
                                                    src={token.file_2}
                                                    className="token__card--video"
                                                />
                                            </div>
                                        ) : (
                                            <img src={`${token.file_1}`} alt="token img" />
                                        )}
                                    </div>

                                    <div className={cn(styles.tokenInfoNameInner, styles.mobile)}>
                                        <div className={styles.tokenInfoNameWrapper}>
                                            {token && token.collection && (
                                                <Link
                                                    className={styles.tokenInfoLink}
                                                    to={`/collection/${token.collection.id}`}
                                                >
                                                    {token &&
                                                        token.collection &&
                                                        token.collection.name}
                                                </Link>
                                            )}

                                            <h1 className={styles.tokenInfoName}>{token.name}</h1>

                                            <p className={styles.tokenInfoSubtext}>
                                                {token.paid
                                                    ? `Куплен за ${
                                                          token &&
                                                          Math.floor(
                                                              token.price / 100,
                                                          ).toLocaleString('ru-RU')
                                                      }`
                                                    : 'Нет владельца'}
                                            </p>
                                        </div>

                                        <Tabs changeMobileTheme className={styles.tokenContentTabs}>
                                            <Tab left onClick={() => setRefetch(true)}>
                                                <Reload />
                                            </Tab>

                                            <Tab onClick={() => setQrModal(true)}>
                                                <Qr />
                                            </Tab>

                                            <Tab right onClick={() => copyAddress()}>
                                                <Share2 />
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>

                                <div className={styles.tokenInfoWrapper}>
                                    <SpoilerBlock
                                        defaultOpen
                                        icon={<Document />}
                                        title="Описание токена"
                                    >
                                        <div className={styles.tokenInfoDesc}>
                                            <p>{token.description}</p>
                                        </div>

                                        <div className={styles.tokenInfoDescWrap}>
                                            <Tabs className={styles.tokenInfoDescTabs}>
                                                {token.file_1 && (
                                                    <Tab
                                                        left
                                                        onClick={() => setCarouselImgModal(true)}
                                                    >
                                                        <Picture />
                                                    </Tab>
                                                )}

                                                {token.file_2 && (
                                                    <Tab onClick={() => setCarouselImgModal(true)}>
                                                        <Picture />
                                                    </Tab>
                                                )}

                                                {token && token.url_1 && (
                                                    <Tab
                                                        right
                                                        onClick={() =>
                                                            window.open(token.url_1, '_blank')
                                                        }
                                                    >
                                                        <ShareLink />
                                                    </Tab>
                                                )}
                                                {token && token.url_2 && (
                                                    <Tab
                                                        right
                                                        onClick={() =>
                                                            window.open(token.url_2, '_blank')
                                                        }
                                                    >
                                                        <ShareLink />
                                                    </Tab>
                                                )}
                                            </Tabs>

                                            {isUserOwner && token && (
                                                <TokenEdit
                                                    tokenId={token.id}
                                                    setRefetch={setRefetch}
                                                />
                                            )}
                                        </div>
                                    </SpoilerBlock>

                                    <SpoilerBlock defaultOpen icon={<Prop />} title="Свойства">
                                        <div className={styles.tokenInfoProps}>
                                            {token.properties &&
                                                token.properties.map((property, index) => {
                                                    return (
                                                        <PropertyItem
                                                            key={index}
                                                            title={property.type}
                                                            value={property.name}
                                                            // text="13 % имеют такое св-во"
                                                        />
                                                    );
                                                })}
                                        </div>
                                    </SpoilerBlock>

                                    <SpoilerBlock
                                        defaultOpen
                                        icon={<Stack />}
                                        title={`О коллекции ${
                                            collection && collection.name ? collection.name : ''
                                        }`}
                                    >
                                        <div className={styles.tokenInfoDesc}>
                                            <p>{collection && collection.description}</p>
                                        </div>

                                        <SocialBlock className={styles.tokenInfoSocials}>
                                            {collection && collection.link_yoursite && (
                                                <SocialLink
                                                    onClick={() =>
                                                        window.open(collection.link_yoursite)
                                                    }
                                                >
                                                    <Web2 />
                                                </SocialLink>
                                            )}

                                            {collection && collection.link_telegram && (
                                                <SocialLink
                                                    onClick={() =>
                                                        window.open(collection.link_telegram)
                                                    }
                                                >
                                                    <Telegram2 />
                                                </SocialLink>
                                            )}

                                            {collection && collection.link_vk && (
                                                <SocialLink
                                                    onClick={() => window.open(collection.link_vk)}
                                                >
                                                    <Vk2 />
                                                </SocialLink>
                                            )}

                                            {collection && collection.link_instagram && (
                                                <SocialLink
                                                    onClick={() =>
                                                        window.open(collection.link_instagram)
                                                    }
                                                >
                                                    <Instagram2 />
                                                </SocialLink>
                                            )}

                                            {collection && collection.link_youtube && (
                                                <SocialLink
                                                    onClick={() =>
                                                        window.open(collection.link_youtube)
                                                    }
                                                >
                                                    <Video2 />
                                                </SocialLink>
                                            )}
                                        </SocialBlock>
                                    </SpoilerBlock>
                                </div>
                            </div>

                            <div className={styles.tokenInfoContent}>
                                <div className={styles.tokenInfoNameInner}>
                                    <div className={styles.tokenInfoNameWrapper}>
                                        {token && token.collection && (
                                            <Link
                                                className={styles.tokenInfoLink}
                                                to={`/collection/${token.collection.id}`}
                                            >
                                                {token && token.collection && token.collection.name}
                                            </Link>
                                        )}

                                        <h1 className={styles.tokenInfoName}>{token.name}</h1>

                                        <p className={styles.tokenInfoSubtext}>
                                            {token.paid
                                                ? `Куплен за ${
                                                      token && token.price.toLocaleString('ru-RU')
                                                  } руб`
                                                : 'Нет владельца'}
                                        </p>
                                    </div>

                                    <Tabs changeMobileTheme small className={styles.tokenTabs}>
                                        <Tab left>
                                            <Reload onClick={() => fetchTokenData(id, setToken)} />
                                        </Tab>

                                        <Tab onClick={() => setQrModal(true)}>
                                            <Qr />
                                        </Tab>

                                        <Tab right onClick={() => copyAddress()}>
                                            <Share2 />
                                        </Tab>
                                    </Tabs>
                                </div>
                                {token && !token.paid && (
                                    <div className={styles.tokenMainBlockInfo}>
                                        <p className={styles.tokenMainBlockInfoTitle}>
                                            <Clock2 />
                                            До завершения продажи: <Timer />
                                        </p>

                                        <div className={styles.tokenMainBlockInfoContent}>
                                            <div className={styles.tokenMainBlockTextWrapper}>
                                                <p className={styles.tokenMainBlockPriceText}>
                                                    Стоимость оформления сертификата
                                                </p>

                                                <div className={styles.tokenMainBlockPriceInner}>
                                                    <p className={styles.tokenMainBlockPrice}>
                                                        {token && token.pack && token.pack.percent
                                                            ? `${token.pack.percent}% стоимости авто`
                                                            : `${Math.floor(
                                                                  token.price / 100,
                                                              ).toLocaleString('ru-RU')} RUB`}
                                                    </p>

                                                    {/* <Tooltip
                                                        placement="top"
                                                        text="Стоимость автомобиля указывается при оформлении"
                                                        wrapperStyles={{
                                                            width: 140,
                                                        }}
                                                        style={{ top: -12 }}
                                                    >
                                                        <Info2
                                                            className={
                                                                styles.tokenMainBlockPriceInfo
                                                            }
                                                        />
                                                    </Tooltip> */}
                                                </div>
                                            </div>

                                            <button
                                                className={cn(
                                                    base.orangeButton,
                                                    styles.tokenOrderAvto,
                                                )}
                                                onClick={() => setFormalizationModal(true)}
                                            >
                                                Оформить на свой авто
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className={cn(styles.tokenInfoWrapper, styles.mobile)}>
                                    <SpoilerBlock
                                        defaultOpen
                                        icon={<Document />}
                                        title="Описание токена"
                                    >
                                        <div className={styles.tokenInfoDesc}>
                                            <p>{token && token.description}</p>
                                        </div>

                                        <div className={styles.tokenInfoDescWrap}>
                                            <Tabs className={styles.tokenInfoDescTabs}>
                                                {token.file_1 && (
                                                    <Tab
                                                        left
                                                        onClick={() => setCarouselImgModal(true)}
                                                    >
                                                        <Picture />
                                                    </Tab>
                                                )}

                                                {token.file_2 && (
                                                    <Tab onClick={() => setCarouselImgModal(true)}>
                                                        <Picture />
                                                    </Tab>
                                                )}

                                                {token && token.url_1 && (
                                                    <Tab
                                                        right
                                                        onClick={() =>
                                                            window.open(token.url_1, '_blank')
                                                        }
                                                    >
                                                        <ShareLink />
                                                    </Tab>
                                                )}
                                                {token && token.url_2 && (
                                                    <Tab
                                                        right
                                                        onClick={() =>
                                                            window.open(token.url_2, '_blank')
                                                        }
                                                    >
                                                        <ShareLink />
                                                    </Tab>
                                                )}
                                            </Tabs>
                                            {isUserOwner && token && (
                                                <TokenEdit
                                                    tokenId={token.id}
                                                    setRefetch={setRefetch}
                                                />
                                            )}
                                            {/* <button
                                                className={styles.tokenInfoDescButton}
                                               
                                            >
                                                + ссылку
                                            </button>

                                            <button className={styles.tokenInfoDescButton}>
                                            
                                                фото
                                            </button>

                                            <button
                                                className={styles.tokenInfoDescButton}
                                                // onClick={() => setDescModal(true)}
                                            >
                                              
                                                описание
                                            </button> */}
                                        </div>
                                    </SpoilerBlock>

                                    <SpoilerBlock defaultOpen icon={<Prop />} title="Свойства">
                                        <div className={styles.tokenInfoProps}>
                                            {token.properties &&
                                                token.properties.map((property, index) => {
                                                    return (
                                                        <PropertyItem
                                                            key={index}
                                                            title={property.type}
                                                            value={property.name}
                                                            // text="13 % имеют такое св-во"
                                                        />
                                                    );
                                                })}
                                        </div>
                                    </SpoilerBlock>

                                    <SpoilerBlock
                                        defaultOpen
                                        icon={<Stack />}
                                        title={`О коллекции ${
                                            collection && collection.name ? collection.name : ''
                                        }`}
                                    >
                                        <div className={styles.tokenInfoDesc}>
                                            <p>{collection && collection.description}</p>
                                        </div>

                                        <SocialBlock className={styles.tokenInfoSocials}>
                                            {collection && collection.link_yoursite && (
                                                <SocialLink
                                                    onClick={() =>
                                                        window.open(collection.link_yoursite)
                                                    }
                                                >
                                                    <Web2 />
                                                </SocialLink>
                                            )}

                                            {collection && collection.link_telegram && (
                                                <SocialLink
                                                    onClick={() =>
                                                        window.open(collection.link_telegram)
                                                    }
                                                >
                                                    <Telegram2 />
                                                </SocialLink>
                                            )}

                                            {collection && collection.link_vk && (
                                                <SocialLink
                                                    onClick={() => window.open(collection.link_vk)}
                                                >
                                                    <Vk2 />
                                                </SocialLink>
                                            )}

                                            {collection && collection.link_instagram && (
                                                <SocialLink
                                                    onClick={() =>
                                                        window.open(collection.link_instagram)
                                                    }
                                                >
                                                    <Instagram2 />
                                                </SocialLink>
                                            )}
                                            {collection && collection.link_youtube && (
                                                <SocialLink>
                                                    <Video2 />
                                                </SocialLink>
                                            )}
                                        </SocialBlock>
                                    </SpoilerBlock>
                                </div>
                                {token && token.wallet_owner && token.paid && (
                                    <TokenOwner
                                        ownerWalletAdress={token ? token.wallet_owner : ''}
                                    />
                                )}

                                {/* <div className={styles.tokenOwner}>
                                    <img
                                        src="/assets/img/element-fire.png"
                                        alt="bg"
                                        className={styles.tokenOwnerBg}
                                    />
                                </div> */}

                                {token && token.paid && token.wallet_owner && (
                                    <TokenHistory
                                        id={token.id}
                                        certSended={certSended}
                                        setCertSended={setCertSended}
                                        setModalCertificateSend={setModalCertificateSend}
                                        setModalCertificateCancel={setModalCertificateCancel}
                                        isUserOwner={isUserOwner}
                                    />
                                )}
                                {/* || (token.paid && !token.wallet_owner)) && */}
                                {((token && !token.paid) ||
                                    (token && token.paid && !token.wallet_owner)) && (
                                    <div className={styles.tokenPartnersComing}>
                                        <div className={styles.tokenPartnersComingInner}>
                                            <PartnersComing />

                                            <p className={styles.tokenPartnersComingTitle}>
                                                Здесь будут предложения партнеров
                                            </p>
                                        </div>
                                    </div>
                                )}

                                {token && token.paid && !token.wallet_owner && (
                                    <SertificateActivation />
                                )}

                                {/* {token &&
                                    // isUserOwner &&
                                    (!user.email_verified || !user.phone_verified) && (
                                        <div className={styles.tokenPartnersComing}>
                                            <div className={styles.tokenPartnersComingInner}>
                                                <PartnersComing />

                                                <p className={styles.tokenPartnersComingTitle}>
                                                    Здесь будут предложения партнеров
                                                </p>
                                            </div>
                                        </div>
                                    )} */}

                                {token && collection && !token.paid && (
                                    <CollectionSertificates
                                        collectionId={collection.id}
                                        type={'published'}
                                        tokenId={id}
                                    />
                                )}
                            </div>
                        </div>

                        {token && token.paid && collection && (
                            <CollectionSertificates
                                collectionId={collection.id}
                                type={'paid'}
                                tokenId={id}
                            />
                        )}
                    </div>
                </div>
            </section>

            <ModalNew2 value={qrModal} setValue={setQrModal} title="Отсканируйте QR код">
                <div className={styles.tokenModalQr}>
                    <QRCode value={window.location.href} bgColor="#1B1B1B" fgColor="#fff" />

                    <p className={styles.tokenModalQrText}>
                        Не упусти шанс - это всего лишь один шаг к новым возможностям!
                    </p>
                </div>
            </ModalNew2>

            <ModalNew2 emptyModal value={imgModal} setValue={setImgModal}>
                <div className={styles.tokenImgModal}>
                    <img src={`${token.file_1}`} alt="token img" />
                </div>
            </ModalNew2>
            {formalizationModal && (
                <TokenFormalization
                    token={token}
                    id={id}
                    formalizationModal={formalizationModal}
                    setFormalizationModal={setFormalizationModal}
                    setToken={setToken}
                    tokenPriceType={token.price_type}
                    price={token.price}
                    percent={token.pack.percent}
                />
            )}

            <ModalNew2 emptyModal value={carouselImgModal} setValue={setCarouselImgModal}>
                <div className={styles.tokenImgCarouselModal}>
                    {token.file_1 && (
                        <div
                            className={cn(styles.tokenImgSlideModal, {
                                [styles.active]: activeSlideNumber === 1 || !token.file_2,
                            })}
                        >
                            <img src={`${token.file_1}`} alt="token" />
                            {isUserOwner && (
                                <button
                                    className={styles.tokenImgRemove}
                                    onClick={() => handleChangeToken('photo', 'file_1')}
                                >
                                    Удалить
                                </button>
                            )}
                        </div>
                    )}
                    {token.file_2 && (
                        <div
                            className={cn(styles.tokenImgSlideModal, {
                                [styles.active]: activeSlideNumber === 2 || !token.file_1,
                            })}
                        >
                            <img src={`${token.file_2}`} alt="token" />

                            {isUserOwner && (
                                <button
                                    className={styles.tokenImgRemove}
                                    onClick={() => handleChangeToken('photo', 'file_2')}
                                >
                                    Удалить
                                </button>
                            )}
                        </div>
                    )}
                </div>

                {token.file_1 && token.file_2 && (
                    <>
                        <button
                            className={cn(styles.imgSliderArrow, styles.imgSliderPrev)}
                            onClick={() =>
                                setActiveSlideNumber((prev) => {
                                    if (prev - 1 === 0) {
                                        return totalCountImages;
                                    }

                                    return prev - 1;
                                })
                            }
                        >
                            <svg
                                width="8"
                                height="13"
                                viewBox="0 0 8 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8 11.4725L3.05533 6.5L8 1.5275L6.47773 -1.81529e-08L7.75117e-08 6.5L6.47773 13L8 11.4725Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>

                        <button
                            className={cn(styles.imgSliderArrow, styles.imgSliderNext)}
                            onClick={() =>
                                setActiveSlideNumber((prev) => {
                                    if (prev + 1 > totalCountImages) {
                                        return 1;
                                    }

                                    return prev + 1;
                                })
                            }
                        >
                            <svg
                                width="8"
                                height="13"
                                viewBox="0 0 8 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M-1.51754e-07 1.5275L4.94467 6.5L-1.13977e-06 11.4725L1.52227 13L8 6.5L1.52227 1.51234e-07L-1.51754e-07 1.5275Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                    </>
                )}
            </ModalNew2>
            {/* 
            <ModalNew2
                value={urlModal}
                setValue={setUrlModal}
                size="small"
                contentClassName={styles.urlModal}
                title="URL ссылки"
            >
                <div className={styles.tokenModalUrl}>
                    <p className={styles.tokenModalUrlAdd}>Добавьте URL при необходимости</p>

                    <div className={styles.tokenModalUrlItems}>
                        <InputNew title="URL 1" placeholder="Введите https://" />

                        <InputNew title="URL 2" placeholder="Введите https://" />
                    </div>

                    <button className={cn(base.orangeButton, styles.tokenModalDescButtonSmall)}>
                        Сохранить
                    </button>
                </div>
            </ModalNew2> */}

            <ModalNew
                active={modalSuccessPayment}
                setActive={setModalSuccessPayment}
                wrapperClass={styles.paymentsModalWrapper}
            >
                <div className={styles.paymentsModal}>
                    <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="33" cy="33" r="31.75" stroke="#207CE2" strokeWidth="2.5" />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M43.7844 24.7098C44.352 25.2764 44.3525 26.1955 43.7854 26.7627L29.2571 41.2934C28.9847 41.5659 28.6151 41.719 28.2297 41.7191C27.8442 41.7192 27.4745 41.5663 27.202 41.2939L22.8435 36.9391C22.2761 36.3722 22.2761 35.4531 22.8435 34.8862C23.4109 34.3193 24.3307 34.3193 24.8981 34.8862L28.2288 38.2141L41.7298 24.7108C42.2969 24.1437 43.2167 24.1432 43.7844 24.7098Z"
                            fill="#207CE2"
                        />
                    </svg>

                    <p className={styles.paymentsModalTitle}>Платёж прошел!</p>

                    <div className={styles.paymentsModalItems}>
                        <p>На сумму: {token && token.price} RUB</p>
                        {/* <p>Получатель: ООО “БРО”</p> */}
                        {/* <p>Номер заказа: 252352355</p>  */}
                        <p>
                            Поздравляем! Вы успешно приобрели цифровой сертификат на платформе
                            CheckBrand
                        </p>
                    </div>

                    <button
                        className={cn(
                            base.orangeButton,
                            styles.modalTokenButton,
                            styles.paymentsModalButton,
                        )}
                        onClick={() => {
                            setModalSuccessPayment(false);
                            setModalCertificate(true);
                        }}
                    >
                        Вернуться в магазин
                    </button>
                </div>
            </ModalNew>

            <ModalNew2
                value={modalCertificate}
                setValue={setModalCertificate}
                title="Приобретение сертификата"
            >
                <div className={styles.tokenModalCert}>
                    <div className={styles.tokenModalCertWrap}>
                        <div className={styles.tokenModalCardImg} style={{ position: 'relative' }}>
                            <VideoPlayer src={token.file_2} className="token__card--video" />
                        </div>

                        <div className={styles.tokenModalInfo}>
                            <div className={styles.tokenModalWrapperInfo}>
                                <p className={styles.tokenModalCollectionName}>
                                    {collection && collection.name}
                                </p>

                                <p className={styles.tokenModalTokenName}>{token && token.name}</p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.certInstructInner}>
                        <div className={styles.certInstructText}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M1 12C1 5.92472 5.92472 1 12 1C18.0753 1 23 5.92472 23 12C23 18.0753 18.0753 23 12 23C5.92472 23 1 18.0753 1 12ZM12 3C7.02928 3 3 7.02928 3 12C3 16.9707 7.02928 21 12 21C16.9707 21 21 16.9707 21 12C21 7.02928 16.9707 3 12 3Z"
                                    fill="#206FE2"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
                                    fill="#206FE2"
                                />
                            </svg>

                            <p className={styles.tokenModalCertTextAuto}>
                                Вы успешно приобрели сертификат!
                            </p>
                        </div>

                        <button
                            className={cn(base.orangeButton, styles.modalTokenButton)}
                            onClick={() => window.open('https://t.me/+5nhoVbOlL9dmNjJi', '_blank')}
                        >
                            Изучить инструкцию
                        </button>
                    </div>

                    <div className={styles.certKnowInner}>
                        <p className={styles.tokenModalKnowText}>Уже знаете что делать дальше?</p>

                        <button
                            onClick={() => setModalCertificate(false)}
                            className={cn(
                                base.orangeButton,
                                styles.modalTokenButton,
                                styles.disabled,
                            )}
                        >
                            Продолжить без инструкции
                        </button>
                    </div>

                    <DescriptionBlock>
                        <p>
                            Спасибо за Вашу оплату и поздравляем с успешным получением цифрового
                            сертификата на платформе CheckBrand!
                        </p>

                        <p>
                            Мы благодарны, что Вы выбрали нас. Рекомендуем ознакомиться с короткой
                            инструкцией по активации сертификата, чтобы упростить процесс.
                        </p>

                        <p>
                            Если Вы уже активировали сертификаты ранее или знаете, как это сделать,
                            можете продолжить без инструкции.
                        </p>

                        <p>Желаем Вам всех благ и отличного опыта с нашим сервисом!</p>
                    </DescriptionBlock>
                </div>
            </ModalNew2>
            {isUserOwner && (
                <TokenTransfer
                    modalCertificateSend={modalCertificateSend}
                    setModalCertificateSend={setModalCertificateSend}
                    token_id={id}
                    setTransferSuccess={setModalCertificateSuccess}
                    setCertSended={setCertSended}
                    token={token}
                    collectionName={collection ? collection.name : ''}
                    collectionId={collection ? collection.id : ''}
                />
            )}
        </div>
    ) : (
        <div className={base.marginWrapper}>
            <TokenNewSkeletonPage />
        </div>
    );
};

export default TokenNew;
//         <div className={styles.blockTokenContent}>
//             <div className={styles.blockPartnersContent}>
//                 <TokenPartnerOffer
//                     name="Виктор Сергеевич"
//                     text="Остальные мини окна оформления покупки и верификации я думаю интуитивно понятны."
//                     buttonText="сервис"
//                     buttonCallback={() =>
//                         setModalServiceGet(true)
//                     }
//                 />
//                 <TokenPartnerOffer
//                     name="Марина Иванова"
//                     text="Показ QR кода что бы другой пользователь мог зайти на страницу (нужно мини-окно с QR кодом"
//                     buttonText="скидка"
//                     buttonCallback={() =>
//                         setModalServiceGet2(true)
//                     }
//                 />
//                 <TokenPartnerOffer
//                     name="Владимир Владимирович Иванов"
//                     text="Цифровой сертификат позволяет владеть исключительными правами на изображение и получать вознаграждение"
//                     buttonText="подарок"
//                     buttonCallback={() =>
//                         setModalGift(true)
//                     }
//                 />
//                 <TokenPartnerOffer
//                     name="Виктор Сергеевич"
//                     text="Остальные мини окна оформления покупки и верификации я думаю интуитивно понятны."
//                     buttonText="сервис"
//                 />
//                 <TokenPartnerOffer
//                     name="Марина Иванова"
//                     text="Показ QR кода что бы другой пользователь мог зайти на страницу (нужно мини-окно с QR кодом"
//                     buttonText="скидка"
//                 />
//                 <TokenPartnerOffer
//                     name="Владимир Владимирович Иванов"
//                     text="Цифровой сертификат позволяет владеть исключительными правами на изображение и получать вознаграждение"
//                     buttonText="подарок"
//                 />
//             </div>
//         </div>
//     )}
// </div>

// СЛЕДУЩИЕ МОДАЛКИ ДОБАВИТЬ ПРИ ПОДКЛЮЧЕНИИ ПРЕДЛОЖЕНИЙ ПАРТНЕРОВ НА БЕКЕ
{
    /* {modalCertificateSuccess && <TokenTransferSuccess/>} */
}

{
    /* <ModalNew2 title="Спортивный клуб Fit Active" value={modalGift} setValue={setModalGift}>
                <div className={styles.tokenModalGift}>
                    <div className={styles.tokenModalGiftTitle}>
                        <svg
                            width="13"
                            height="16"
                            viewBox="0 0 13 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 10.2887H6.20457V16H1.61739C0.724034 16 0 15.297 0 14.4296V10.2887ZM13 10.2887V14.4296C13 15.297 12.276 16 11.3826 16H6.79543V10.2887H13ZM6.20457 4.00343V9.71478H0V5.57388C0 4.70645 0.724034 4.00343 1.61739 4.00343H6.20457ZM6.79543 4.00343H11.3826C12.276 4.00343 13 4.70645 13 5.57388V9.71478H6.79543V4.00343ZM9.93168 3.42955C10.1666 3.34237 10.3823 3.20784 10.5636 3.03184C10.9017 2.70367 11.0914 2.25845 11.0914 1.79429V1.7502C11.0914 1.28604 10.9017 0.840816 10.5636 0.51249C10.2256 0.18449 9.76707 0 9.28903 0H9.25624C8.6035 0 7.97766 0.251755 7.51627 0.699755C7.05471 1.14792 6.79543 1.75559 6.79543 2.38922V3.42955H9.93168ZM6.20457 3.42955V2.38922C6.20457 1.75559 5.94529 1.14792 5.48373 0.699755C5.02234 0.251755 4.3965 0 3.74376 0H3.71097C3.23293 0 2.7744 0.18449 2.43643 0.51249C2.09829 0.840816 1.90862 1.28604 1.90862 1.7502V1.79429C1.90862 2.25845 2.09829 2.70367 2.43643 3.03184C2.61769 3.20784 2.83342 3.34237 3.06832 3.42955H6.20457Z"
                                fill="#2069E2"
                            />
                        </svg>

                        <p>Подарок</p>
                    </div>

                    <p className={styles.tokenModalGiftText}>
                        Текст-заполнитель — это текст, который имеет некоторые характеристики
                        реального письменного текста, но является случайным набором слов или
                        сгенерирован иным образом. Его можно использовать для отображения образца
                        шрифтов, создания текста для тестирования или обхода спам-фильтра
                    </p>

                    <div className={styles.tokenModalGiftWrapper}>
                        <div className={styles.tokenModalGiftItem}>
                            <p>www.company10.com</p>
                            <p>7 567 890 1234</p>
                            <p>www.instagram.com/company10</p>
                            <p>info@company10.com</p>
                        </div>

                        <div className={styles.tokenModalGiftItem}>
                            <p>Адрес:</p>
                            <p>Москва, Россия, Москва,</p>
                            <p>улица Старый Арбат, 10</p>
                        </div>
                    </div>

                    <button className={cn(base.orangeButton, styles.modalTokenButton)}>
                        Воспользоваться услугой
                    </button>
                </div>
            </ModalNew2>

            <ModalNew2
                title="Получение услуги"
                value={modalServiceGet}
                setValue={setModalServiceGet}
            >
                <div className={styles.tokenModalGetService}>
                    <div className={styles.tokenModalGetServiceWrapper}>
                        <p className={styles.tokenModalGetServiceGiftName}>
                            Спортивный клуб Fit Active
                        </p>

                        <div className={styles.tokenModalGetServiceImg}>
                            <img src="/assets/img/avatar2.png" alt="img" />
                        </div>

                        <p className={styles.tokenModalGetServiceGift}>
                            <svg
                                width="13"
                                height="16"
                                viewBox="0 0 13 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0 10.2887H6.20457V16H1.61739C0.724034 16 0 15.297 0 14.4296V10.2887ZM13 10.2887V14.4296C13 15.297 12.276 16 11.3826 16H6.79543V10.2887H13ZM6.20457 4.00343V9.71478H0V5.57388C0 4.70645 0.724034 4.00343 1.61739 4.00343H6.20457ZM6.79543 4.00343H11.3826C12.276 4.00343 13 4.70645 13 5.57388V9.71478H6.79543V4.00343ZM9.93168 3.42955C10.1666 3.34237 10.3823 3.20784 10.5636 3.03184C10.9017 2.70367 11.0914 2.25845 11.0914 1.79429V1.7502C11.0914 1.28604 10.9017 0.840816 10.5636 0.51249C10.2256 0.18449 9.76707 0 9.28903 0H9.25624C8.6035 0 7.97766 0.251755 7.51627 0.699755C7.05471 1.14792 6.79543 1.75559 6.79543 2.38922V3.42955H9.93168ZM6.20457 3.42955V2.38922C6.20457 1.75559 5.94529 1.14792 5.48373 0.699755C5.02234 0.251755 4.3965 0 3.74376 0H3.71097C3.23293 0 2.7744 0.18449 2.43643 0.51249C2.09829 0.840816 1.90862 1.28604 1.90862 1.7502V1.79429C1.90862 2.25845 2.09829 2.70367 2.43643 3.03184C2.61769 3.20784 2.83342 3.34237 3.06832 3.42955H6.20457Z"
                                    fill="#2069E2"
                                />
                            </svg>
                            Подарок
                        </p>

                        <p className={styles.tokenModalGetServiceGiftText}>
                            Выберите способ связи для согласования предоставления услуги
                        </p>
                    </div>

                    <div className={styles.tokenModalGetServiceButtons}>
                        <button
                            className={cn(styles.tokenModalGetServiceButton, styles.blue)}
                            onClick={() => {
                                setModalServiceGet(false);
                                setModalTg(true);
                            }}
                        >
                            Телеграм
                        </button>

                        <button
                            className={cn(styles.tokenModalGetServiceButton, styles.green)}
                            onClick={() => {
                                setModalServiceGet(false);
                                setModalWhatsapp(true);
                            }}
                        >
                            WhatsApp
                        </button>
                    </div>

                    <DescriptionBlock>
                        <p>
                            Для того чтобы воспользоваться услугой партнёра платформы CheckBrand,
                            Вам необходимо выбрать способ связи с менеджером сервиса.
                        </p>

                        <p>Вы можете выбрать общение через Telegram или WhatsApp.</p>

                        <p>
                            Если у Вас еще не указаны контакты для связи, не переживайте — на
                            следующем шаге Вам будет предложено ввести их.
                        </p>

                        <p>
                            После этого менеджер сервиса свяжется с Вами для согласования
                            предоставления выбранной услуги.
                        </p>

                        <p>Мы ценим Ваше время и стремимся сделать процесс максимально удобным.</p>

                        <p>Спасибо, что выбираете CheckBrand! Желаем Вам отличного дня!</p>
                    </DescriptionBlock>
                </div>
            </ModalNew2>

            <ModalNew2
                title="Добавление контакта"
                value={modalWhatsapp}
                setValue={setModalWhatsapp}
            >
                <div className={styles.tokenModalGetService}>
                    <InputNew title="WhatsApp" placeholder="Укажите номер WhatsApp" />

                    <button className={cn(base.orangeButton, styles.modalTokenButton)}>
                        Продолжить
                    </button>
                </div>
            </ModalNew2>

            <ModalNew2 title="Добавление контакта" value={modalTg} setValue={setModalTg}>
                <div className={styles.tokenModalGetService}>
                    <InputNew title="Телеграм" placeholder="Укажите аккаунт Телеграм" />

                    <button className={cn(base.orangeButton, styles.modalTokenButton)}>
                        Продолжить
                    </button>
                </div>
            </ModalNew2>

            <ModalNew2
                title="Получение услуги"
                value={modalServiceGet2}
                setValue={setModalServiceGet2}
            >
                <div className={styles.tokenModalGetService}>
                    <div className={styles.tokenModalGetServiceWrapper}>
                        <p className={styles.tokenModalGetServiceGiftName}>
                            Спортивный клуб Fit Active
                        </p>

                        <div className={styles.tokenModalGetServiceImg}>
                            <img src="/assets/img/avatar2.png" alt="img" />
                        </div>

                        <p className={styles.tokenModalGetServiceTitle}>
                            WhatsApp: 7 999 555 77 66
                        </p>

                        <p
                            className={styles.tokenModalGetServiceGiftText}
                            style={{ maxWidth: 220 }}
                        >
                            Менеджер свяжется с вами в течение минуты
                        </p>
                    </div>

                    <button className={cn(base.orangeButton, styles.modalTokenButton)}>
                        Благодарим вас
                    </button>
                </div>
            </ModalNew2> */
}
