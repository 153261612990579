import React from 'react';
import cn from 'classnames';

import styles from './index.module.css';

const Tab = ({ disabled = false, active = false, left = false, right = false, size = 'big', children, ...props }) => {
    return (
        <button
            disabled={disabled}
            className={cn(styles.tabsItem, styles[size], {
                [styles.tabsLeft]: left,
                [styles.tabsRight]: right,
                [styles.active]: active,
            })}
            {...props}
        >
            {children}
        </button>
    );
};

export default Tab;
