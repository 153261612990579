import React from 'react';
import cn from 'classnames';

import styles from '../index.module.css';
import { Link } from 'react-router-dom';
import ImgBlockItem from '../../../components/ImgBlock/ImgBlockItem';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { S3Context } from '../../../context/S3Context';

const CertificatesItemMini = ({
    certificate,
    view = 'big',
    invited = false,
    filterOpen = false,
}) => {
    const { id, name, file_1, investor_royalty: investorRoyalty, purchased, price, price_type, percent } = certificate;
    const {
        actions: { getImageBacketPath },
    } = React.useContext(S3Context);

    return (
        <div
            className={cn(styles.certificateItemWrapperMini, styles[view], {
                [styles.invited]: invited,
                [styles.filterOpen]: filterOpen,
            })}
        >
            <Link to={`/token/${id}`} className={styles.certificateItemImgInner}>
                <div className={styles.brandItem}>
                    {view !== 'small' && (
                        <img
                            src="/assets/img/element-fire.png"
                            alt="decor"
                            className={styles.brandItemDecor}
                        />
                    )}

                    <img src={file_1} alt={name} />

                    {view !== 'small' && (
                        <div className={styles.certificateItemInfo}>
                            <p className={styles.brandItemInfoName}>{name}</p>

                            {!purchased && !invited && (
                                <div className={styles.certificateItemInfoTags}>
                                    <p className={styles.brandItemInfoTag}>
                                        {/* {investorRoyalty}% стоимости авто */}
                                        {percent
                                            ? `${percent}% стоимости авто`
                                            : `${Math.floor(price / 100).toLocaleString(
                                                  'ru-RU',
                                              )} RUB`}
                                    </p>
                                </div>
                            )}

                            {!purchased && !invited ? (
                                <p className={styles.certificateItemText}>Оформить на свой авто</p>
                            ) : (
                                <p className={styles.certificateItemTextBuyed}>
                                    {invited ? 'Отправлен пользователю' : 'Куплен за'}
                                </p>
                            )}

                            {!purchased && !invited ? (
                                <Link className={styles.certificateItemButton} to={`/token/${id}`}>
                                    Оформить
                                </Link>
                            ) : (
                                <div className={styles.certificateItemButtonBuyed}>
                                    {Math.floor(price / 100).toLocaleString('ru-RU')} RUB
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Link>
        </div>
    );
};

export default CertificatesItemMini;
