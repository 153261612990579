import React from 'react';
import cn from 'classnames';
import styles from '../../index.module.css';
import ModalNew from '../../../../common/ModalNew';
import Check3 from '../../../../icons/Check3';
import base from '../../../../styles/base.module.css';
export const CollabModal = ({ orderThx, setOrderThx }) => {
    return (
        <ModalNew active={orderThx} setActive={setOrderThx}>
            <div className={styles.modalSuccess}>
                <Check3 />

                <p style={{ color: 'white' }} className={cn(styles.collabSuccessTitle)}>
                    Ваша заявка принята
                </p>

                <p className={styles.modalSuccessText}>
                    Менеджер свяжется с вами в течение 30 мин <span>(в рабочее время)</span>
                </p>
            </div>
        </ModalNew>
    );
};
