import React, { useEffect, useState } from 'react';

export const TimerLastUpdate = ({ resetTokensList }) => {
    const [minutes, setMinutes] = useState(0);

    useEffect(() => {
      
        setMinutes(0);

        const updateInterval = setInterval(() => {
            setMinutes((prevMinutes) => prevMinutes + 1);
        }, 60000);
        
    
        return () => clearInterval(updateInterval);
    }, [resetTokensList]);

    return <p>Обновлено {minutes} мин назад</p>;
};