import React from 'react';
import StepItem from '../StepItem';
import Doc from '../../../../icons/Doc';
import Download from '../../../../icons/Download';
import Card from '../../../../icons/Card';
import Cash from '../../../../icons/Cash';
import Web from '../../../../icons/Web';
// import Link from '../../../../icons/Link';
import { Link } from 'react-router-dom';
import styles from '../../index.module.css';
import base from '../../../../styles/base.module.css';
import cn from 'classnames';
import Order from '../../../../icons/Order';
import { transferTokenTitles, qrGivingTitles } from './consts';
const paymentVariants = [
    {
        title: 'Карта',
        icon: <Card />,
    },
    {
        title: 'Квитанция',
        icon: <Order />,
    },
    {
        title: 'Наличные',
        icon: <Cash />,
    },
    {
        title: 'платежная система',
        icon: <Web />,
    },
];

const CooperationStages = () => {
    const loadDocument = () => {
        const fileUrl = '/assets/1.pdf';
        const fileName = 'sample_agreement.pdf';

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        link.click();
    };

    return (
        <section className={styles.steps}>
            <img src="/assets/img/blur-left.svg" alt="blur" className={styles.stepsBlur} />

            <div className={base.container}>
                <div id="stages_and_deadlines" className={styles.stepsInner}>
                    <h2 className={cn(base.title2, styles.stepsTitle)}>
                        Этапы и сроки
                        <br />
                        сотрудничества
                    </h2>

                    <div className={styles.stepsContent}>
                        <StepItem
                            number={1}
                            name="Подписание договора и произведение оплаты"
                            time="от 1-го до 3-х дней"
                            image="/assets/img/sber.svg"
                            openDefault
                        >
                            <div className={styles.stepItemTextInner}>
                                <div className={styles.stepItemDocument} onClick={loadDocument}>
                                    <div className={styles.stepItemDocumentInner}>
                                        <Doc />

                                        <div className={styles.stepItemDocumentTextInner}>
                                            <p className={styles.stepItemDocumentName}>
                                                Образец договора
                                            </p>

                                            <p className={styles.stepItemDocumentWeight}>115 kb</p>
                                        </div>
                                    </div>

                                    <Download className={styles.stepItemDocumentDownload} />
                                </div>

                                <div className={styles.stepItemVariantsInner}>
                                    <p className={styles.stepItemVariantsTitle}>
                                        Варианты приема оплаты:
                                    </p>

                                    <div className={styles.stepItemVariants}>
                                        {paymentVariants.map((variant, index) => {
                                            return (
                                                <div
                                                    className={styles.stepItemVariantItem}
                                                    key={index}
                                                >
                                                    <div className={styles.stepItemVariantItemIcon}>
                                                        {variant.icon}
                                                    </div>

                                                    <p className={styles.stepItemVariantItemText}>
                                                        {variant.title}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <p className={styles.stepItemSubtitle}>
                                    После подписания договора и произведения оплаты переходим к
                                    выпуску сертификатов
                                </p>
                            </div>
                        </StepItem>

                        <StepItem
                            number={2}
                            name="Разработка дизайна цифровых сертификатов"
                            time="от 3-х до 10-и дней"
                            image="/assets/img/step2.svg"
                        >
                            <div className={styles.stepItemTextInner}>
                                <p className={styles.stepItemSubtitle}>
                                    Специалисты CheckBrand подготовят дизайн цифровых сертификатов
                                </p>

                                <ul className={styles.stepItemUl}>
                                    <li>
                                        Разработаем дизайн и создадим изображения для Ваших цифровых
                                        сертификатов.
                                    </li>
                                    <li>
                                        Осуществим разработку текстового контента и описаний для
                                        Вашей коллекции цифровых сертификатов.
                                    </li>
                                </ul>

                                <Link
                                    className={cn(base.orangeButton, styles.stepItemButton)}
                                    to="/brandsnew/certificates"
                                >
                                    Смотреть циф. сертификаты
                                </Link>
                            </div>
                        </StepItem>

                        <StepItem
                            number={3}
                            name="Выпуск цифровых сертификатов"
                            time="от 1-го до 2-х дней"
                            image="/assets/img/step3.png"
                        >
                            <div className={styles.stepItemTextInner}>
                                <p className={styles.stepItemSubtitle}>
                                    После утверждения дизайна мы выпустим цифровые сертификаты
                                </p>

                                <ul className={styles.stepItemUl}>
                                    <li>Разместим на платформе коллекцию бренда.</li>
                                    <li>
                                        Добавим в коллекцию согласованное количество цифровых
                                        сертификатов.
                                    </li>
                                    <li>
                                        Наполним коллекцию подробными описаниями товаров и привлечём
                                        партнеров для популяризации.
                                    </li>
                                </ul>

                                <Link
                                    className={cn(base.orangeButton, styles.stepItemButton)}
                                    to="/brandsnew/collections"
                                >
                                    Смотреть коллекции брендов
                                </Link>
                            </div>
                        </StepItem>

                        <StepItem
                            number={4}
                            name="Предоставление QR-кодов для размещения на продукции"
                            time="от 1-го до 5-и дней"
                            image="/assets/img/step4.png"
                        >
                            <div className={styles.stepItemTextInner}>
                                {qrGivingTitles.map((title, index) => {
                                    return (
                                        <p
                                            key={index}
                                            style={{fontWeight:300}}
                                            className={
                                                // index === 1
                                                     cn(styles.stepItemSubtext, styles.margin)
                                                    // :styles.stepItemSubtitle
                                            }
                                        >
                                            {title}
                                        </p>
                                    )
                                })}
                            </div>
                        </StepItem>

                        <StepItem
                            number={5}
                            name="Передача цифровых сертификатов покупателям продукции"
                            time="До окончания сотрудничества"
                            image="/assets/img/step5.png"
                        >
                            <div className={styles.stepItemTextInner}>
                                {transferTokenTitles.map((title, index) => {
                                    return (
                                        <p
                                        style={{fontWeight:300}}
                                            key={index}
                                            className={
                                                index === 1
                                                    ? cn(styles.stepItemSubtext, styles.margin)
                                                    : styles.stepItemSubtitle
                                            }
                                        >
                                            {title}
                                        </p>
                                    );
                                })}
                            </div>
                        </StepItem>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CooperationStages;
