import React, { useEffect, useMemo, useState, useCallback } from 'react';
import TableCell from '../../../components/TableRow/TableCell';
import TableRow from '../../../components/TableRow';
import { BREAKPOINTS } from '../../../const/breakpoints/BREAKPOINTS';
import { Link, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDeleteCollectionMutation } from '../../../redux/api/dataService';
import { CancelModal } from '../../Сonclusions/modals/CancelModal';
import { useDynamicPagination } from '../../../hooks/useDynamicPagination/useDynamicPagination';
import { useGetTableView } from '../../../hooks/useGetTableView/useGetTableView';
import { useGenerateRoutes } from '../../../hooks/useGenerateRoutes/useGenerateRoutes';
import { expandListOfParameters } from '../../utils';
import { setParentsList } from '../Container/tableSlice';

const TABLE_URL = `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/collection/`;
const Collections = () => {
    const location = useLocation();
    const { pageId, accountId } = useParams();
    const currentUrl = window.location.href.split('/');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getTableViewParam = Boolean(new URLSearchParams(window.location.search).get('all'));
    const [isDelete, setIsDelete] = useState(false);
    const [clickedItemId, setClickedItemId] = useState();
    const [deleteCollection] = useDeleteCollectionMutation();
    const [requestParams, isResetList, setIsResetList, setCount, setCurrentPage] =
        useOutletContext();
    useGetTableView(location.pathname, getTableViewParam, setCurrentPage, 'collections', [
        pageId,
        accountId,
    ]);
    useGenerateRoutes([
        { title: 'page', id: pageId },
        { title: 'account', id: accountId },
    ],dispatch,setParentsList);
    const params = useMemo(() => {
        return expandListOfParameters(requestParams, [
            {
                title: 'account_id',
                value: accountId,
            },
            {
                title: 'page_id',
                value: pageId,
            },
        ]);
    }, [accountId, pageId, requestParams, requestParams]);

    const items = useDynamicPagination(TABLE_URL, params,true, isResetList, setIsResetList, 'handler_service');
    useEffect(() => {
        if (items) {
            setCount(items.count);
        }
    }, [items]);

    const handleDeleteCollectionRequest = () => {
        if (clickedItemId) {
            deleteCollection({
                id: clickedItemId,
            })
                .unwrap()
                .finally(() => {
                    setIsDelete(false);
                });
        }
    };

    const handleNavigate = useCallback(
        (el, type) => {
            if (type === 'navigateNext' && el && el.page && el.account) {
                navigate(`../packs/${el.page.id}/${el.id}/${el.account.id}`);
            }
            if (type === 'navigateEdit' && el && el.page && el.account) {
                navigate(`../../updatecollection/${el.id}/${el.page.id}`);
            }
        },
        [items],
    );

    const handleDownloadExcelCollection = (el) => {
        const accessToken = localStorage.getItem('access_token');
        if (el) {
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/factory/shorts/download_excel_for_collection/${el.id}/`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                },
            )
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = `${el.name}.xls`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
        }
    };
    const handleDownloadQrCollection = (el) => {
        const accessToken = localStorage.getItem('access_token');
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/factory/shorts/download_qr_codes_for_collection/${el.id}/`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json',
                },
            },
        )
            .then((response) => response.blob())
            .then((blob) => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = `${el.name}.zip`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    };

    return (
        <>
            <TableRow names differentColumn>
                <TableCell title largeColumn>
                    Статус
                </TableCell>

                <TableCell title largeColumn>
                    Коллекции
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD700}>
                    Токены
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD620}>
                    Доход
                </TableCell>

                <TableCell title textCenter textOrange breakpoint={BREAKPOINTS.MD1200}>
                    Продано
                </TableCell>

                <TableCell title textCenter textGreen breakpoint={BREAKPOINTS.MD1200}>
                    Забронировано
                </TableCell>

                <TableCell title textCenter textBlue breakpoint={BREAKPOINTS.MD1200}>
                    Отчеканено
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1400}>
                    Chain
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1600}>
                    Fee
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1600}>
                    Admin
                </TableCell>
            </TableRow>

            {items &&
                items.items.map((el, i) => {
                    return (
                        <TableRow
                            differentColumn
                            actionType="factoryCollection"
                            key={i}
                            handleDownloadExcelCollection={() => handleDownloadExcelCollection(el)}
                            handleDownloadQrCollection={() => handleDownloadQrCollection(el)}
                        >
                            <TableCell
                                largeColumn
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                <p className="whithdraw__index">{i + 1}</p>

                                <div className="stats__item--status--circle white"></div>

                                <div className="stats__item--wrapper withdraw__item">
                                    <p className="withdraw__item--collection--name stats__item--text--white">
                                        {el.status}
                                    </p>
                                </div>
                            </TableCell>

                            <TableCell
                                largeColumn
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                <div className="stats__item--avatar--inner withdraw__item">
                                    <img
                                        src={`${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${el.logo}`}
                                        alt={'img'}
                                        style={{
                                            objectFit: 'cover',
                                            width: '48px',
                                            height: '48px',
                                        }}
                                    />
                                </div>

                                <div className="stats__item--wrapper withdraw__item">
                                    <Link className="withdraw__item--collection--name">
                                        {el.name}
                                    </Link>
                                </div>
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD700}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                {el.items_count}
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD620}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                {Math.floor(el.volume_troded_count / 100).toLocaleString('ru-RU')}{' '}
                                RUB
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                textOrange
                                breakpoint={BREAKPOINTS.MD1200}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                {el.owners_count}
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                textGreen
                                breakpoint={BREAKPOINTS.MD1200}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                -
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                textBlue
                                breakpoint={BREAKPOINTS.MD1200}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                -
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD1400}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                -
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD1600}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                -
                            </TableCell>

                            <TableCell
                                scroll
                                textCenter
                                link
                                breakpoint={BREAKPOINTS.MD1600}
                                onClick={() => handleNavigate(el, 'navigateNext')}
                            >
                                <Link to="/">User name</Link>
                                <Link to="/">User name</Link>
                                <Link to="/">User name</Link>
                                <Link to="/">User name</Link>
                                <Link to="/">User name</Link>
                            </TableCell>
                        </TableRow>
                    );
                })}
            <CancelModal
                handleOk={() => {
                    handleDeleteCollectionRequest();
                }}
                setActive={setIsDelete}
                active={isDelete}
                text={'Подтверждение на удаление'}
                okBtnText={'Удалить'}
                rejectBtnText={'Отмена'}
            />
        </>
    );
};

export default Collections;
