import React, { useEffect, useRef, useState } from 'react';

const VideoPlayer = (props) => {
    const videoPlayerRef = useRef(null);
    const { src,  className } = props;
    useEffect(() => {
        if (videoPlayerRef.current) {
            videoPlayerRef.current.load()
        }
       
    },[src])

    return (
        <video
            ref={videoPlayerRef}
            autoPlay
            muted
            controls
            className={className}
            // loop
            style={{ height: '100%', width: '100%' }}
        >
            <source src={src} />
        </video>
    );
};

export default VideoPlayer;
