import React, { useEffect, useState } from 'react';
import { HistoricalRecordItem } from './HistoricalRecordItem';
import Document from '../../icons/Document';
import cn from 'classnames';
import styles from './index.module.css';
import base from '../../styles/base.module.css';
const fetchTokenHistory = (setHistory, id) => {
    const access_token = localStorage.getItem('access_token');
    fetch(
        `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/billing/user/token_history/${id}`,
        {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        },
    )
        .then((res) => {
            return res.json();
        })
        .then((response) => {
            if (response.length > 0 ) {
                setHistory(response);
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

export const TokenHistory = ({
    id,
    certSended,
    setCertSended,
    setModalCertificateSend,
    setModalCertificateCancel,
    isUserOwner,
}) => {
    const [history, setHistory] = useState([]);

    useEffect(() => {
        fetchTokenHistory(setHistory, id);
    }, [id]);

    return (
        <div className={styles.blockToken} style={{ borderRadius: 25 }}>
            <div className={styles.blockTokenTop}>
                <p className={styles.blockTokenTitle}>
                    <Document />
                    История активности
                </p>
                {isUserOwner && (
                    <>
                        {certSended ? (
                            <button
                                className={styles.blockTokenButtonCancel}
                                onClick={() => {
                                    setCertSended(false);
                                    setModalCertificateCancel(true);
                                }}
                            >
                                Отменить передачу
                            </button>
                        ) : (
                            <button
                                className={styles.blockTokenButton}
                                onClick={() => {
                                    // setCertSended(true);
                                    setModalCertificateSend(true);
                                }}
                            >
                                Передать сертификат
                            </button>
                        )}
                    </>
                )}

                <></>
            </div>

            <div className={styles.blockTokenContent}>
                <div className={styles.blockTokenHistoryScroll}>
                    <div className={styles.blockTokenHistory}>
                        <div className={styles.blockTokenHistoryHead}>
                            <p className={styles.blockTokenHistoryEvent}>Событие</p>
                            <p className={styles.blockTokenHistoryPrice}>Цена (RUB)</p>
                            <p className={styles.blockTokenHistorySender}>Отправитель</p>
                            <p className={styles.blockTokenHistoryGet}>Получатель</p>
                            <p className={styles.blockTokenHistoryDate}>Дата</p>
                        </div>

                        {history &&
                            history.map((historyRecord, index) => {
                                return (
                                    <HistoricalRecordItem
                                        historyRecord={historyRecord}
                                        key={index}
                                    />
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};
