import { handleFetchSMSCode } from '../../utils/utils';

export const handleSendCodeByVerifyStep = (currentStep, contacts, setError) => {

    const verifyVariants = {
        2: contacts.phone,
        1: contacts.email,
    };

    const currentCodeType = verifyVariants[currentStep];

    if (currentCodeType) {
        handleFetchSMSCode(currentCodeType, setError, currentStep);
    }
};
