import React from 'react';
import cn from 'classnames';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from '../index.module.css';
import base from '../../../styles/base.module.css';

import ArrowLeftShort from '../../../icons/ArrowLeftShort';
import ArrowRightShort from '../../../icons/ArrowRightShort';
import Clothes from '../../../icons/Clothes';
import Alcohol from '../../../icons/Alcohol';
import Car from '../../../icons/Car';
import Watch from '../../../icons/Watch';
import Check2 from '../../../icons/Check2';
import MoneyBust from '../../../icons/MoneyBust';
import Raise from '../../../icons/Raise';
import Eye from '../../../icons/Eye';
import Crown from '../../../icons/Crown';
import Check3 from '../../../icons/Check3';
import { sendForm } from '../lib/requests';
import { Link } from 'react-router-dom';

export const CollabForm = ({ setOrderThx }) => {
    const [collabStep, setCollabStep] = React.useState(1);
    const [userEmail, setUserEmail] = React.useState('');
    const [userName, setUserName] = React.useState('');
    const [userPhone, setUserPhone] = React.useState('');
    const [brandType, setBrandType] = React.useState('clothes');
    const [mainTask, setMainTask] = React.useState('increase_number_sales');
    const [nameBrand, setNameBrand] = React.useState('');
    const [errors, setErrors] = React.useState({});

    const handleNextStep = async () => {
        const validateForm = () => {
            const newErrors = {};

            if (collabStep === 3) {
                if (!nameBrand) newErrors.nameBrand = 'Название бренда не может быть пустым';
                if (!userName) newErrors.userName = 'Имя представителя не может быть пустым';
                if (!userPhone) {
                    newErrors.userPhone = 'Телефон не может быть пустым';
                } else if (!/^\+?\d{10,15}$/.test(userPhone)) {
                    newErrors.userPhone = 'Неверный формат номера телефона';
                }
                if (userEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmail)) {
                    newErrors.userEmail = 'Неверный формат e-mail';
                }

                if (Object.values(newErrors).length === 0) {
                    setOrderThx(true)
                }
            }

            setErrors(newErrors);
            return Object.keys(newErrors).length === 0;
        };

        if (validateForm()) {
            if (collabStep < 3) {
                setCollabStep((prev) => prev + 1);
            } else {
                const result = await sendForm(
                    userName,
                    userPhone,
                    brandType,
                    mainTask,
                    nameBrand,
                    userEmail,
                );
                if (result) {
                    setCollabStep((prev) => prev + 1);
                }
            }
        }
    };
    return (
        <div className={styles.collabContentWrapper}>
            <div className={styles.collabContent}>
                {collabStep < 4 && (
                    <img
                        src="/assets/img/collab-bg.png"
                        alt="collab bg"
                        className={styles.collabBg}
                    />
                )}

                {collabStep < 2 && (
                    <img
                        src="/assets/img/collab-img.png"
                        alt="collab img"
                        className={styles.collabImg}
                    />
                )}

                {collabStep > 1 && collabStep < 4 && (
                    <div
                        className={styles.collabSteps}
                        onClick={() => {
                            setCollabStep(1);
                        }}
                    >
                        <div className={styles.collabStep}>
                            <p className={styles.collabStepNumber}>1</p>
                            {brandType == 'clothes' && (
                                <p className={styles.collabStepText}>Бренд одежды </p>
                            )}
                            {brandType == 'watches' && (
                                <p className={styles.collabStepText}>Бренд часов</p>
                            )}
                            {brandType == 'cars' && (
                                <p className={styles.collabStepText}>Автомобильный бренд</p>
                            )}
                            {brandType == 'alcohol' && (
                                <p className={styles.collabStepText}>Бренд алкоголя</p>
                            )}
                        </div>

                        {collabStep > 2 && (
                            <div
                                className={styles.collabStep}
                                onClick={() => {
                                    setCollabStep(1);
                                }}
                            >
                                <p className={styles.collabStepNumber}>2</p>
                                {mainTask == 'increase_number_sales' && (
                                    <p className={styles.collabStepText}>
                                        Увеличить количество продаж
                                    </p>
                                )}
                                {mainTask == 'increase_brand_awareness' && (
                                    <p className={styles.collabStepText}>
                                        Повысить узнаваемость бренда
                                    </p>
                                )}
                                {mainTask == 'increase_cost_products' && (
                                    <p className={styles.collabStepText}>
                                        Поднять стоимость продукции
                                    </p>
                                )}
                                {mainTask == 'increase_brand_prestige' && (
                                    <p className={styles.collabStepText}>Повысить престиж бренда</p>
                                )}
                            </div>
                        )}
                    </div>
                )}

                <div
                    className={cn(styles.collabForm, {
                        [styles.full]: collabStep === 4,
                    })}
                >
                    <div className={styles.collabFormProgressWrap}>
                        {collabStep < 4 && (
                            <div className={styles.collabFormTitleInner}>
                                <p className={styles.collabFormTitleNumber}>{collabStep}</p>

                                <p className={styles.collabFormTitle}>
                                    {collabStep === 1 && 'Какой бренд вы представляете?'}
                                    {collabStep === 2 && 'Какая основная задача вашего бренда?'}
                                    {collabStep === 3 &&
                                        'Заполните форму и мы пришлём проверенный гайд для решения вашей задачи'}
                                </p>
                            </div>
                        )}

                        <div className={styles.collabFormProgress}>
                            <div className={styles.collabFormProgressLine}>
                                <img
                                    src="/assets/img/progress.svg"
                                    alt="progress"
                                    className={styles.collabFormProgressBar}
                                    style={{
                                        width: `${((collabStep - 1) / 3) * 100 + 10}%`,
                                    }}
                                />
                            </div>

                            <p className={styles.collabFormProgressText}>
                                Готово: {(((collabStep - 1) / 3) * 100).toFixed(0)}%
                            </p>
                        </div>
                    </div>

                    {collabStep === 1 && (
                        <div className={styles.collabFormContent}>
                            <div
                                className={cn(styles.collabFormVariant, {
                                    [styles.active]: brandType === 'clothes',
                                })}
                                onClick={() => {
                                    setBrandType('clothes');
                                }}
                            >
                                <p className={styles.collabFormVariantName}>
                                    <Clothes />
                                    Бренд одежды
                                </p>

                                <div className={styles.collabFormVariantIndicator}>
                                    <Check2 />
                                </div>
                            </div>

                            <div
                                className={cn(styles.collabFormVariant, {
                                    [styles.active]: brandType === 'watches',
                                })}
                                onClick={() => {
                                    setBrandType('watches');
                                }}
                            >
                                <p className={styles.collabFormVariantName}>
                                    <Watch />
                                    Бренд часов
                                </p>

                                <div className={styles.collabFormVariantIndicator}>
                                    <Check2 />
                                </div>
                            </div>

                            <div
                                className={cn(styles.collabFormVariant, {
                                    [styles.active]: brandType === 'cars',
                                })}
                                onClick={() => {
                                    setBrandType('cars');
                                }}
                            >
                                <p className={styles.collabFormVariantName}>
                                    <Car />
                                    Автомобильный бренд
                                </p>

                                <div className={styles.collabFormVariantIndicator}>
                                    <Check2 />
                                </div>
                            </div>

                            <div
                                className={cn(styles.collabFormVariant, {
                                    [styles.active]: brandType === 'alcohol',
                                })}
                                onClick={() => {
                                    setBrandType('alcohol');
                                }}
                            >
                                <p className={styles.collabFormVariantName}>
                                    <Alcohol />
                                    Бренд алкоголя
                                </p>

                                <div className={styles.collabFormVariantIndicator}>
                                    <Check2 />
                                </div>
                            </div>
                        </div>
                    )}

                    {collabStep === 2 && (
                        <div className={styles.collabFormContent}>
                            <div
                                className={cn(styles.collabFormVariant, {
                                    [styles.active]: mainTask === 'increase_number_sales',
                                })}
                                onClick={() => {
                                    setMainTask('increase_number_sales');
                                }}
                            >
                                <p className={styles.collabFormVariantName}>
                                    <MoneyBust />
                                    Увеличить количество продаж
                                </p>

                                <div className={styles.collabFormVariantIndicator}>
                                    <Check2 />
                                </div>
                            </div>

                            <div
                                className={cn(styles.collabFormVariant, {
                                    [styles.active]: mainTask === 'increase_cost_products',
                                })}
                                onClick={() => {
                                    setMainTask('increase_cost_products');
                                }}
                            >
                                <p className={styles.collabFormVariantName}>
                                    <Raise />
                                    Поднять стоимость продукции
                                </p>

                                <div className={styles.collabFormVariantIndicator}>
                                    <Check2 />
                                </div>
                            </div>

                            <div
                                className={cn(styles.collabFormVariant, {
                                    [styles.active]: mainTask === 'increase_brand_awareness',
                                })}
                                onClick={() => {
                                    setMainTask('increase_brand_awareness');
                                }}
                            >
                                <p className={styles.collabFormVariantName}>
                                    <Eye />
                                    Повысить узнаваемость бренда
                                </p>

                                <div className={styles.collabFormVariantIndicator}>
                                    <Check2 />
                                </div>
                            </div>

                            <div
                                className={cn(styles.collabFormVariant, {
                                    [styles.active]: mainTask === 'increase_brand_prestige',
                                })}
                                onClick={() => {
                                    setMainTask('increase_brand_prestige');
                                }}
                            >
                                <p className={styles.collabFormVariantName}>
                                    <Crown />
                                    Повысить престиж бренда
                                </p>

                                <div className={styles.collabFormVariantIndicator}>
                                    <Check2 />
                                </div>
                            </div>
                        </div>
                    )}

                    {collabStep === 3 && (
                        <div className={styles.collabFormContent}>
                            <div className={styles.collabFormInputItem}>
                                <p className={styles.collabFormInputName}>Название бренда</p>

                                <input
                                    type="text"
                                    placeholder="Введите название"
                                    className={styles.collabFormInput}
                                    onChange={(e) => setNameBrand(e.target.value)}
                                />
                                {errors.nameBrand && (
                                    <p className={styles.errorText}>{errors.nameBrand}</p>
                                )}
                            </div>

                            <div className={styles.collabFormInputItem}>
                                <p className={styles.collabFormInputName}>
                                    Имя представителя бренда
                                </p>

                                <input
                                    type="text"
                                    placeholder="Введите имя и фамилию"
                                    className={styles.collabFormInput}
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                                {errors.userName && (
                                    <p className={styles.errorText}>{errors.userName}</p>
                                )}
                            </div>

                            <div className={styles.collabFormInputItem}>
                                <p className={styles.collabFormInputName}>Телефон для связи</p>

                                <input
                                    type="text"
                                    placeholder="Введите телефон"
                                    className={styles.collabFormInput}
                                    onChange={(e) => setUserPhone(e.target.value)}
                                />
                                {errors.userPhone && (
                                    <p className={styles.errorText}>{errors.userPhone}</p>
                                )}
                            </div>

                            <div className={styles.collabFormInputItem}>
                                <p className={styles.collabFormInputName}>E-mail</p>

                                <input
                                    type="text"
                                    placeholder="Введите e-mail"
                                    className={styles.collabFormInput}
                                    onChange={(e) => {
                                        setUserEmail(e.target.value);
                                    }}
                                />
                                {errors.userEmail && (
                                    <p className={styles.errorText}>{errors.userEmail}</p>
                                )}
                            </div>
                        </div>
                    )}

                    {collabStep === 4 && (
                        <div className={styles.collabSuccess}>
                            <Check3 />

                            <p className={cn(base.title2, styles.collabSuccessTitle)}>
                                Ваша заявка принята
                            </p>

                            <p className={styles.collabSuccessText}>
                                Уже занимаемся вашим проектом
                            </p>
                        </div>
                    )}

                    {collabStep < 4 && (
                        <div className={styles.collabFormButtons}>
                            <button
                                className={cn(base.orangeButton, styles.collabFormButton, {
                                    [styles.disabled]: collabStep === 1,
                                })}
                                onClick={() => setCollabStep((prev) => prev - 1)}
                            >
                                <ArrowLeftShort />
                                Назад
                            </button>

                            {collabStep < 3 ? (
                                <button
                                    className={cn(base.orangeButton, styles.collabFormButton)}
                                    onClick={handleNextStep}
                                >
                                    Вперед
                                    <ArrowRightShort />
                                </button>
                            ) : (
                                <button
                                    className={styles.collabFormButtonSend}
                                    onClick={() => {
                                        handleNextStep();
                                    }}
                                >
                                    Отправить
                                </button>
                            )}

                            {collabStep === 3 && <p className={styles.mainformAgree}>
                                Нажимая на кнопку «Отправить», вы даёте своё согласие на
                                обработку персональных данных в соответствии с{' '}
                                <Link to="/privacy">Политикой конфиденциальности</Link>
                            </p>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
