import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import TableCell from '../../../components/TableRow/TableCell';
import TableRow from '../../../components/TableRow';
import { BREAKPOINTS } from '../../../const/breakpoints/BREAKPOINTS';
import { Link, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import './style.css';
import { ApproveCancelModal } from './ApproveCancelModal';
import { useDynamicPagination } from '../../../hooks/useDynamicPagination/useDynamicPagination';
import { useGetTableView } from '../../../hooks/useGetTableView/useGetTableView';
import { useGenerateRoutes } from '../../../hooks/useGenerateRoutes/useGenerateRoutes';
import { expandListOfParameters } from '../../utils';
import { ChangeLinkQr } from './ChangeLinkQr';
import { QrCode } from '@mui/icons-material';
import { setParentsList } from '../Container/tableSlice';

moment.locale('ru');

const accessToken = localStorage.getItem('access_token');
const TABLE_URL = `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/token/factory`;

const variants = {
    sold: 'продано',
    transfer: 'передается',
    posted: 'размещено',
};

const colorVariants = {
    sold: 'blue',
    transfer: '#4bef4b',
    posted: 'white',
};

const Tokens = () => {
    const navigate = useNavigate();
    const { collectionId, packId, accountId, pageId } = useParams();
    const getTableViewParam = Boolean(new URLSearchParams(window.location.search).get('all'));
    const location = useLocation();
    const dispatch = useDispatch()
    const [openQr, setOpenQr] = useState(false);
    const [openChangeQr, setOpenChangeQr] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [isCancelSell, setIsCancelSell] = useState(false);
    const [token, setToken] = useState(null);
    const [requestParams, isResetList, setIsResetList, setCount, setCurrentPage] =
        useOutletContext();
    useGetTableView(location.pathname, getTableViewParam, setCurrentPage, 'tokens');
    useGenerateRoutes(
        [
            { title: 'page', id: pageId },
            { title: 'account', id: accountId },
            { title: 'collection', id: collectionId },
            { title: 'pack', id: packId },
        ],
        dispatch,
        setParentsList,
    );
    const params = useMemo(() => {
        return expandListOfParameters(requestParams, [
            { title: 'pack_id', value: packId },
            { title: 'collection_id', value: collectionId },
            { title: 'page_id', value: pageId },
        ]);
    }, [accountId, pageId, collectionId, pageId, requestParams]);
    const items = useDynamicPagination(TABLE_URL, params,true, isResetList, setIsResetList, 'handler_service');
    useEffect(() => {
        if (items) {
            setCount(items.count);
        }
    }, [items]);

    const handleCloseSell = () => {
        setIsCancelSell(false);
        setToken(null);
    };
    const handleSetStatusWork = (el) => {
        if (el && el.short_url) {
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/factory/shorts/${el.short_url.id}/in_work/`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                },
            )
                .then((res) => {
                    if (Number(res.status) === 200) {
                        return res.json();
                    }

                    if (Number(res.status) >= 400) {
                        return new Error('error');
                    }
                })
                .then((result) => {});
        }
    };
    const handleDownloadQr = (el) => {
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/factory/shorts/download_qr_code_for_token/${el.id}/`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json',
                },
            },
        )
            .then((response) => {
                if (Number(response.status) === 200) {
                    return response.blob();
                }
            })
            .then((blob) => {
                if (blob) {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = `${el.name}.png`;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                }
            });
    };
    const handleSetAppliedStatus = (el) => {
        if (el && el.short_url) {
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/factory/shorts/${el.short_url.id}/applied/`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                },
            )
                .then((res) => {
                    if (Number(res.status) === 200) {
                        return res.json();
                    }

                    if (Number(res.status) >= 400) {
                        return new Error('error');
                    }
                })
                .then((result) => {});
        }
    };
    const handleChangeQr = (el) => {
        setOpenChangeQr((prev) => !prev);
        setCurrentItem(el);
    };

    return (
        <>
            {openChangeQr && (
                <ChangeLinkQr element={currentItem} setHandleClose={() => setOpenChangeQr(false)} />
            )}

            {isCancelSell && (
                <ApproveCancelModal
                    element={token}
                    type={'sell'}
                    handleCloseModal={() => handleCloseSell()}
                />
            )}

            <TableRow names differentColumn>
                <TableCell title largeColumn>
                    Статус
                </TableCell>

                <TableCell title largeColumn>
                    Токен
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                    Свойства
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                    Описание
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD700}>
                    адрес QR кода
                </TableCell>
                <TableCell title textCenter breakpoint={BREAKPOINTS.MD700}>
                    QR
                </TableCell>
                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1400}>
                    Дата
                </TableCell>
            </TableRow>

            {items &&
                items.items &&
                items.items.map((el, i) => {
                    return (
                        <TableRow
                            differentColumn
                            actionType="factoryTokens"
                            key={i}
                            handleChangeQr={() => handleChangeQr(el)}
                            handleSetAppliedStatus={() => handleSetAppliedStatus(el)}
                            handleDownloadQr={() => handleDownloadQr(el)}
                            handleSetStatusWork={() => handleSetStatusWork(el)}
                        >
                            <TableCell largeColumn>
                                <p className="whithdraw__index">{i + 1}</p>

                                <div
                                    style={{
                                        width: '22px',
                                        height: '22px',
                                        borderRadius: '999px',
                                        background: colorVariants[el.status_store],
                                    }}
                                />

                                <div className="stats__item--wrapper withdraw__item">
                                    <p
                                        style={{
                                            color: el.status_store === 'transfer' && '#4bef4b',
                                        }}
                                        className={`withdraw__item--collection--name ${
                                            el.paid
                                                ? 'stats__item--text--blue'
                                                : 'stats__item--text--white'
                                        }`}
                                    >
                                        {variants[el.status_store]}
                                    </p>
                                </div>
                            </TableCell>

                            <TableCell largeColumn>
                                <div className="stats__item--avatar--inner withdraw__item">
                                    <img
                                        src={`${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${el.file_1}`}
                                        alt={'img'}
                                        style={{
                                            objectFit: 'cover',
                                            width: '48px',
                                            height: '48px',
                                        }}
                                    />
                                </div>

                                <div className="stats__item--wrapper withdraw__item">
                                    {/* <p
                                        // to="../collections"
                                        className="withdraw__item--collection--name"
                                    >
                                        {el && el.collection && el.collection.name}
                                        <br />
                                        <b>{el.name}</b>
                                    </p> */}

                                    <Link
                                        to={`/token/${el.id}`}
                                        className="withdraw__item--collection--name"
                                    >
                                        {el && el.collection && el.collection.name}
                                        <br />
                                        <b>{el.name}</b>
                                    </Link>
                                </div>
                            </TableCell>

                            <TableCell text textCenter breakpoint={BREAKPOINTS.MD620}>
                                {Math.floor(el.price / 100).toLocaleString('ru-RU')} RUB
                            </TableCell>

                            <TableCell text scroll breakpoint={BREAKPOINTS.MD1200}>
                                {el.description}
                            </TableCell>

                            <TableCell
                                text
                                scroll
                                breakpoint={BREAKPOINTS.MD1200}
                                onClick={() => {
                                    window.location.href = el.short_url.full_url;
                                }}
                            >
                                {el.short_url && el.short_url.url_hash}
                            </TableCell>

                            <TableCell linkWithText textCenter breakpoint={BREAKPOINTS.MD700}>
                                <QrCode onClick={() => setOpenQr(true)} />
                                {/* {
                                        openQr && el.short_url && (
                                            <div>
                                                <img src={el.short_url.full_url}/>
                                            </div>
                                        )
                                    } */}
                            </TableCell>

                            <TableCell text textCenter breakpoint={BREAKPOINTS.MD1400}>
                                {moment(el.created_at).utc().calendar('l')}
                            </TableCell>
                        </TableRow>
                    );
                })}
        </>
    );
};

export default Tokens;

// const handleTransferToken = (el) => {
//     setCurrentElement(el);
//     setIsTokenTransfer(true);
// };

// const handleCancelTransfer = (elem) => {
//     setToken(elem);
//     setIsCancelTransfer(true);
// };

// const handleNavigateEdit = (el) => {
//     if (el && el.page && el.collection && el.pack && el.account) {
//         navigate(
//             `/admin/edit/token/${el.id}/${el.page}/${el.collection.id}/${el.pack.id}/${el.account.id}`,
//         );
//     }
// };                            // handlerDeleteClick={() => handleDeleteToken(el.id)}
// handleTransferToken={() => handleTransferToken(el)}
// handleCancelTransfer={() => {
//     handleCancelTransfer(el);
// }}
// handlerEditClick={() => {
//     handleNavigateEdit(el);
// }}

// const handleDeleteToken = (id) => {
//     setClickedItemId(id);
//     setIsDelete(true);
// };

// const handleDeleteTokenRequest = () => {
//     if (clickedItemId) {
//         deleteToken({
//             id: clickedItemId,
//         })
//             .unwrap()
//             .finally(() => {
//                 setIsDelete(false);
//             });
//     }
// };
// {el.status_store && variants[el.status_store]}

{
    /* <CancelModal
                handleOk={() => {
                    handleDeleteTokenRequest();
                }}
                setActive={setIsDelete}
                active={isDelete}
                text={'Подтверждение на удаление'}
                okBtnText={'Удалить'}
                rejectBtnText={'Отмена'}
            /> */
}
// const nameProvider = useCallback(
//     (status, tokenId) => {

//         const foundTokenTransfer = transferList.find((id) => id === tokenId);
//         if (variants[status] && foundTokenTransfer && variants[status] !== 'transfer') {
//             return (
//                 <span>
//                     <CircularProgress size={'small'} sx={{ width: '25px', height: '25px' }} />{' '}
//                     Передача...
//                 </span>
//             );
//         }

//         if (variants[status] && !foundTokenTransfer) {
//             return variants[status];
//         }

//         return status;
//     },
//     [transferList],
// );
// const dispatch = useDispatch();
// const [currentElement, setCurrentElement] = useState(null);
// const [selectedField, setSelectedField] = useState([
//     {
//         type: 'email',
//         state: false,
//     },
//     {
//         type: 'phone',
//         state: false,
//     },
//     {
//         type: 'public_adress',
//         state: false,
//     },
// ]);
// const myRole = useSelector((state) => state.auth.userRole);
// const [token, setToken] = useState(null);
// const [isClose, setIsClose] = useState(false);
// const [isCancelTransfer, setIsCancelTransfer] = useState(false);
// const navigate = useNavigate();
// const [isTokenTransfer, setIsTokenTransfer] = useState(false);
// const [isDelete, setIsDelete] = useState(false);
// const [clickedItemId, setClickedItemId] = useState();
// const [deleteToken] = useDeleteTokenMutation();
// const transferList = useSelector(
//     (state) => state.tokensStatisticSlice.tokenTransferList,
//     shallowEqual,
// );
// import { useDeleteTokenMutation } from '../../../redux/api/dataService';
// import { CancelModal } from '../../Сonclusions/modals/CancelModal';
// import { CircularProgress } from '@mui/material';
// const variants = {
//     sold: 'продано',
//     transfer: 'передается',
//     posted: 'размещено',
// };
