export const fetchRequest = (
    url,
    query,
    setItems,
    setCount,
    setPage,
    setFetching,
    type,
    isAuth
) => {
    const accessToken = localStorage.getItem('access_token');
    setFetching(true);
    fetch(`${url}${query}`, {
        headers: isAuth
            ? {
                  Authorization: `Bearer ${accessToken}`,
              }
            : {},
    })
        .then((res) => {
            if (!res.ok) throw new Error('Ошибка запросsа');
            return res.json();
        })
        .then((result) => {
            setItems((prevItems) => [...prevItems, ...result.results]);
            setCount(result.count);
            if (type === 'admin_service') {
                setPage(result.next || null);
            }

            setCount(result.count)

            if (
                type === 'handler_service' &&
                result &&
                result.results &&
                result.results.length !== 0
            ) {
                setPage((prev) => prev + 1);
            }
        })
        .catch((err) => {
            console.error(err);
        })
        .finally(() => {
            setFetching(false);
        });
};

export const scrollPage = (e, items, count, setFetching) => {

        const isPaginationRequest =
            e.target.documentElement.scrollHeight -
                (e.target.documentElement.scrollTop + window.innerHeight) <
                100 && items.length < count;
        console.log(isPaginationRequest)
        if (isPaginationRequest) {
            setFetching(true);
        }
}
