import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from '../../index.module.css';
import base from '../../../../styles/base.module.css';

import WhatsApp from '../../../../icons/WhatsApp';
import Telegram2 from '../../../../icons/Telegram2';
import ArrowLink from '../../../../icons/ArrowLink';
import { CollabModal } from '../CollabModal/CollabModal';
import { sendForm } from '../../lib/requests';
import { ModalNew2 } from '../../../../components/ModalNew2';
import SuccessApplicationModal from '../SuccessApplicationModal/SuccessApplicationModal';

export const CertificateApplicationForm = () => {
  // Состояние для значений полей
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [orderThx, setOrderThx] = useState(false)
  const [errors, setErrors] = useState({});

  // Функция для проверки валидации
  const validateForm = () => {
    const newErrors = {};
    const nameRegex = /^[A-Za-zА-Яа-я\s]+$/;
    const phoneRegex = /^\d+$/;

    if (!userName || !nameRegex.test(userName)) {
      newErrors.name = 'Введите корректное имя';
    }

    if (!userPhone || !phoneRegex.test(userPhone)) {
      newErrors.phone = 'Введите корректный телефон';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
      <section className={styles.mainform}>
          <div className={base.container}>
              <div className={styles.mainformInner}>
                  <h2 className={cn(base.title2, styles.mainformTitle)}>
                      Готовы начать продажу токенизированной продукции? <span>Заполните форму</span>{' '}
                      и покорите рынок!
                  </h2>

                  <div className={styles.mainformContent}>
                      <div className={styles.mainformForm}>
                          <div className={styles.mainformTop}>
                              <p className={styles.mainformTopTitle}>
                                  Оформите заявку на выпуск цифровых сертификатов
                              </p>

                              <p className={styles.mainformText}>
                                  Менеджер свяжется
                                  <br />с вами в течение 30 мин <span>(в рабочее время)</span>
                              </p>
                          </div>

                          <div className={styles.mainformInputs}>
                              <div className={styles.mainformInputInner}>
                                  <p className={styles.mainformInputName}>Имя</p>
                                  <input
                                      type="text"
                                      className={cn(styles.mainformInput, {
                                          [styles.error]: errors.name,
                                      })}
                                      placeholder="Введите имя"
                                      value={userName}
                                      onChange={(e) => setUserName(e.target.value)}
                                  />
                                  {errors.name && <p className={styles.errorText}>{errors.name}</p>}
                              </div>

                              <div className={styles.mainformInputInner}>
                                  <p className={styles.mainformInputName}>Телефон</p>
                                  <input
                                      type="text"
                                      className={cn(styles.mainformInput, {
                                          [styles.error]: errors.phone,
                                      })}
                                      placeholder="Введите телефон"
                                      value={userPhone}
                                      onChange={(e) => setUserPhone(e.target.value)}
                                  />
                                  {errors.phone && (
                                      <p className={styles.errorText}>{errors.phone}</p>
                                  )}
                              </div>
                          </div>

                          <div className={styles.mainformFormBottom}>
                              <button
                                  className={cn(base.orangeButton, styles.mainformButton)}
                                  onClick={() => {
                                      if (validateForm()) {
                                          sendForm(userName, userPhone);
                                          setUserName('');
                                          setUserPhone('');
                                          setOrderThx(true);
                                      }
                                  }}
                              >
                                  Отправить заявку
                              </button>

                              <p className={styles.mainformAgree}>
                                  Нажимая на кнопку «Отправить заявку», вы даёте своё согласие на
                                  обработку персональных данных в соответствии с{' '}
                                  <Link to="/privacy">Политикой конфиденциальности</Link>
                              </p>
                          </div>
                      </div>

                      <div className={styles.mainformSocials}>
                          <a
                              href="https://wa.me/79952810735"
                              className={cn(styles.mainformSocialLink, styles.whatsapp)}
                              target="_blank"
                          >
                              <WhatsApp />
                              WhatsApp
                              <ArrowLink />
                          </a>

                          <a
                              href="https://t.me/check_brand"
                              className={cn(styles.mainformSocialLink, styles.telegram)}
                              target="_blank"
                          >
                              <Telegram2 />
                              Telegram
                              <ArrowLink />
                          </a>
                      </div>
                  </div>
              </div>
          </div>
        {orderThx && <CollabModal orderThx={orderThx} setOrderThx={setOrderThx}/>}
      </section>
  );
};
