import React, { useEffect, useState } from 'react';
import Certificate from '../../icons/Certificate';
import ViewSmall from '../../icons/ViewSmall';
import ViewMedium from '../../icons/ViewMedium';
import ViewBig from '../../icons/ViewBig';
import { Tab, Tabs } from '../../common/Tabs';
import CertificatesItemMini from '../BrandsNew/components/CertificateItemMini';
import CertificatesItem from '../BrandsNew/components/CertificatesItem';
import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';
export const CollectionSertificates = ({ collectionId, type, tokenId }) => {
    const [tokens, setTokens] = useState([]);
    const [view, setView] = React.useState(type === 'published' ? 'normal' : 'big');
    const navigate = useNavigate()
    useEffect(() => {
        fetch(
            `${
                process.env.REACT_APP_BACKEND_URL
            }/admin_service/api/v1/token/?collection_id=${collectionId}&page_size=${
                type === 'paid' ? 10 : 6
            }`,
        )
            .then((res) => {
                return res.json();
            })
            .then((response) => {
                // console.log(response)
                if (response.results) {
                    setTokens(response.results);
                }
            });
    }, [collectionId]);

    // console.log(tokens,type)

    return type === 'published' ? (
        <div className={styles.tokenCollectionBlock}>
            <div className={styles.tokenCollectionTitleInner}>
                <p className={styles.tokenCollectionTitle}>
                    <Certificate />
                    Сертификаты коллекции
                </p>

                <Tabs className={styles.tokenCollectionView}>
                    <Tab left active={view === 'small'} onClick={() => setView('small')}>
                        <ViewSmall />
                    </Tab>

                    <Tab active={view === 'normal'} onClick={() => setView('normal')}>
                        <ViewMedium />
                    </Tab>

                    <Tab right active={view === 'big'} onClick={() => setView('big')}>
                        <ViewBig />
                    </Tab>
                </Tabs>
            </div>

            <div className={styles.tokenCollectionsMini}>
                {tokens
                    .filter((token) => token.id !== tokenId)
                    .map((token) => {
                        console.log(token.price_type)
                        return (
                            <CertificatesItemMini
                                id={token.id}
                                certificate={{
                                    id: token.id,
                                    name: token.name,
                                    price: token.price,
                                    file_1: token.file_1,
                                    investor_royalty: token.investor_royalty,
                                    purchased: token.paid,
                                    price_type: token.price_type,
                                    percent: token.pack.percent,
                                }}
                                view={view}
                            />
                        );
                    })}
            </div>

            <button
                className={styles.tokenCollectionsMiniButtonAll}
                onClick={() => navigate(`/collection/${collectionId}`)}
            >
                Смотреть все
            </button>
        </div>
    ) : (
        <div className={styles.tokenCollectionBlock}>
            <div className={styles.tokenCollectionTitleInner}>
                <p className={styles.tokenCollectionTitle}>
                    <Certificate />
                    Сертификаты коллекции
                </p>

                <Tabs className={styles.tokenCollectionView}>
                    <Tab left active={view === 'small'} onClick={() => setView('small')}>
                        <ViewSmall />
                    </Tab>

                    <Tab active={view === 'normal'} onClick={() => setView('normal')}>
                        <ViewMedium />
                    </Tab>

                    <Tab right active={view === 'big'} onClick={() => setView('big')}>
                        <ViewBig />
                    </Tab>
                </Tabs>
            </div>

            <div className={styles.tokensFullBlockScroll}>
                <div className={styles.tokensFullBlockInner}>
                    {tokens
                        .filter((token) => token.id !== tokenId)
                        .map((token) => {
                           
                            return (
                                <CertificatesItem
                                    id={token.id}
                                    certificate={{
                                        id: token.id,
                                        name: token.name,
                                        price: token.price,
                                        file_1: token.file_1,
                                        investor_royalty: token.investor_royalty,
                                        purchased: token.paid,
                                        price_type: token.price_type,
                                        percent: token.pack.percent,
                                    }}
                                    view={view}
                                />
                            );
                        })}
                </div>
            </div>

            <button
                className={styles.tokensSeeMore}
                onClick={() => navigate(`/collection/${collectionId}`)}
            >
                Смотреть все
            </button>
        </div>
    );
};
