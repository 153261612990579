import React from 'react'
import cn from 'classnames';
import styles from './index.module.css';
import base from '../../styles/base.module.css';
import Verify from '../../icons/Verify';
import { useDispatch,useSelector } from 'react-redux';
import { connectWallet } from '../../functions/auth';
import { setIsTrustActive } from '../../redux/slices/auth';
import { useNavigate } from 'react-router-dom';
export const SertificateActivation = () => {
    const walletAddress = useSelector((state) => state.auth.walletAddress);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleAuth = () => {
        if (!window.ethereum) {
            dispatch(setIsTrustActive(true));
        }
        connectWallet(dispatch).then((res) => {
            if (res === true && typeof res === 'boolean') {
                navigate(`/profileNew/${walletAddress}`);
            }
        });
    };

    return (
        <div className={styles.blockToken}>
            <div className={styles.blockTokenTop}>
                <p className={styles.blockTokenTitle}>
                    <Verify />
                    Активация сертификата
                </p>
            </div>

            <div className={styles.blockTokenContent}>
                <div className={styles.blockTokenActivation}>
                    <button
                        onClick={() => handleAuth()}
                        className={cn(base.orangeButton, styles.tokenActivationButton)}
                    >
                        Активировать сертификат
                    </button>

                    <p className={styles.tokenActivationInfo}>
                        Активация сертификата - это важный шаг, который позволит получить его в
                        собственность. После активации сертификат будет закреплён в Вашем профиле и
                        появится в личной коллекции на главной странице.
                    </p>

                    <div className={styles.tokenActivationSteps}>
                        <p>
                            <strong>Чтобы активировать сертификат, следуйте простым шагам:</strong>
                        </p>

                        <p>1. Нажмите кнопку «Активировать сертификат»</p>
                        <p>
                            2. Загрузите приложение для хранения токенов <span>MetaMask</span>
                        </p>
                        <p>3. Авторизуйтесь на платформе CheckBrand</p>
                    </div>

                    <p className={styles.tokenActivationText}>
                        После активации Вы сможете воспользоваться эксклюзивными предложениями от
                        наших партнеров. Если у Вас возникнут вопросы, мы всегда рады помочь!
                    </p>
                </div>
            </div>
        </div>
    );
};

