import React, { useState, useEffect, useCallback, useContext, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CenteredContainer from '../../common/CenteredContainer';
import { CustomSelect } from '../../common/CustomSelect';
import File from '../../common/File';
import Input from '../../common/Input';
import Loader from '../../common/Loader';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import { NotificationContext } from '../../context/NotificationContext';
import {
    useCreateCollectionMutation,
    useGetAccountsQuery,
    useGetBlockchainsQuery,
    useGetCollectionQuery,
    useGetCurrencyTokensQuery,
    useUpdateCollectionMutation,
} from '../../redux/api/dataService';
import { pagesSelectors } from '../../redux/slices/pages';
import { normilizeError } from '../../utils/http/normilizeError';
import { DIALOG_TYPES } from '../../components/WlModals/const';
import { uid } from 'uid';
import './index.css';
import { useCheckValidationErrors } from '../../hooks/useCheckValidationErrors';
import { isValidURL } from '../CreatePage';
import { useGetPagesQuery } from '../../redux/api/handleService';
import * as Lodash from 'lodash';
import { Checkbox } from '@mui/material';

const adminList = [
    {
        value: '1',
        name: 'Admin1',
    },
    {
        value: '2',
        name: 'Admin2',
    },
    {
        value: '3',
        name: 'Admin3',
    },
    {
        value: '4',
        name: 'Admin4',
    },
    {
        value: '5',
        name: 'Admin5',
    },
];

const blockchainList = [
    {
        value: '1',
        name: 'BNB Smart Chain',
        icon: '/assets/img/binance.svg',
    },
];

const paymentList = [
    {
        value: '1',
        name: 'ETH',
    },
];

const wlUpdateList = [
    {
        value: '1',
        name: 'Do not update',
    },
    {
        value: '2',
        name: 'Update every 24 hours',
    },
];

const SocialLinks = {
    YOURSITE: {
        imgLink: '/assets/img/Internet.svg',
        name: 'yourSite',
        placeholder: 'https://yoursite.com',
    },
    TG: {
        imgLink: '/assets/img/tg.svg',
        name: 'telegram',
        placeholder: 'https://t.me/profile',
    },
    INSTAGRAM: {
        imgLink: '/assets/img/inst.svg',
        name: 'instagram',
        placeholder: 'https://instagram.com/@account_name',
    },
    VK: {
        imgLink: '/assets/img/vk.svg',
        name: 'vk',
        placeholder: 'https://vk.com/profile',
    },
    YOUTUBE: {
        imgLink: '/assets/img/youtube.svg',
        name: 'youtube',
        placeholder: 'https://youtube.com/abcdef',
    },
};

const DisplayThemes = {
    PADDED: {
        img: '/assets/img/display1.png',
        name: 'Padded',
        value: 'padded',
        description: 'Recommended for assets with transparent background',
    },
    CONTAINED: {
        img: '/assets/img/display2.png',
        name: 'Contained',
        value: 'contained',
        description: 'Recommended for assets that are not a 1:1 ratio',
    },
    COVERED: {
        img: '/assets/img/display3.png',
        name: 'Covered',
        value: 'covered',
        description: 'Recommended for assets that can extend to the edge',
    },
};

const SOCIAL_LINKS_ARR = Object.values(SocialLinks);
const DISPLAIED_THEMES_ARR = Object.values(DisplayThemes);
const APPLICATION_FORMS_ARR = Object.values(DIALOG_TYPES);
const APPLICATION_FORMS_SELECT_OPTIONS = APPLICATION_FORMS_ARR.map((item) => ({
    value: item,
    name: item,
}));

const CreateCollection = (props) => {
    const { isEdit } = props;

    const { id, pageId } = useParams();
    const { errors, handlerErrors, handelClearErrors } = useCheckValidationErrors();

    const navigate = useNavigate();

    const pages = useSelector(pagesSelectors.selectAll);
    const auth = useSelector((state) => state.auth);

    const { data: blockchains, isLoading: isBlockchainsLoading } = useGetBlockchainsQuery();
    const { isLoading: isPagesLoading } = useGetPagesQuery();
    const { data: accounts, isLoading: isAccountsLoading } = useGetAccountsQuery({
        page: 1,
        pageSize: 1000,
    });

    const { data: collection, isLoading: isCollectionLoading } = useGetCollectionQuery(
        { id },
        {
            skip: !id || !isEdit,
        },
    );

    const [
        onUpdateCollectionRequest,
        {
            isLoading: isCollectionUpdatingProccessing,
            error: updateCollectionError,
            isSuccess: isCollectionUpdatingSuccessfully,
            reset: resetCollectionUpdate,
        },
    ] = useUpdateCollectionMutation();

    const [blockchainId, setBlockchainId] = useState('');

    const { data: currencyTokens } = useGetCurrencyTokensQuery(
        { blockchainId },
        { skip: !blockchainId },
    );

    const [
        onCreateCollectionRequest,
        {
            isLoading: isCollectionCreatingProccessing,
            isSuccess: isCollectionCreatedSuccessfully,
            error: collectionCreatingError,
            reset: resetCollectionCreationState,
        },
    ] = useCreateCollectionMutation();

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);
    const [offer, setOffer] = useState(null);
    const [logo, setLogo] = useState('');
    const [adminSmart, setAdminSmart] = useState(auth.walletAddress);
    const [featuredImage, setFeaturedImage] = useState('');
    const [banner, setBanner] = useState('');
    const [name, setName] = useState('');
    const [symbol, setSymbol] = useState('');
    const [description, setDescriprion] = useState('');
    const [checkbrandcom, setCheckbrandcom] = useState('');
    const [brandId, setBrandId] = useState('');
    const [accountId, setAccountId] = useState('');
    const [tokens, setTokens] = useState('');
    const [earn, setEarn] = useState('');
    const [social, setSocial] = useState({
        yourSite: '',
        telegram: '',
        instagram: '',
        vk: '',
        youtube: '',
    });
    const [admin, setAdmin] = useState('1');
    const [blockchain, setBlockchain] = useState('1');
    const isFirstRender = useRef(true);

    const [payment, setPayment] = useState([
        {
            state: false,
            title: 'rub',
        },
        {
            state: false,
            title: 'tether',
        },
        {
            state: false,
            title: 'bnb',
        },
        {
            state: false,
            title: 'cbc',
        },
    ]);
    const [pdfBinary, setPdfBinary] = useState('');
    const [wlUpdate, setWlUpdate] = useState('1');
    const [limitwl, setLimitwl] = useState('');
    const [limitnowl, setLimitnowl] = useState('');
    const [percentageFee, setPercentageFee] = useState('5');
    const [tokenId, setTokenId] = useState('');
    const [displayTheme, setDisplayTheme] = useState(DisplayThemes.COVERED.value);
    const [applicationForm, setApplicationForm] = useState(DIALOG_TYPES.PERSONS);
    const [isSendData, setIsSendData] = useState(false);
    const [isPdfLoad, setisPdfLoad] = useState(false);

    const availbleAccaunts = useMemo(() => {
        if (!brandId || !accounts || !accounts.results) {
            return [];
        }

        return (accounts || []).results.filter((a) => a.page === brandId);
    }, [accounts, brandId]);

    const changeBrandHandler = useCallback((id) => {
        setBrandId(id);
    }, []);

    const changeAccountHandler = useCallback((id) => {
        setAccountId(id);
    }, []);

    const onChangeSocialLinksHandler = useCallback((key, value) => {
        setSocial((p) => ({
            ...p,
            [key]: value,
        }));
    }, []);

    const setAdminHandler = (value) => {
        setAdmin(value);
    };

    const setApplicationFormHandler = useCallback((value) => {
        setApplicationForm(value);
    }, []);

    const setBlockchainHandler = (value) => {
        setBlockchain(value);
    };

    const setPaymentHandler = (value) => {
        setPayment(value);
    };

    const setwlUpdateHandler = (value) => {
        setWlUpdate(value);
    };

    const blockchainChangeHandler = useCallback((value) => {
        setBlockchainId(value);
    }, []);

    const tokenChangeHandler = useCallback((value) => {
        setTokenId(value);
    }, []);

    const changeThemeHandler = useCallback((theme) => {
        setDisplayTheme(theme);
    }, []);

    const handleClearFields = () => {
        setLogo('');
        setAdminSmart('');
        setFeaturedImage('');
        setBanner('');
        setName('');
        setDescriprion('');
        setCheckbrandcom('');
        setBrandId('');
        setAccountId('');
        setSocial({
            yourSite: '',
            telegram: '',
            instagram: '',
            vk: '',
            youtube: '',
        });
        setOffer(null);
        setisPdfLoad(false);
        setPercentageFee('');
        setBlockchainId('');
        setTokenId('');
        setDisplayTheme('');
        setApplicationForm('');

        setAdmin('1');
        setBlockchain('1');
        setPayment('1');
        setWlUpdate('1');
    };

    const onSubmitHandler = useCallback(() => {
        setIsSendData(true);
        let formData = new FormData();

        // if (
        //     (!logo && !isEdit) ||
        //     (!banner && !isEdit) ||
        //     (!featuredImage && !isEdit) ||
        //     !name ||
        //     !checkbrandcom ||
        //     !percentageFee ||
        //     !displayTheme ||
        //     !description ||
        //     !brandId ||
        //     !accountId ||
        //     !blockchainId ||
        //     !tokenId ||
        //     !applicationForm
        // ) {
        //     addNotification({
        //         type: NOTIFICATION_TYPES.ERROR,
        //         text: 'Fill all required fields',
        //     });
        //     console.log(
        //         logo,
        //         banner,
        //         featuredImage,
        //         name,
        //         checkbrandcom,
        //         percentageFee,
        //         displayTheme,
        //         description,
        //         brandId,
        //         accountId,
        //         blockchainId,
        //         tokenId,
        //         applicationForm,
        //     );
        //     return;
        // }

        formData.append('link_yoursite', social.yourSite);
        formData.append('link_telegram', social.telegram);
        formData.append('link_instagram', social.instagram);
        formData.append('link_vk', social.vk);
        formData.append('link_youtube', social.youtube);
        if (offer !== null) {
            formData.append('offer', offer);
        }

        formData.append('application_form', applicationForm);

        if (featuredImage && !isValidURL(featuredImage)) {
            formData.append('featured', featuredImage);
        }

        if (banner && !isValidURL(banner)) {
            formData.append('banner', banner);
        }

        if (logo && !isValidURL(logo)) {
            formData.append('logo', logo);
        }

        if (!isEdit && blockchainId) {
            formData.append('blockchain', blockchainId);
        }

        if (!isEdit && tokenId) {
            formData.append('payment_tokens', tokenId);
        }

        formData.append('name', name);

        if (isEdit && symbol.length === 0) {
            formData.append('url', collection.url);
        }
        if (isEdit && symbol.length !== 0) {
            formData.append('url', symbol);
        }
        if (!isEdit) {
            formData.append('url', symbol);
        }

        formData.append('percentage_fee', percentageFee);
        formData.append('display_theme', displayTheme);
        formData.append('description', description);
        formData.append('smart_contract_address', adminSmart);
        formData.append('page', brandId);
        formData.append('account', accountId);
        formData.append('category_opensea', '1');

        if (isEdit) {
            onUpdateCollectionRequest({ id, data: formData })
                .unwrap()
                .then((res) => {
                    handleClearFields();
                    if (pageId && res) {
                        localStorage.setItem('isDelay', 'true');
                        navigate(`/admin/statistics2/collections/${pageId}/${res.account}`);
                    }
                })
                .catch((err) => {
                    handlerErrors(err.data);
                })
                .finally(() => {
                    setIsSendData(false);
                });
        } else {
            onCreateCollectionRequest(formData)
                .unwrap()
                .then(() => {
                    handleClearFields();
                })
                .catch((err) => {
                    handlerErrors(err.data);
                })
                .finally(() => {
                    setIsSendData(false);
                });
        }
    }, [
        isEdit,
        id,
        social,
        logo,
        featuredImage,
        banner,
        name,
        checkbrandcom,
        percentageFee,
        displayTheme,
        description,
        adminSmart,
        brandId,
        accountId,
        blockchainId,
        tokenId,
        applicationForm,
        offer,
        isPdfLoad,
        symbol,
    ]);

    useEffect(() => {
        if (isCollectionCreatedSuccessfully) {
            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Collection successfuly created',
            });
        }
    }, [isCollectionCreatedSuccessfully]);

    useEffect(() => {
        if (collectionCreatingError) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(collectionCreatingError),
            });
        }
    }, [collectionCreatingError]);

    useEffect(() => {
        if (updateCollectionError) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(updateCollectionError),
            });
        }
    }, [updateCollectionError]);

    useEffect(() => {
        if (isCollectionUpdatingSuccessfully) {
            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Collection successfuly updated',
            });
        }
    }, [isCollectionUpdatingSuccessfully]);

    useEffect(() => {
        if (collection && isEdit) {
            setAdminSmart(collection.smart_contract_address);
            setName(collection.name);
            setCheckbrandcom(collection.url);
            setDescriprion(collection.description);
            setBrandId(collection.page);
            setAccountId(collection.account);
            setSocial({
                yourSite: collection.link_yoursite !== 'null' ? collection.link_yoursite : '',
                telegram: collection.link_telegram !== 'null' ? collection.link_telegram : '',
                instagram: collection.link_instagram !== 'null' ? collection.link_instagram : '',
                vk: collection.link_vk !== 'null' ? collection.link_vk : '',
                youtube: collection.link_youtube !== 'null' ? collection.link_youtube : '',
            });
            setPercentageFee(String(Number(collection.percentage_fee)));
            setBlockchainId(collection.blockchain.id);
            if (collection.payment_tokens && collection.payment_tokens.length > 0) {
                setTokenId(collection.payment_tokens[0].id);
            }
            setDisplayTheme(collection.display_theme);
            setApplicationForm(
                APPLICATION_FORMS_ARR.includes(collection.application_form)
                    ? collection.application_form
                    : DIALOG_TYPES.PERSONS,
            );
            collection.logo && setLogo(collection.logo);
            collection.banner && setBanner(collection.banner);
            collection.featured && setFeaturedImage(collection.featured);
        }
    }, [collection, isEdit]);

    useEffect(
        () => () => {
            resetCollectionCreationState();
            resetCollectionUpdate();
        },
        [],
    );

    useEffect(() => {
        if (blockchains && !blockchainId) {
            const defaultBlockChain = blockchains.find((b) => b.name === 'Ethereum');
            if (defaultBlockChain && defaultBlockChain.id) {
                setBlockchainId(defaultBlockChain.id);
            }
        }
    }, [blockchains, blockchainId]);

    // useEffect(() => {
    //     if (currencyTokens && !tokenId) {
    //         const defaultCurrencyToken = currencyTokens.find((t) => t.name === 'ETH');

    //         setTokenId();
    //     }
    // }, [currencyTokens, tokenId]);

    // setTokenId();    ID token
    if (
        isBlockchainsLoading ||
        isPagesLoading ||
        isAccountsLoading ||
        isCollectionLoading ||
        (!collection && isEdit)
    ) {
        return (
            <CenteredContainer>
                <Loader />
            </CenteredContainer>
        );
    }

    const handleSetName = (value) => {
        setName(value);
        setSymbol(Lodash.kebabCase(value));
    };

    const handleSetUrl = (value) => {
        setSymbol(value);
    };

    const handleExtractTextData = async (file) => {
        let textContent = '';
        for (let pageNum = 1; pageNum <= file.numPages; pageNum++) {
            const page = await file.getPage(pageNum);
            const textContentPage = await page.getTextContent();
            textContent += textContentPage.items.map((item) => item.str).join(' ') + '\n';
        }
        return textContent;
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setOffer(selectedFile);
            setPdfBinary(URL.createObjectURL(selectedFile));
        }
    };

    const handleDelitoffer = () => {
        setOffer(null);
    };

    const handleOpenFile = () => {
        if (pdfBinary.length > 0 && offer) {
            window.open(pdfBinary, '_blank');
        }

        if (isEdit && collection && collection.offer && !offer) {
            window.open(collection.offer, '_blank');
        }
    };

    const handleSetCurrency = (token) => {
        setTokenId((prev) => {
            return prev === token.id ? null : token.id;
        });
    };

    return (
        <div className="default__padding createpage">
            <div className="container">
                <div className="createpage__inner">
                    <div className="createpage__content--wrap">
                        <h2 className="title left">
                            {isEdit ? 'Обновить Коллекцию' : 'Создание Коллекции'}
                        </h2>

                        <div className="create__content">
                            <File
                                title="Логотип"
                                text="350 x 350 рекомендуемый размер"
                                type="logo"
                                id="createaccountLogo"
                                defaultValue={collection && collection.logo}
                                value={logo}
                                setValue={setLogo}
                                isError={
                                    (!isEdit && isSendData && !logo) || errors.includes('logo')
                                }
                            />

                            <File
                                title="Изображение на главной"
                                text="600 x 400 рекомендуемый размер"
                                defaultValue={collection && collection.featured}
                                type="img"
                                id="featuredImage"
                                value={featuredImage}
                                setValue={setFeaturedImage}
                                isError={
                                    (!isEdit && isSendData && !featuredImage) ||
                                    errors.includes('featured')
                                }
                            />

                            <File
                                title="Баннер"
                                text="1400 x 350 рекомендуемый размер"
                                id="createcollectionBanner"
                                defaultValue={collection && collection.banner}
                                value={banner}
                                setValue={setBanner}
                                isError={
                                    (!isEdit && isSendData && !banner) || errors.includes('banner')
                                }
                            />
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <Input
                                    title="Название коллекции"
                                    text="50"
                                    placeholder="Введите название коллекции"
                                    value={name}
                                    setValue={handleSetName}
                                    isError={(isSendData && !name) || errors.includes('name')}
                                />
                            </div>
                            <div className="admin_add--url">
                                URL
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            color: '#fff',
                                            background: '#e5e8eb05',
                                            borderTopLeftRadius: '4px',
                                            borderBottomLeftRadius: '4px',
                                            padding: '1.2rem 0rem',
                                            paddingLeft: '1rem',
                                            border: 0,
                                            outline: 0,
                                        }}
                                    >
                                        https://checkbrand.com/collection/
                                    </div>
                                    <input
                                        style={{
                                            color: '#fff',
                                            background: '#e5e8eb05',
                                            borderTopRightRadius: '4px',
                                            borderBottomRightRadius: '4px',
                                            padding: '1.2rem 0rem',
                                            border: 0,
                                            outline: 0,
                                            width: '100px',
                                        }}
                                        onChange={(e) => handleSetUrl(e.target.value)}
                                        placeholder={isEdit ? collection.url : ''}
                                        value={symbol}
                                    />
                                </div>
                            </div>

                            <Input
                                title="Описание"
                                text="1000"
                                placeholder="e.g. In these sneakers I won the basketball World Cup, the Universe Cup and all the Galaxy Cups of the world. Very pleasant memories."
                                textarea
                                value={description}
                                setValue={setDescriprion}
                                isError={
                                    (isSendData && !description) || errors.includes('description')
                                }
                            />

                            <div className="admin__add--item full">
                                <div className="admin__add--title--inner">
                                    <p className="admin__add--title">Страница</p>
                                </div>

                                <div className="create__item--select--prop">
                                    <CustomSelect
                                        optionsList={pages}
                                        value={brandId}
                                        placeholder="Выберите страницу"
                                        onChange={changeBrandHandler}
                                        isError={
                                            (isSendData && !brandId) || errors.includes('page')
                                        }
                                    />
                                </div>
                            </div>

                            <div className="admin__add--item full">
                                <div className="admin__add--title--inner">
                                    <p className="admin__add--title">Бренд</p>

                                    <p className="admin__add--text">
                                       Выберите название Бренда для добавления в Коллекцию
                                    </p>
                                </div>

                                <div
                                    className={`create__item--account--inner ${
                                        (isSendData && !brandId) || errors.includes('page')
                                            ? 'error_input'
                                            : ''
                                    }`}
                                >
                                    <div className="create__item--account--content">
                                        {brandId ? (
                                            <>
                                                {availbleAccaunts.length ? (
                                                    <>
                                                        {availbleAccaunts.map((acc, i) => {
                                                            return (
                                                                <div
                                                                    className={`create__item--account--item`}
                                                                    key={acc.id}
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        name="accounts"
                                                                        className="create__item--account--checkbox"
                                                                        checked={
                                                                            acc.id === accountId
                                                                        }
                                                                        id={acc.id}
                                                                        onChange={() =>
                                                                            changeAccountHandler(
                                                                                acc.id,
                                                                            )
                                                                        }
                                                                    />

                                                                    <label
                                                                        htmlFor={acc.id}
                                                                        className="create__item--account--item--label"
                                                                    >
                                                                        <span className="create__item--account--item--label--wrap">
                                                                            <p className="create__item--account--item--name">
                                                                                {acc.name}
                                                                            </p>
                                                                        </span>

                                                                        <span className="create__item--account--item--circle"></span>
                                                                    </label>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <span className="selectPageWarning">
                                                       В выбранная странице нет брендов
                                                    </span>
                                                )}
                                            </>
                                        ) : (
                                            <span className="selectPageWarning">
                                                Сначала выберите страницу
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="admin__add--item full">
                                <div className="admin__add--title--inner">
                                    <p className="admin__add--title">Whitelist</p>

                                    <p className="admin__add--text">
                                    Для бронирования и частной чеканки
                                    </p>
                                </div>

                                <CustomSelect
                                    optionsList={APPLICATION_FORMS_SELECT_OPTIONS}
                                    value={applicationForm}
                                    placeholder="Brands whitelist"
                                    onChange={setApplicationFormHandler}
                                    isError={
                                        (isSendData && !applicationForm) ||
                                        errors.includes('application_form')
                                    }
                                />
                            </div>
                            <div className="admin__add--item full">
                                <div className="admin__add--title--inner">
                                    <p className="admin__add--title">Ссылки
                                
                                    </p>
                                </div>

                                <div className="create__item--links">
                                    {SOCIAL_LINKS_ARR.map(({ imgLink, name, placeholder }) => (
                                        <div className="create__item--link" key={name}>
                                            <img
                                                src={imgLink}
                                                alt={name}
                                                className="create__item--link--icon"
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                            />
                                            <Input
                                                className="linkContainer"
                                                placeholder={placeholder}
                                                isLink
                                                value={social[name]}
                                                setValue={(value) =>
                                                    onChangeSocialLinksHandler(name, value)
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="admin__add--title--inner">
                                <p className="admin__add--title">Токен платежа</p>
                                <ul style={{ width: '100%' }}>
                                    {currencyTokens &&
                                        currencyTokens.map((token, index) => {
                                            return (
                                                <li
                                                    onClick={() => handleSetCurrency(token)}
                                                    style={{
                                                        color: 'white',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {token.name}
                                                    <Checkbox
                                                        sx={{ width: '20px' }}
                                                        checked={
                                                            token.id === tokenId ? true : false
                                                        }
                                                    />
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                            <div className="create__item">
                                <p className="create__item--title">Creator Earnings</p>

                                <p className="create__item--text">
                                    Collect a fee when a user re-sells on item you originally
                                    created. This is deducted from the final sale price and paid
                                    monthly to a payout address of your choosing.
                                </p>

                                <p className="create__item--text">Percentage fee</p>
                                <Input
                                    placeholder="e.g. 5"
                                    value={percentageFee}
                                    setValue={setPercentageFee}
                                    isError={
                                        (isSendData && !percentageFee) ||
                                        errors.includes('percentage_fee')
                                    }
                                />
                            </div>

                            <div className="create__item">
                                <input
                                    id="files"
                                    accept="application/pdf"
                                    onChange={(e) => handleFileChange(e)}
                                    style={{ visibility: 'hidden', position: 'absolute' }}
                                    // disabled={isUploadDisabled}
                                    type="file"
                                />

                                <section style={{ display: 'flex', gap: '10px' }}>
                                    <p className="create__item--title">Договор офёрты</p>

                                    <div style={{ position: 'relative' }}>
                                        <label
                                            for="files"
                                            style={{
                                                color: '#77C57F',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            загрузить
                                        </label>

                                        <input
                                            id="files"
                                            accept="application/pdf"
                                            onChange={(e) => handleFileChange(e)}
                                            style={{
                                                visibility: 'hidden',
                                                position: 'absolute',
                                            }}
                                            type="file"
                                        />
                                    </div>

                                    <button
                                        style={{ color: '#CC5554', textDecoration: 'underline' }}
                                        onClick={() => handleDelitoffer()}
                                    >
                                        Удалить
                                    </button>
                                    {isEdit ? (
                                        <button
                                            onClick={() => handleOpenFile()}
                                            style={{
                                                color: '#2081E2',
                                                textDecoration: 'underline',
                                                maxWidth: '200px',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {!offer &&
                                                collection &&
                                                collection.offer &&
                                                collection.offer}
                                            {offer && offer.name}
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => handleOpenFile()}
                                            style={{
                                                color: '#2081E2',
                                                textDecoration: 'underline',
                                                maxWidth: '200px',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {offer && offer.name ? offer.name : 'имя файла.pdf'}
                                        </button>
                                    )}
                                </section>
                            </div>
                        </div>

                        <div className="create__button--content">
                            {isCollectionCreatingProccessing || isCollectionUpdatingProccessing ? (
                                <button className="button create__button default__hover">
                                    Загрузка...
                                </button>
                            ) : (
                                <button
                                    className="button create__button default__hover"
                                    onClick={onSubmitHandler}
                                >
                                    {isEdit ? 'Сохранить изменения' : 'Создать Коллекцию'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CreateCollection);
