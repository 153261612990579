const access_token = localStorage.getItem('access_token');

const validateTokenList = (userProps, newUserProps) => {
    if (!userProps && !newUserProps) return [];
    if (!userProps) return newUserProps;
    if (!newUserProps) return userProps;

    const result = [...userProps];
    const existingTypes = new Set(userProps.map((prop) => prop.type));

    newUserProps.forEach((prop) => {
        const isPropExist = existingTypes.has(prop.type);

        if (!isPropExist) {
            result.push(prop);
        }
    });
    return result;
};

export const editToken = (
    id,
    token,
    tokenProps,
    tokenPrice,
    access_token,
    generator,
    setErrors,
) => {
    const props = validateTokenList(token.properties, tokenProps);

    fetch(`${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/token/${id}/`, {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            properties: props,
            price: Number(tokenPrice.toFixed(0)),
        }),
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`При изменении свойств токена произошла ошибка:${res.status}`);
            }
            setErrors((prev) => ({ ...prev, props: '' }));
            generator.next();
        })
        .catch((err) => {
            setErrors((prev) => ({ ...prev, props: err.message }));
        });
};

export const handleSetTokenProps = (token, setTokenProps, propValue, propName) => {
    const newTokenProp = {
        name: propValue,
        type: propName,
        id: Date.now(),
    };

    setTokenProps((prev) => {
        const updatedProps = prev.map((prop) =>
            prop.type === propName ? { ...prop, name: propValue } : prop,
        );
        const isElementExist = updatedProps.find((prop) => prop.type === propName);
        return isElementExist ? updatedProps : [...updatedProps, newTokenProp];
    });
};

export const fetchTokenData = (id, setToken, setRefetch) => {
    console.log('fetching')
    fetch(`${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/token/${id}/`)
        .then((res) => res.json())
        .then((res) => {
            setToken(res);
        })
        .catch((err) => {
            console.log(err);
        })
        // .finally(() => {
        //     setRefetch(false);
        // });
};

export const buyToken = (id, newUserEmail, setErrors, setFormalizationModal, setToken) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/payments`, {
        method: 'POST',
        headers: {
            // Authorization: `Bearer ${access_token}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            email: newUserEmail,
            tokens: [id],
            // price:price
        }),
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`При оформлении токена произошла ошибка:${res.status}`);
            }
            setErrors([]);
            return res.json();
        })
        .then((response) => {
            if (response && response.payment_url) {
                window.open(response.payment_url, '_blank');
                setErrors((prev) => ({ ...prev, token: '' }));
                fetchTokenData(id, setToken);
                setFormalizationModal(false);
            }
        })
        .catch((err) => {
            setErrors((prev) => ({ ...prev, token: err.message }));
        });
};
