import React, { useCallback, useEffect, useState,useMemo,useRef } from 'react';
import cn from 'classnames';

import styles from './index.module.css';
import base from '../../styles/base.module.css';

import { FadeDescription } from '../../components/FadeDescription';
import Download2 from '../../icons/Download2';
import { SocialBlock, SocialLink } from '../../components/SocialBlock';
import Telegram2 from '../../icons/Telegram2';
import Instagram2 from '../../icons/Instagram2';
import Web2 from '../../icons/Web2';
import Vk2 from '../../icons/Vk2';
import Cross from '../../icons/Cross';
import CertificatesItem from '../BrandsNew/components/CertificatesItem';
import Reload from '../../icons/Reload';
import Filter from '../../icons/Filter';
import { Tab, Tabs } from '../../common/Tabs';
import ViewSmall from '../../icons/ViewSmall';
import ViewMedium from '../../icons/ViewMedium';
import ViewBig from '../../icons/ViewBig';
import { CustomSelect } from '../../common/CustomSelect';
import { SORT_BY_OPTIONS_ARR } from '../Collection/lib/FiltersContext';
import { FilterItem, FilterItemList } from '../../components/FilterItemList';
import Search2 from '../../icons/Search2';
import { Video2 } from '../../icons/Video2';
import { Plus2 } from '../../icons/Plus2';
import { Minus } from '../../icons/Minus';
import { Search3 } from '../../icons/Search3';
import CollectionNewSkeletonPage from './CollectionNewSkeletonPage';
import { useParams } from 'react-router-dom';
import { useDynamicPagination } from '../../hooks/useDynamicPagination/useDynamicPagination';
import { ShortenNumber } from '../../utils/ShortenNumber';
import CollectionItemSkeleton from '../BrandsNew/components/CollectionItemSkeleton';
import { TokenPropertyFilter } from './TokenPropertyFilter';
import { setPropFilterValue } from './utils';
import { TimerLastUpdate } from './TimerlastUpdate/TimerLastUpdate';
import { setIsTrustActive } from '../../redux/slices/auth';
import { useOutsideClick } from '../../hooks/useOutsideClick/useOutsideClick';
const windowWidth = window.innerWidth;
const device = windowWidth <= 480 ? 'mobile' : windowWidth <= 768 ? 'tablet' : 'desktop';

const generatePaidState = (paid) => {
    if (paid.paid && !paid.posted) {
        return true;
    }
    if ((!paid.paid && !paid.posted) || (paid.paid && paid.posted)) {
        return null;
    }
    if (!paid.paid && paid.posted) {
        return false;
    }
};

const CollectionNew = () => {
    const { id } = useParams();
    const [collection, setCollection] = useState(null);
    const [resetTokensList, setResetTokensList] = useState(false);
    const [paid, setPaid] = useState({ paid: false, posted: false });
    const [searchValue, setSearchValue] = useState('');
    // const [count, setCount] = useState(0);
    const [sortBy, setSortBy] = useState('random_score');
    const [collectionPropertyFilters, setCollectionPropertyFilters] = useState([]);
    const [moreInfoOpen, setMoreInfoOpen] = React.useState(false);
    const [filterOpen, setFilterOpen] = React.useState(device === 'desktop' ? true : false);
    const [showSearchFilter, setShowSearchFilter] = React.useState(false);
    const [view, setView] = React.useState(device === 'desktop' ? 'big' : 'normal');
    const componentRef = React.useRef(null);
    const componentRef2 = React.useRef(null);
    const buttonRef = React.useRef(null);
    useEffect(() => {
        if (id) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/collection/${id}/`)
                .then((res) => {
                    return res.json();
                })
                .then((response) => {
                    setCollection(response);
                })
                .catch((err) => {});
        }
    }, []);


    const generatePropFilterRequestParams = useMemo(() => {
        const filteredNonEmptyFilters = collectionPropertyFilters.filter(
            (prop) => prop.value.length > 0,
        );
        if (filteredNonEmptyFilters.length) {
            return [
                ...filteredNonEmptyFilters.flatMap((property) => {
                    return [
                        {
                            title: 'properties_type',
                            value: property.title,
                        },
                        ...property.value.map((propValue) => {
                            return {
                                title: 'properties_value',
                                value: propValue,
                            };
                        }),
                    ];
                }),
            ];
            
        } else return [];
    }, [collectionPropertyFilters]);

    const { items, loading,count } = useDynamicPagination(
        `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/token_filter/`,
        [
            {
                title: 'collection_id',
                value: id,
            },
            {
                title: 'paid',
                value: generatePaidState(paid),
            },
            {
                title: 'sort_by',
                value: sortBy,
            },
            {
                title: 'q',
                value: searchValue,
            },

            ...generatePropFilterRequestParams,
        ],
        false,
        resetTokensList,
        setResetTokensList,
        'admin_service',

    );


    const handleSetPaidFilter = (type) => {
        setPaid((prev) => ({ ...prev, [type]: !paid[type] }));
        setResetTokensList(true);
    };

    const handleSortList = (value) => {
        setSortBy(value);
        setResetTokensList(true);
    };

    const handleSetPropFilterValue = useCallback((title, value) => {
        setPropFilterValue(title, value, setCollectionPropertyFilters);
        setResetTokensList(true)
    }, []);

    const handleOpenOffer = () => {
        if (collection && collection.offer) {
            window.open(collection.offer, '_blank');
        }
    };
    const inputRef = useRef()
    useOutsideClick(inputRef,() => setShowSearchFilter(false))
    return collection ? (
        <div className={base.marginWrapper}>
            <div className={styles.collectionCoverInner}>
                <div className={styles.collectionBg}>
                    <img src={`${collection.banner}`} alt="collection bg" />
                </div>

                <div className={base.container}>
                    <div className={styles.collectionImgInner}>
                        <img src={`${collection.logo}`} alt="collection img" />
                    </div>
                </div>
            </div>

            <section className={styles.collection}>
                <div className={base.container}>
                    <div className={styles.collectionInner}>
                        <div className={styles.collectionInfoInner}>
                            <div className={styles.collectionInfoWrapper}>
                                <h1 className={styles.collectionInfoName}>{collection.name}</h1>

                                <div className={styles.collectionInfoPoints}>
                                    <div className={styles.collectionInfoPoint}>
                                        <p className={styles.collectionInfoPointValue}>
                                            {ShortenNumber(collection.total_token_price)}
                                        </p>
                                        <p className={styles.collectionInfoPointName}>
                                            объем продаж, руб
                                        </p>
                                    </div>

                                    <div className={styles.collectionInfoPoint}>
                                        <p className={styles.collectionInfoPointValue}>
                                            {ShortenNumber(collection.floor_price_count)}
                                        </p>
                                        <p className={styles.collectionInfoPointName}>
                                            мин. цена, руб
                                        </p>
                                    </div>

                                    <div className={styles.collectionInfoPoint}>
                                        <p className={styles.collectionInfoPointValue}>
                                            {collection.items_count}
                                        </p>
                                        <p className={styles.collectionInfoPointName}>токенов</p>
                                    </div>

                                    <div className={styles.collectionInfoPoint}>
                                        <p className={styles.collectionInfoPointValue}>
                                            {collection.owners_count}
                                        </p>
                                        <p className={styles.collectionInfoPointName}>владельцев</p>
                                    </div>
                                </div>

                                <FadeDescription
                                    theme="white"
                                    description={collection.description}
                                />
                            </div>

                            <div className={styles.collectionInfoSocial}>
                                <SocialBlock className={styles.collectionInfoSocialContent}>
                                    {collection && collection.link_yoursite && (
                                        <SocialLink
                                            onClick={() => window.open(collection.link_yoursite)}
                                        >
                                            <Web2 />
                                        </SocialLink>
                                    )}

                                    {collection && collection.link_telegram && (
                                        <SocialLink
                                            onClick={() => window.open(collection.link_telegram)}
                                        >
                                            <Telegram2 />
                                        </SocialLink>
                                    )}

                                    {collection && collection.link_vk && (
                                        <SocialLink onClick={() => window.open(collection.link_vk)}>
                                            <Vk2 />
                                        </SocialLink>
                                    )}

                                    {collection && collection.link_instagram && (
                                        <SocialLink
                                            onClick={() => window.open(collection.link_instagram)}
                                        >
                                            <Instagram2 />
                                        </SocialLink>
                                    )}
                                    {collection && collection.link_youtube && (
                                        <SocialLink
                                            onClick={() => window.open(collection.link_youtube)}
                                        >
                                            <Video2 />
                                        </SocialLink>
                                    )}
                                </SocialBlock>

                                <button
                                    className={styles.collectionOfferButton}
                                    onClick={handleOpenOffer}
                                >
                                    <span className={styles.collectionOfferButtonText}>
                                        Договор оферты
                                    </span>

                                    <span className={styles.collectionOfferButtonIcon}>
                                        <Download2 />
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className={styles.collectionMoreInfoMobile}>
                            <button
                                className={cn(styles.collectionMoreInfoOpen, {
                                    [styles.active]: moreInfoOpen,
                                })}
                                onClick={() => setMoreInfoOpen((prev) => !prev)}
                            >
                                Дополнительная информация
                                <span>{moreInfoOpen ? <Minus /> : <Plus2 />}</span>
                            </button>

                            {moreInfoOpen && (
                                <div className={styles.collectionMoreInfoContent}>
                                    <div className={styles.collectionMoreInfoContentWrap}>
                                        <SocialBlock className={styles.collectionInfoSocialContent}>
                                            {collection && collection.link_yoursite && (
                                                <SocialLink
                                                    onClick={() =>
                                                        window.open(collection.link_yoursite)
                                                    }
                                                >
                                                    <Web2 />
                                                </SocialLink>
                                            )}

                                            {collection && collection.link_telegram && (
                                                <SocialLink
                                                    onClick={() =>
                                                        window.open(collection.link_telegram)
                                                    }
                                                >
                                                    <Telegram2 />
                                                </SocialLink>
                                            )}

                                            {collection && collection.link_vk && (
                                                <SocialLink
                                                    onClick={() => window.open(collection.link_vk)}
                                                >
                                                    <Vk2 />
                                                </SocialLink>
                                            )}

                                            {collection && collection.link_instagram && (
                                                <SocialLink
                                                    onClick={() =>
                                                        window.open(collection.link_instagram)
                                                    }
                                                >
                                                    <Instagram2 />
                                                </SocialLink>
                                            )}

                                            {collection && collection.link_youtube && (
                                                <SocialLink
                                                    onClick={() =>
                                                        window.open(collection.link_youtube)
                                                    }
                                                >
                                                    <Video2 />
                                                </SocialLink>
                                            )}
                                        </SocialBlock>

                                        <div
                                            className={cn(
                                                styles.collectionInfoPoints,
                                                styles.mobile,
                                            )}
                                        >
                                            <div className={styles.collectionInfoPoint}>
                                                <p className={styles.collectionInfoPointValue}>
                                                    {ShortenNumber(collection.profit)}
                                                </p>
                                                <p className={styles.collectionInfoPointName}>
                                                    объем продаж, руб
                                                </p>
                                            </div>

                                            <div className={styles.collectionInfoPoint}>
                                                <p className={styles.collectionInfoPointValue}>
                                                    {ShortenNumber(collection.floor_price_count)}
                                                </p>
                                                <p className={styles.collectionInfoPointName}>
                                                    мин. цена, руб
                                                </p>
                                            </div>

                                            <div className={styles.collectionInfoPoint}>
                                                <p className={styles.collectionInfoPointValue}>
                                                    {count}
                                                </p>
                                                <p className={styles.collectionInfoPointName}>
                                                    токенов
                                                </p>
                                            </div>

                                            <div className={styles.collectionInfoPoint}>
                                                <p className={styles.collectionInfoPointValue}>
                                                    {collection.owners_count}
                                                </p>
                                                <p className={styles.collectionInfoPointName}>
                                                    владельцев
                                                </p>
                                            </div>
                                        </div>

                                        <button
                                            className={styles.collectionOfferButton}
                                            onClick={handleOpenOffer}
                                        >
                                            <span className={styles.collectionOfferButtonText}>
                                                Договор оферты
                                            </span>

                                            <span className={styles.collectionOfferButtonIcon}>
                                                <Download2 />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={styles.collectionFilterInner}>
                            <button
                                className={styles.collectionFilterButton}
                                onClick={() => setFilterOpen((prev) => !prev)}
                            >
                                <Filter />

                                <span>Фильтр</span>
                            </button>

                            <button
                                className={styles.collectionFilterSearchButton}
                                onClick={() => setShowSearchFilter(true)}
                                ref={buttonRef}
                            >
                                <Search3 />
                                Поиск
                            </button>

                            <div
                                className={styles.collectionFilterInputWrapper}
                                // ref={componentRef2}
                                // ref={inputRef}
                            >
                                <div
                                    // ref={inputRef}
                                    className={cn(styles.collectionFilterInputInner, {
                                        [styles.searchFocus]: showSearchFilter,
                                    })}
                                >
                                    <input
                                        
                                        type="text"
                                        placeholder="Поиск по названию токена, характеристикам..."
                                        className={styles.collectionFilterInput}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        onFocus={() => setShowSearchFilter(true)}
                                    />

                                    <button
                                        className={styles.collectionFilterInputButton}
                                        onClick={() => setResetTokensList(true)}
                                    >
                                        Найти
                                    </button>
                                </div>
                            </div>

                            <div
                                className={cn(styles.collectionFilterInputInner, styles.mobile, {
                                    [styles.searchFilterMobile]: showSearchFilter,
                                })}
                                ref={componentRef}
                                
                            >
                                <input
                                ref={inputRef}
                                    type="text"
                                    placeholder="Поиск по названию токена, характеристикам..."
                                    className={styles.collectionFilterInput}
                                />

                                <button className={styles.collectionFilterInputButton}>
                                    Найти
                                </button>
                            </div>

                            <div className={styles.collectionFilterSort}>
                                <CustomSelect
                                    optionsList={SORT_BY_OPTIONS_ARR}
                                    value={sortBy}
                                    placeholder="Выбрать фильтр"
                                    onChange={handleSortList}
                                />
                            </div>

                            <Tabs className={styles.collectionFilterView}>
                                <Tab
                                    left
                                    active={view === 'small'}
                                    onClick={() => setView('small')}
                                >
                                    <ViewSmall />
                                </Tab>

                                <Tab active={view === 'normal'} onClick={() => setView('normal')}>
                                    <ViewMedium />
                                </Tab>

                                <Tab right active={view === 'big'} onClick={() => setView('big')}>
                                    <ViewBig />
                                </Tab>
                            </Tabs>
                        </div>

                        <div className={styles.collectionContent}>
                            <div
                                className={cn(styles.collectionFilter, {
                                    [styles.filterOpen]: filterOpen,
                                })}
                            >
                                <div
                                    className={styles.filterMobileElement}
                                    onClick={() => setFilterOpen((prev) => !prev)}
                                >
                                    <Filter />
                                    Фильтр
                                </div>

                                <FilterItemList title="Токены">
                                    <div className={styles.collectionFilterValueItems}>
                                        <FilterItem
                                            title="Проданы"
                                            onClick={() => handleSetPaidFilter('paid')}
                                            id={'paidTokens'}
                                        />
                                        <FilterItem
                                            title="В продаже"
                                            id={'paidTokens2'}
                                            onClick={() => handleSetPaidFilter('posted')}
                                        />
                                    </div>
                                </FilterItemList>
                                <TokenPropertyFilter
                                    collection={collection}
                                    handleSetFilter={handleSetPropFilterValue}
                                />
                            </div>

                            <div className={styles.collectionContentInner}>
                                <div className={styles.collectionContentBar}>
                                    <div className={styles.collectionContentUpdateBar}>
                                        <button
                                            className={styles.collectionContentUpdateButton}
                                            onClick={() => {
                                                setResetTokensList(true)
                                                
                                            }}
                                        >
                                            <Reload />
                                        </button>
                                         <TimerLastUpdate resetTokensList={resetTokensList}/>
                                        
                                    </div>

                                    <p className={styles.collectionContentCount}>{count} шт.</p>
                                </div>

                                {/* <div className={styles.collectionFilterItems}>
                                    <button className={styles.collectionFilterItem}>
                                        Имя фильтра
                                        <Cross />
                                    </button>

                                    <button className={styles.collectionFilterItem}>
                                        Имя фильтра 2
                                        <Cross />
                                    </button>

                                    <button className={styles.collectionFilterClear}>
                                        Очистить всё
                                    </button>
                                </div> */}

                                <div
                                    className={cn(styles.collectionContentItems, {
                                        [styles.smallCards]: view === 'small',
                                        [styles.normalCards]: view === 'normal',
                                    })}
                                >
                                    {items.map((token) => {
                               
                                        return (
                                            <CertificatesItem
                                                key={token.id}
                                                certificate={{
                                                    id: token.id,
                                                    name: token.name,
                                                    price: token.price,
                                                    file_1: token.file_1,
                                                    investor_royalty: token.investor_royalty,
                                                    purchased: token.paid,
                                                    price_type: token.price_type,
                                                    percent: token.pack.percent,
                                                }}
                                                view={view}
                                                filterOpen={filterOpen}
                                            />
                                        );
                                    })}

                                    {loading &&
                                        new Array(10).fill('colelction').map((_, index) => {
                                            return <CollectionItemSkeleton />;
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    ) : (
        <div className={base.marginWrapper}>
            <CollectionNewSkeletonPage />
        </div>
    );
};

export default CollectionNew;
