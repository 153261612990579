import React from 'react';
import cn from 'classnames';

import styles from './index.module.css';

const UserAvatar = ({ style = 'default', avatar, icon, className }) => {
    // style={owner.level === 'No Level' ? owner.noLevel : owner.level}

    return (
        <span
            className={cn(
                styles.ownersItemUserAvatar,
                style === 'No Level' ? styles.noLevel : styles[style],
                className,
                {
                    [styles.centerImage]: icon,
                },
            )}
        >
            <span
                style={
                    icon ? icon : { backgroundImage: `url(${avatar})`, backgroundSize: '99% 99%' }
                }
            ></span>
        </span>
    );
};

export default UserAvatar;
