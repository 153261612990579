import React, { useState, useMemo, useCallback, useContext, useRef, useEffect } from 'react';
import Input, { INPUT_TYPE } from '../Input';
import RoyaltyDestribution from '../RoyaltyDestribution';
import LevelsDialog from '../../components/LevelsDialog';
import PropertiesDialog from '../../components/PropertiesDialog';
import StatsDialog from '../../components/StatsDialog';
import { useDialog } from '../../hooks/useDialog';
import {
    useGetBlockchainsQuery,
    useGetCollectionsQuery,
    useGetCurrencyTokensQuery,
    useLazyGetCurrencyTokensQuery,
} from '../../redux/api/dataService';
import File from '../File';
import CenteredContainer from '../CenteredContainer';
import Loader from '../Loader';
import { CustomSelect } from '../CustomSelect';

import css from './TokenCommonFieldsForm.module.css';
import { sortStringsInAlfOrderCb } from '../../utils/sortStringsInAlfOrderCb';

const MAX_NUMERIC_INDICATOR_START = 1000;


const TokenCommonFieldsForm = (props) => {
    const {
        packId,
        tokenCommonName,
        setTokenCommonName,
        numbering,
        setNumbering,
        tokenPrice,
        setTokenPrice,
        tokenIdForPayment,
        setTokenIdForPayment,
        investorRoyalty,
        setInvestorRoyalty,
        creatorRoyalty,
        setCreatorRoyalty,
        isTokenNameEqualFileName,
        setIsTokenNameEqualFileName,
        isAuction,
        setIsAuction,
        isNoPrice,
        setIsNoPrice,
        properties,
        setProperties,
        description,
        setDescriprion,
        levels,
        setLevels,
        stats,
        setStats,
        creatorRoyaltyDestribution,
        setCreatorRoyaltyDestribution,
        incomeRoyaltyDestribution,
        setIncomeRoyaltyDestribution,
        collectionId,
        setCollectionId,
        collections,
        isCollectionsLoading,
        unlockable,
        setUnlockable,
        unlockableContent,
        setUnlockableContent,
        isTokenUploadStarted,
        onPackIdChangeHandler,
        withoutNumbering = false,
        name,
        setName,
        isCreateToken = false,
        packs,
        isSendData,
        errors,
        availablePaymentTokens,
        handelClearErrors,
        isEditToken,
        setPriceType,
        type,
        percent,
        setPercent
    } = props;
  
   
    const [hiddenContent, setHiddenContent] = React.useState(false);
    const [saveChanges, setSaveChanges] = React.useState(false);
    const [currency, setCurrency] = useState('1');
    const [currencyTokens, setCurrencyTokens] = useState();
    const [currencyList,setCurrencyList] = useState([])
 
    const currencyChangeHandler = useCallback((value) => {
        setCurrency(value);
    }, []);

    const hiddenContentHandler = useCallback(() => {
        setHiddenContent((prev) => !prev);
    }, []);

    const saveChangesHandler = useCallback(() => {
        setSaveChanges((prev) => !prev);
    }, []);

    const { data: blockchains, isLoading: isBlockchainsLoading } = useGetBlockchainsQuery();

    const [getCurrencyToken] = useLazyGetCurrencyTokensQuery();

    const propertiesDialog = useDialog();
    const levelsDialog = useDialog();
    const statsDialog = useDialog();

    const selectedCollection = useMemo(() => {
        if (!collectionId || !collections || !collections.results) {
            return null;
        }

        return collections.results.find((c) => c.id === collectionId);
    }, [collections, collectionId]);

    const selectedBlockchain = useMemo(() => {
        if (!selectedCollection || !blockchains) {
            return null;
        }

        return blockchains.find((b) => b.id === selectedCollection.blockchain.id);
    }, [selectedCollection, blockchains]);

    // const { data: availablePaymentTokens } = useGetCurrencyTokensQuery(
    //     { blockchainId: selectedBlockchain ? selectedBlockchain.id : '' },
    //     { skip: !selectedBlockchain || !selectedBlockchain.id, pollingInterval: 300 },
    // );

    const filteredByCollectionPacks = useMemo(() => {
        if (packs && packs.results && collectionId) {
            return packs.results.filter((p) => p.collection.id === collectionId);
        }

        return [];
    }, [packs, collectionId]);

    const onChangeTokenNameEqualToFileNameHandler = useCallback(() => {
        setIsTokenNameEqualFileName((p) => !p);
    }, []);

    const onSelectTokenNameForPayment = useCallback((value) => {
        setTokenIdForPayment(value);
    }, []);

    const onPackSelectHandler = useCallback(
        (value) => {
            handelClearErrors && handelClearErrors('pack');
            onPackIdChangeHandler(value);

            const pack = packs.results.find((p) => p.id === value);

            setCollectionId(pack.collection.id);
        },
        [packs],
    );

    const onPriceInputChange = useCallback((value) => {
        setTokenPrice(value);
    }, []);

    // CREATOR ROYALTY
    const saveCreatotRoyaltyWalletHandler = useCallback((creatorRoyaltyInput) => {
        setCreatorRoyaltyDestribution((p) => [...p, creatorRoyaltyInput]);
    }, []);

    const deleteCreatorDestributionItem = useCallback((id) => {
        setCreatorRoyaltyDestribution((p) => p.filter((el) => el.id !== id));
    }, []);

    // INCOME ROYALTY
    const saveIncomeRoyaltyWalletHandler = useCallback((incomeRoylaty) => {
        setIncomeRoyaltyDestribution((p) => [...p, incomeRoylaty]);
    }, []);

    const deleteIncomeDestributionItem = useCallback((id) => {
        setIncomeRoyaltyDestribution((p) => p.filter((el) => el.id !== id));
    }, []);

    const onCollectionIdChangeHandler = useCallback((value) => {
        handelClearErrors && handelClearErrors('collection');
        setCollectionId(value);
    }, []);

    const setPropertiesHandler = useCallback((propertiesA) => {
        setProperties(propertiesA);
    }, []);

    const setLevelsHandler = useCallback((levelsA) => {
        setLevels(levelsA);
    }, []);

    const setStatsHandler = useCallback((statsA) => {
        setStats(statsA);
    }, []);

    useEffect(() => {
        if (availablePaymentTokens && !tokenIdForPayment) {
            const defaultCurrencyToken = availablePaymentTokens.find((t) => t.name === 'ETH');
            setTokenIdForPayment(defaultCurrencyToken.id);
        }
    }, [availablePaymentTokens, tokenIdForPayment]);

    useEffect(() => {
        if (collectionId) {
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/collection/${collectionId}/`,
                {
                    authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            )
                .then((res) => {
                    if (res.ok) {
                        return res.json(); 
                    }
                })
                .then((response) => {
                    if (response) {
                        if (response.blockchain && response.blockchain.id) {
                            getCurrencyToken({ blockchainId: response.blockchain.id })
                                .unwrap()
                                .then((res) => {
                                    if (res && res.length > 0) {
                                        setCurrencyList(res);
                                        
                                    }
                                });
                        }
                    }
                });
        }
    }, [collectionId]);

    if (isBlockchainsLoading || isCollectionsLoading) {
        return (
            <div className={css.loadingContainer}>
                <CenteredContainer>
                    <Loader />
                </CenteredContainer>
            </div>
        );
    }

    return (
        <>
            <div className="admin__add--item full">
                {Boolean(collections && collections.results) && (
                    <>
                        <div className="admin__add--title--inner">
                            <p className="admin__add--title">Выбор Коллекции</p>
                        </div>

                        <CustomSelect
                            optionsList={collections.results
                                .map((c) => ({
                                    value: c.id,
                                    name: c.name,
                                }))
                                .sort((a, b) => sortStringsInAlfOrderCb(a, b, 'name'))}
                            disabled={isTokenUploadStarted}
                            value={collectionId}
                            placeholder="Имя Коллекции"
                            onChange={onCollectionIdChangeHandler}
                            isError={
                                (isSendData && !collectionId) ||
                                (errors && errors.includes('collection'))
                            }
                        />
                    </>
                )}
            </div>

            {!isEditToken ? (
                isCreateToken ? (
                    <div className="admin__add--item full">
                        <div className="admin__add--title--inner">
                            <p className="admin__add--title">Выбор Пака</p>
                        </div>

                        {Boolean(filteredByCollectionPacks) && (
                            <CustomSelect
                                optionsList={filteredByCollectionPacks
                                    .map((c) => ({
                                        value: c.id,
                                        name: c.name,
                                    }))
                                    .sort((a, b) => sortStringsInAlfOrderCb(a, b, 'name'))}
                                disabled={isTokenUploadStarted || !collectionId}
                                value={packId}
                                placeholder={!collectionId ? 'Сначала выберите коллекцию' : 'Пак'}
                                onChange={onPackSelectHandler}
                                isError={
                                    (isSendData && !packId) || (errors && errors.includes('pack'))
                                }
                            />
                        )}
                    </div>
                ) : (
                    <>
                        <Input
                            title="Название Пака"
                            placeholder="Введите название Пака"
                            text="50"
                            value={name}
                            setValue={(value) => {
                                setName(value);
                                handelClearErrors && handelClearErrors('name');
                            }}
                            isError={(isSendData && !name) || (errors && errors.includes('name'))}
                        />
                    </>
                )
            ) : (
                ''
            )}

            {!isEditToken && (
                <div className="create__item--checkbox--inner">
                    <div className="create__item--checkbox--wrapper">
                        <div className="create__item--checkbox--text">
                            <p className="create__item--checkbox--text--title">
                                {isTokenNameEqualFileName
                                    ? 'Имя токена = «имя файла»'
                                    : 'Общее название токена'}
                            </p>
                            <p className="create__item--checkbox--text--text">
                                {isTokenNameEqualFileName
                                    ? 'Имя токена копирует имя файла токена.'
                                    : 'Общее имя будет использоваться для всех токенов.'}
                            </p>
                        </div>
                    </div>

                    <div className="create__item--checkbox--box">
                        <input
                            type="checkbox"
                            className="create__item--checkbox"
                            id="tokenNameEqualToFileName"
                            onChange={onChangeTokenNameEqualToFileNameHandler}
                            checked={isTokenNameEqualFileName}
                        />

                        <label
                            htmlFor="tokenNameEqualToFileName"
                            className="create__item--checkbox--label"
                        />
                    </div>
                </div>
            )}

            {!isTokenNameEqualFileName && (
                <>
                    <Input
                        title="Имя Токена"
                        text="37"
                        placeholder="Unique card #"
                        disabled={isTokenUploadStarted}
                        value={tokenCommonName}
                        setValue={(value) => {
                            setTokenCommonName(value);
                            handelClearErrors && handelClearErrors('name');
                        }}
                        isError={errors && errors.includes('name')}
                    />
                    {!isEditToken && (
                        <Input
                            title="Нумерация начнётся с"
                            placeholder="1"
                            type={INPUT_TYPE.NUMERIC}
                            maxValue={MAX_NUMERIC_INDICATOR_START}
                            disabled={isTokenUploadStarted}
                            value={numbering}
                            setValue={setNumbering}
                        />
                    )}
                </>
            )}

            <Input
                title={'Задать цену'}
                placeholder="Количество"
                typeItem="small"
                value={tokenPrice}
                setValue={(value) => {
                    onPriceInputChange(value);
                    handelClearErrors && handelClearErrors('price');
                }}
                isError={(isSendData && !tokenPrice) || (errors && errors.includes('price'))}
            />

            <div className="currency__inner">
                <CustomSelect
                    optionsList={
                        type === 'token'
                            ? [...currencyList, { name: 'В процентах %', id: 'percent' }]
                            : currencyList
                    }
                    value={currency}
                    placeholder={'Currency'}
                    onChange={(e) => {
                        if (type === 'token') {
                            setPriceType(e);
                        }
                        setTokenIdForPayment(e);
                        currencyChangeHandler(e);
                    }}
                />
            </div>
            {type === 'pack' && (
                <Input
                    title={'Задать процент'}
                    value={percent}
                    typeItem="small"
                    
                    setValue={(value) => {
                        setPercent(value);
                    }}
                />
            )}
            <div></div>
            <div className="checkbox__inner">
                <input type="checkbox" className="checkbox" id="showPrice" />

                <label htmlFor="showPrice" className="checkbox__label">
                  Не показывать цену
                </label>
            </div>

            <Input
                title="Investor fee (%)"
                placeholder="4.5"
                typeItem="half"
                disabled={isTokenUploadStarted}
                value={investorRoyalty}
                setValue={(value) => {
                    setInvestorRoyalty(value);
                    handelClearErrors && handelClearErrors('investor_royalty');
                }}
                isError={
                    (isSendData && !investorRoyalty) ||
                    (errors && errors.includes('investor_royalty')) ||
                    (selectedCollection &&
                        Number(selectedCollection.percentage_fee) !==
                            Number(investorRoyalty) + Number(creatorRoyalty) &&
                        isSendData)
                }
            />

            <Input
                title="Creator fee (%)"
                placeholder="0.5"
                typeItem="half"
                disabled={isTokenUploadStarted}
                value={creatorRoyalty}
                setValue={(value) => {
                    setCreatorRoyalty(value);
                    handelClearErrors && handelClearErrors('creator_royalty');
                }}
                isError={
                    (isSendData && !creatorRoyalty) ||
                    (errors && errors.includes('creator_royalty')) ||
                    (selectedCollection &&
                        Number(selectedCollection.percentage_fee) !==
                            Number(investorRoyalty) + Number(creatorRoyalty) &&
                        isSendData)
                }
            />

            <div className="admin__add--item full">
                <div className="admin__add--title--inner">
                    <p className="admin__add--title">Распределение создателей</p>
                </div>

                <RoyaltyDestribution
                    royaltyDestribution={creatorRoyaltyDestribution}
                    deleteDestributionItem={deleteCreatorDestributionItem}
                    saveRoyaltyWalletHandler={saveCreatotRoyaltyWalletHandler}
                    isSendData={isSendData}
                />
            </div>

            <div className="admin__add--item full">
                <div className="admin__add--title--inner">
                    <p className="admin__add--title">Распределение доходов</p>
                </div>

                <RoyaltyDestribution
                    royaltyDestribution={incomeRoyaltyDestribution}
                    deleteDestributionItem={deleteIncomeDestributionItem}
                    saveRoyaltyWalletHandler={saveIncomeRoyaltyWalletHandler}
                    isSendData={isSendData}
                />
            </div>

            <Input
                title="Описание"
                text="1000"
                placeholder="e.g. In these sneakers I won the basketball World Cup, the Universe Cup and all the Galaxy Cups of the world. Very pleasant memories."
                textarea
                value={description}
                disabled={isTokenUploadStarted}
                setValue={(value) => {
                    setDescriprion(value);
                    handelClearErrors && handelClearErrors('description');
                }}
                isError={(isSendData && !description) || (errors && errors.includes('description'))}
            />

            <div className="create__checkbox--items">
                <div className="create__item--checkbox--inner">
                    <div className="create__item--checkbox--wrapper">
                        <img
                            src="/assets/img/prop2.svg"
                            alt="prop"
                            className="create__item--checkbox--wrapper--icon"
                        />

                        <div className="create__item--checkbox--text">
                            <p className="create__item--checkbox--text--title">Свойства</p>

                            <p className="create__item--checkbox--text--text">
                            Текстовые черты, которые проявляются в виде прямоугольников
                            </p>
                        </div>
                    </div>

                    <button
                        className="button create__item--add"
                        onClick={propertiesDialog.onShow}
                        disabled={isTokenUploadStarted}
                    >
                        <img
                            src="/assets/img/plus.png"
                            alt="add"
                            className="create__item--add--icon"
                        />
                    </button>
                </div>

                <div className="create__item--checkbox--inner">
                    <div className="create__item--checkbox--wrapper">
                        <img
                            src="/assets/img/dontsea.svg"
                            alt="warn"
                            className="create__item--checkbox--wrapper--icon"
                        />

                        <div className="create__item--checkbox--text">
                            <p className="create__item--checkbox--text--title">Скрытый контент</p>

                            <p className="create__item--checkbox--text--text">
                            Загрузите обложку, которая будет отображаться на всех токенах в паке.
                            </p>
                        </div>
                    </div>

                    <div className="create__item--checkbox--box">
                        <input
                            type="checkbox"
                            className="create__item--checkbox"
                            id="hidden"
                            onChange={hiddenContentHandler}
                            checked={hiddenContent}
                        />

                        <label htmlFor="hidden" className="create__item--checkbox--label"></label>
                    </div>
                </div>

                <div className="create__item--checkbox--inner">
                    <div className="create__item--checkbox--wrapper">
                        <img
                            src="/assets/img/lock.svg"
                            alt="lock"
                            className="create__item--checkbox--wrapper--icon"
                        />

                        <div className="create__item--checkbox--text">
                            <p className="create__item--checkbox--text--title">Сохранить от изменений</p>

                            <p className="create__item--checkbox--text--text">
                            Токен будет устойчив к изменениям в настройках Пака.
                            </p>
                        </div>
                    </div>

                    <div className="create__item--checkbox--box">
                        <input
                            type="checkbox"
                            className="create__item--checkbox"
                            id="lock"
                            onChange={saveChangesHandler}
                            checked={saveChanges}
                        />

                        <label htmlFor="lock" className="create__item--checkbox--label"></label>
                    </div>
                </div>
            </div>

            {hiddenContent && (
                <File text="500 x 500 рекомендованный размер" type="logo_square" id="createpackLogo" />
            )}

            <PropertiesDialog
                open={propertiesDialog.visible}
                onClose={propertiesDialog.onClose}
                properties={properties}
                setPropertiesHandler={setPropertiesHandler}
            />
            <LevelsDialog
                open={levelsDialog.visible}
                onClose={levelsDialog.onClose}
                levels={levels}
                setLevelsHandler={setLevelsHandler}
            />
            <StatsDialog
                open={statsDialog.visible}
                onClose={statsDialog.onClose}
                stats={stats}
                setLevelsHandler={setStatsHandler}
            />
        </>
    );
};

export default React.memo(TokenCommonFieldsForm);
