export const setPropFilterValue = (title, value, setCollectionPropertyFilters) => {
    setCollectionPropertyFilters((prev) => {
        const updatedProps = prev.map((prop) => {
            if (prop.title === title) {
                const isPropExist = prop.value.includes(value);
                return {
                    ...prop,
                    value: isPropExist
                        ? prop.value.filter((property) => property !== value)
                        : [...prop.value, value],
                };
            } else return prop;
        });

        const propExists = prev.some((prop) => prop.title === title);

        if (!propExists) {
            return [...updatedProps, { title, value: [value] }];
        }

        return updatedProps
    });
};
