import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import styles from '../../index.module.css';
import base from '../../../../styles/base.module.css';
import CollectionItem from '../CollectionItem';
import NotContent from '../../../../icons/NotContent';
import { useDynamicPagination } from '../../../../hooks/useDynamicPagination/useDynamicPagination';
import { expandListOfParameters } from '../../../utils';
import CollectionItemSkeleton from '../CollectionItemSkeleton';
const url = `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/collection/`;
export const Collections = ({ setCount }) => {
    const [fullText, setFullText] = React.useState(false);
    const showText = (e) => {
        e.preventDefault();
        setFullText((prev) => !prev);
    };
    const { pathname, search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const cat = queryParams.get('cat');
    const [selectedCategory, setSelectedCategory] = React.useState(cat ? cat :'all');
    const containerRef = useRef(null);
    const [filter, setFilter] = React.useState(null);

    // const [isLoading, setIsLoadingCollection] = React.useState(true);
    
    const pages = useSelector((state) => state.pages.entities);
    console.log(pages)
    const [description, setDescription] = React.useState(
        `Цифровые сертификаты - это новый прорыв в мире современных технологий. 
        Они не только подтверждают подлинность и права владения, но и открывают доступ к 
        эксклюзивным товарам, услугам и мероприятиям. 
        
        Цифровые сертификаты могут служить в качестве членских билетов, 
        пропусков, а также участвовать в различных розыгрышах призов.   
        Коллекционирование таких сертификатов становится новым трендом, ведь собранную 
        коллекцию можно в дальнейшем продать заинтересованным коллекционерам по выгодной цене.`,
    );
    const [collections, setCollections] = useState(null);
    const accesToken = localStorage.getItem('access_token');
    const [resetList, setResetList] = useState(false);
    // const params = useMemo(() => {
    //     return expandListOfParameters(
    //         [],

    //     );
    // }, [filter, selectedCategory]);

    const { items } = useDynamicPagination(
        url,
        [
            {
                title: 'paid',
                value: filter,
            },
            {
                title: 'page_id',
                value: selectedCategory !== 'all' ? selectedCategory : '',
            },
        ],
        false,
        resetList,
        setResetList,
        'handler_service',
    );

    // useEffect(() => {
    //     const params = new URLSearchParams();

    //     if (filter !== null) {
    //         params.append('paid', filter);
    //     }

    //     if (selectedCategory !== 'all') {
    //         params.append('page_id', selectedCategory);
    //     }

    //     fetch(
    //         `${
    //             process.env.REACT_APP_BACKEND_URL
    //         }/handler_service/api/v1/collection/?${params.toString()}`,
    //         {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: `Bearer ${accesToken}`,
    //             },
    //         },
    //     )
    //         .then((res) => {
    //             return res.json();
    //         })
    //         .then((response) => {
    //             setCollections(response);
    //         })
    //         .catch((err) => {

    //         });
    // }, [selectedCategory, filter]);
    // const {
    //     state: {
    //         bookedTokensIds,
    //         collectionTokens,
    //         collectionTokensCount: count,
    //         sortBy: filterSortBy,
    //     },
    //     actions: { onNextPageCollectionsTokensHandler, handlePage, handleFilter },

    //     isTokensLoading,
    //     isFetchingLoading,
    // } = React.useContext(CollectionContext);

    // const { isWhitelistApplicationLoading } = React.useContext(WhiteListContext);

    // const containerRef = React.useRef(null);
    // const firstUndrowenItemIdx = React.useRef(-1);

    // const isDataLoading = React.useMemo(() => {
    //     return !collectionTokens || isWhitelistApplicationLoading || isTokensLoading;
    // }, [collectionTokens, isWhitelistApplicationLoading, isTokensLoading, isFetchingLoading]);

    // React.useEffect(() => {
    //     setIsLoadingCollection(isDataLoading);
    // }, [isDataLoading]);

    // React.useEffect(() => {
    //     setCount(count);
    // }, [count]);

    // const onPageScrollHandler = React.useCallback(() => {
    //     if (containerRef.current && firstUndrowenItemIdx.current > -1) {
    //         const elem = Array.from(containerRef.current.childNodes)[firstUndrowenItemIdx.current];

    //         if (!isDataLoading && elem && isElementIntoViewPort(elem)) {
    //             onNextPageCollectionsTokensHandler();
    //             firstUndrowenItemIdx.current = -1;
    //         }
    //     }
    // }, [isDataLoading]);

    // React.useEffect(() => {
    //     setSelectedCategory(cat ? cat : 'all');
    //     setResetList(true);
    // }, [cat]);

    React.useEffect(() => {
        if (selectedCategory !== null) {
            // handlePage(selectedCategory);

            setDescription(
                selectedCategory == 'all'
                    ? `
                    Цифровые сертификаты - это новый прорыв в мире современных технологий. 
                    Они не только подтверждают подлинность и права владения, но и открывают доступ к эксклюзивным товарам, услугам и мероприятиям. 
                    Цифровые сертификаты могут служить в качестве членских билетов, пропусков, а также участвовать в различных розыгрышах призов. 
                    
                    Коллекционирование таких сертификатов становится новым трендом, ведь собранную 
                    коллекцию можно в дальнейшем продать заинтересованным коллекционерам по выгодной цене.`
                    : pages[selectedCategory].description,
            );
        }
    }, [selectedCategory]);

    // React.useEffect(() => {
    //     handleFilter(filter);
    // }, [filter]);

    // React.useEffect(() => {
    //     window.addEventListener('scroll', onPageScrollHandler);

    //     return () => {
    //         window.removeEventListener('scroll', onPageScrollHandler);
    //     };
    // }, [isDataLoading]);

    return (
        <>
            <div className={styles.brandSubTabs}>
                {/* {isLoading ? (
                    <>
                        <Skeleton
                            containerClassName={cn(styles.brandSubTabSkeletonWrapper, styles.small)}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={styles.brandSubTabSkeletonWrapper}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={styles.brandSubTabSkeletonWrapper}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={styles.brandSubTabSkeletonWrapper}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={cn(styles.brandSubTabSkeletonWrapper, styles.small)}
                            className={styles.brandSubTabSkeleton}
                        />
                    </>
                ) : ( */}
                <>
                    <button
                        className={cn(styles.brandSubTab, {
                            [styles.active]: selectedCategory === 'all',
                        })}
                        onClick={() => {
                            setSelectedCategory('all');
                            setResetList(true);
                        }}
                    >
                        Все
                    </button>
                    {Object.values(pages).map((page) => (
                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: selectedCategory === page.id,
                            })}
                            onClick={() => {
                                setSelectedCategory(page.id);
                                setResetList(true);
                            }}
                        >
                            {page.name}
                        </button>
                    ))}
                </>
                {/* )} */}
            </div>

            {/* {isLoading ? (
                <div className={styles.brandsText}>
                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />
                </div>
            ) : ( */}
            <div className={styles.brandsText} style={{ whiteSpace: 'pre-line',margin:0,padding:0, }}>
                <p>
                    {description && description.length > 150 && !fullText
                        ? `${description.slice(0, 150)}...`
                        : description}
                </p>

                {description && description.length > 150 && (
                    <a href="#" className={styles.brandsTextShow} onClick={showText}>
                        {fullText ? 'Свернуть' : 'Развернуть'}
                    </a>
                )}
            </div>
            {/* )} */}

            <div className={styles.brandOuter}>
                <div className={styles.brandSubTabs}>
                    {/* {isLoading ? (
                        <>
                            <Skeleton
                                containerClassName={cn(
                                    styles.brandSubTabSkeletonWrapper,
                                    styles.small,
                                )}
                                className={styles.brandSubTabSkeleton}
                            />

                            <Skeleton
                                containerClassName={styles.brandSubTabSkeletonWrapper}
                                className={styles.brandSubTabSkeleton}
                            />

                            <Skeleton
                                containerClassName={styles.brandSubTabSkeletonWrapper}
                                className={styles.brandSubTabSkeleton}
                            />
                        </>
                    ) : ( */}
                    <>
                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: filter === null,
                            })}
                            onClick={() => {
                                setFilter(null);
                                setResetList(true);
                            }}
                        >
                            Все
                        </button>

                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: filter === true,
                            })}
                            onClick={() => {
                                setFilter(true);
                                setResetList(true);
                            }}
                        >
                            Продано
                        </button>

                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: filter === false,
                            })}
                            onClick={() => {
                                setFilter(false);
                                setResetList(true);
                            }}
                        >
                            В продаже
                        </button>
                    </>
                    {/* )} */}
                </div>

                {/* {isLoading ? (
                    <>
                        <Skeleton
                            containerClassName={styles.tokenValueSkeletonWrapper}
                            className={styles.tokenValueSkeleton}
                        />
                    </>
                ) : (
                    <p className={styles.tokenValue}>{count} шт.</p>
                )} */}
            </div>

            <div className={styles.brandsContent} ref={containerRef}>
                {/* {isLoading ? (
                    <>
                        {' '}
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />{' '}
                    </>
                ) : (
                    <> */}
                {items &&
                    items.length > 0 &&
                    items.map((collection) => (
                        <CollectionItem key={collection.id} collection={collection} />
                    ))}
                {items && items.length === 0 && (
                    <>
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                        <CollectionItemSkeleton />
                    </>
                )}
            </div>
        </>
    );
};

export const CollectionsContext = ({ setCount }) => {
    return <Collections setCount={setCount} />;
};
