import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import styles from '../../index.module.css';
import base from '../../../../styles/base.module.css';
import CertificatesItem from '../CertificatesItem';
import NotContent from '../../../../icons/NotContent';
import { useDynamicPagination } from '../../../../hooks/useDynamicPagination/useDynamicPagination';
import { expandListOfParameters } from '../../../utils';
import CertificatesItemSkeleton from '../CertificatesItemSkeleton';

const url = `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/token/`;

export const Certificates = ({ setCount }) => {
    const [fullText, setFullText] = React.useState(false);
    const showText = (e) => {
        e.preventDefault();
        setFullText((prev) => !prev);
    };
    const [selectedCategory, setSelectedCategory] = React.useState('all');
    const [filter, setFilter] = React.useState('');
    const [isLoading, setIsLoadingCollection] = React.useState(true);
    const pages = useSelector((state) => state.pages.entities);

    const [description, setDescription] = React.useState(
        `Цифровые сертификаты - это новый прорыв в мире современных технологий. 
       Они не только подтверждают подлинность и права владения, но и открывают доступ к эксклюзивным товарам, услугам и мероприятиям. 
       Цифровые сертификаты могут служить в качестве членских билетов, пропусков, а также участвовать в различных розыгрышах призов. 
                    
       Коллекционирование таких сертификатов становится новым трендом, ведь собранную 
       коллекцию можно в дальнейшем продать заинтересованным коллекционерам по выгодной цене.`,
    );

    const [currentPage, setCurrentPage] = useState('brands');
    const accesToken = localStorage.getItem('access_token');
    const [resetList, setResetList] = useState(false);

    const params = useMemo(() => {
        return expandListOfParameters(
            [],
            [
                {
                    title: 'paid',
                    value: filter,
                },
                {
                    title: 'page_id',
                    value: selectedCategory !== 'all' ? selectedCategory : '',
                },
            ],
            
        );
    }, [filter, selectedCategory]);
    const { items } = useDynamicPagination(url, params,false, resetList, setResetList, 'admin_service');
    const containerRef = React.useRef(null);
    const firstUndrowenItemIdx = React.useRef(-1);
    React.useEffect(() => {
        setDescription(
            selectedCategory == 'all'
                ? `Цифровые сертификаты - это новый прорыв в мире современных технологий.
                Они не только подтверждают подлинность и права владения, но и открывают доступ к эксклюзивным товарам, услугам и мероприятиям.
                Цифровые сертификаты могут служить в качестве членских билетов, пропусков, а также участвовать в различных розыгрышах призов.

                Коллекционирование таких сертификатов становится новым трендом, ведь собранную
                коллекцию можно в дальнейшем продать заинтересованным коллекционерам по выгодной цене.`
                : pages[selectedCategory].description,
        );
    }, [selectedCategory]);

    return (
        <>
            <div className={styles.brandSubTabs}>
                <>
                    <button
                        className={cn(styles.brandSubTab, {
                            [styles.active]: selectedCategory === 'all',
                        })}
                        onClick={() => {
                            setSelectedCategory('all');
                            setResetList(true);
                        }}
                    >
                        Все
                    </button>
                    {Object.values(pages).map((page) => (
                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: selectedCategory === page.id,
                            })}
                            onClick={() => {
                                setSelectedCategory(page.id);
                                setResetList(true);
                            }}
                        >
                            {page.name}
                        </button>
                    ))}
                </>
                {/* )} */}
            </div>
            <div className={styles.brandsText} style={{ whiteSpace: 'pre-line' }}>
                <p>
                    {description && description.length > 150 && !fullText
                        ? `${description.slice(0, 150)}...`
                        : description}
                </p>

                {description && description.length > 150 && (
                    <a
                        href="#"
                        className={styles.brandsTextShow}
                        onClick={showText}
                        style={{ whiteSpace: 'pre-line' }}
                    >
                        {fullText ? 'Свернуть' : 'Развернуть'}
                    </a>
                )}
            </div>

            <div className={styles.brandOuter}>
                <div className={styles.brandSubTabs}>
                    <>
                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: filter === null,
                            })}
                            onClick={() => {
                                setFilter(null);
                                setResetList(true);
                            }}
                        >
                            Все
                        </button>

                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: filter === true,
                            })}
                            onClick={() => {
                                setFilter(true);
                                setResetList(true);
                            }}
                        >
                            Продано
                        </button>

                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: filter === false,
                            })}
                            onClick={() => {
                                setFilter(false);
                                setResetList(true);
                            }}
                        >
                            В продаже
                        </button>
                    </>
                </div>
            </div>

            <div className={cn(styles.brandsContent, styles.certificateContent)} ref={containerRef}>
                {items &&
                    items.length > 0 &&
                    items.map((certificate) => (
                        <CertificatesItem
                            key={certificate.id}
                            certificate={{ ...certificate, percent: certificate.pack.percent }}
                        />
                    ))}
                {items && items.length === 0 && (
                    <>
                        <CertificatesItemSkeleton />
                        <CertificatesItemSkeleton />
                        <CertificatesItemSkeleton />
                        <CertificatesItemSkeleton />
                        <CertificatesItemSkeleton />
                        <CertificatesItemSkeleton />
                        <CertificatesItemSkeleton />
                    </>
                )}
            </div>
        </>
    );
};

export const CertificatesContext = ({ setCount }) => {
    return <Certificates />;
};
