import React, { memo } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';
import { TokenInfo } from '../../components/TokenInfo';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import { ShortenNumber } from '../../utils/ShortenNumber';

const OwnerItem = memo(({ owner }) => {
    const allTokensCount = owner.tokens.length - 4;
    const navigate = useNavigate();

    // http://backend.checkbrand.site/profile/media/
    return (
        <div
            className={styles.ownersItem}
            onClick={() => navigate(`/profileNew/${owner.public_address}`)}
        >
            <img src="/assets/img/brand-light.png" alt="light" className={styles.ownersItemLight} />

            <div className={styles.ownersItemWrapper}>
                {/* <Link to="#" className={styles.ownersItemUser}> */}
                <UserAvatar
                    avatar={
                        owner.image
                            ? `${process.env.REACT_APP_BACKEND_URL}/profile/media/${owner.image}`
                            : '/assets/img/avatar2.png'
                    }
                    style={owner.level === 'No Level' ? owner.noLevel : owner.level}
                />

                <span className={styles.ownersItemUserInfo}>
                    <span className={styles.ownersItemUserName}>
                        {owner.first_name ? owner.first_name : owner.username}
                    </span>

                    <span className={styles.ownersItemUserRole}>
                        {owner.first_name && owner.username}
                    </span>
                </span>
                {/* </Link> */}

                <div className={styles.ownersItemCollections}>
                    {owner.tokens &&
                        owner.tokens.slice(0, 4).map((token, index) => {
                            return (
                                <TokenInfo key={index} token={token}>
                                    <div className={styles.ownersItemCollection}>
                                        <img src={token.file_1} alt="brand" />
                                    </div>
                                </TokenInfo>
                            );
                        })}

                    <Link to="#" className={styles.ownersItemCollectionsMore}>
                        {owner.tokens.length > 4 && `еще ${allTokensCount}`}
                    </Link>
                </div>

                <p
                    className={cn(
                        styles.ownersItemPrice,
                        owner.level === 'No Level' ? styles.noLevel : styles[owner.level],
                    )}
                >
                    <span>{ShortenNumber(owner.token_sum)} руб.</span>
                </p>
            </div>
        </div>
    );
});

export default OwnerItem;

{
    /* <TokenInfo>
                        <div className={styles.ownersItemCollection}>
                            <img src="/assets/img/brand2.jpg" alt="brand" />
                        </div>
                    </TokenInfo>

                    <TokenInfo>
                        <div className={styles.ownersItemCollection}>
                            <img src="/assets/img/brand1.jpg" alt="brand" />
                        </div>
                    </TokenInfo>

                    <TokenInfo>
                        <div className={styles.ownersItemCollection}>
                            <img src="/assets/img/brand2.jpg" alt="brand" />
                        </div>
                    </TokenInfo> */
}
