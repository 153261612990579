import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchByID } from './utils/useGenerateRoutes.utils';

export const useGenerateRoutes = (idArray, dispatch, setParentsList) => {
    const firstRender = useRef(true);
    const generateParentsList = useCallback(() => {
        fetchByID(idArray,dispatch, setParentsList);
    }, [dispatch, setParentsList, idArray]);

    useEffect(() => {
        if (firstRender.current) {
            generateParentsList();
            firstRender.current = false;
        }
    }, [idArray]);
};
