import React from 'react';
import { FilterItemList, FilterItem } from '../../components/FilterItemList';

export const TokenPropertyFilter = ({ collection, handleSetFilter }) => {
    return (
        <div style={{ width: '100%', paddingTop: '8px' }}>
            {collection.properties_types.map((property, index) => {
                return (
                    <FilterItemList key={index} title={property.type}>
                        {property.values.map((value, id) => {
                            return (
                                <FilterItem
                                    key={id}
                                    title={value}
                                    id={property.type + value + id}
                                    onClick={() => handleSetFilter(property.type, value)}
                                />
                            );
                        })}
                    </FilterItemList>
                );
            })}
        </div>
    );
};
