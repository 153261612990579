import React from 'react';

const Diamond2 = ({ color = 'currentColor' }) => {
    return (
        <svg viewBox="0 0 21 15" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.91443 6.27505L7.7007 12.8507C7.79136 13.044 7.51937 13.2161 7.36135 13.0653L0.429611 6.03816C0.294048 5.90954 0.407161 5.6941 0.610936 5.7162L4.75641 6.16758C4.82376 6.16758 4.89112 6.21034 4.91443 6.27505ZM14.3139 4.42693L12.5248 0.129434C12.5024 0.043417 12.4117 0.000819403 12.321 0.000819403L8.62894 0C8.53827 0 8.47006 0.0425976 8.42516 0.128615L6.65853 4.42611C6.61363 4.55473 6.70342 4.70546 6.8623 4.70546L14.1101 4.68416C14.269 4.70628 14.3588 4.55556 14.3139 4.42693ZM6.74822 6.48968L10.2814 14.871C10.3496 15.043 10.5983 15.043 10.6665 14.871L14.2454 6.51191C14.2903 6.38329 14.2005 6.23257 14.0417 6.23257L6.907 6.21127C6.79389 6.21045 6.70332 6.36107 6.74822 6.48968ZM13.6567 13.0227L20.5427 6.06039C20.6783 5.93177 20.5652 5.69502 20.3614 5.73843L16.2842 6.18981C16.216 6.18981 16.1487 6.25452 16.1254 6.31842L13.3174 12.8072C13.2268 13.0014 13.4987 13.1727 13.6567 13.0227ZM20.8148 4.08277C20.9962 4.06147 21.0644 3.84602 20.9279 3.73871L17.0095 0.235823C16.8506 0.107208 16.6701 0.0211997 16.4431 0.0211997H14.4951C14.3595 0.0211997 14.2456 0.171933 14.2913 0.300541L16.0131 4.5129C16.058 4.59891 16.1486 4.64151 16.2393 4.64151L20.8148 4.08277ZM6.65759 0.300541C6.70249 0.171927 6.61269 0.0211997 6.45381 0.0211997H4.50586C4.30208 0.0211997 4.09829 0.0859158 3.93943 0.235823L0.0660629 3.78128C-0.0694993 3.8886 0.0211644 4.10324 0.179176 4.12534L4.73225 4.61933C4.82292 4.61933 4.91358 4.57673 4.95847 4.49071L6.65759 0.300541Z"
                fill={color}
            />
        </svg>
    );
};

export default Diamond2;
