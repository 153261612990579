import React, { useState, useMemo, useCallback, useContext } from 'react';
import { useEffect } from 'react';
import { NotificationContext } from '../../context/NotificationContext';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import {
    useCreatePackMutation,
    useGetCollectionsQuery,
    useGetFilteredTokensQuery,
    useGetPackQuery,
    useUpdatePackMutation,
} from '../../redux/api/dataService';
import { normilizeError } from '../../utils/http/normilizeError';
import CenteredContainer from '../../common/CenteredContainer';
import Loader from '../../common/Loader';

import './index.css';
import UploadManyTokensForm from '../../common/UploadManyTokensForm/UploadManyTokensForm';
import { TokenCommonFieldsForm } from '../../common/TokenCommonFieldsForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useCheckValidationErrors } from '../../hooks/useCheckValidationErrors';
import { useSelector } from 'react-redux';

const CreatePack = (props) => {
    const { isEdit } = props;

    const { id, collectionId: collId, accountId, pageId } = useParams();
    const { errors, handlerErrors, handelClearErrors } = useCheckValidationErrors();
    const walletAddress = useSelector((state) => state.auth.walletAddress);

    const navigate = useNavigate();

    const { data: collections, isLoading: isCollectionsLoading } = useGetCollectionsQuery({
        page: 1,
        pageSize: 1000,
    });

    const { data: pack, isLoading: isPackLoading } = useGetPackQuery(
        { id },
        {
            skip: !id || !isEdit,
        },
    );

    const { data: packTokens, isLoading: isPackTokensLoading } = useGetFilteredTokensQuery(
        {
            page: 1,
            pageSize: 1000,
            packId: pack ? pack.id : '',
        },
        {
            skip: !pack || !pack.id || !isEdit,
        },
    );

    const [onCreatePackRequest, { data: createdPackData, isLoading, error, isSuccess, reset }] =
        useCreatePackMutation();

    const [
        onUpdatePackRequest,
        {
            isLoading: isPackUpdatingProccessing,
            error: updatePackError,
            isSuccess: isPackUpdatingSuccessfully,
            reset: resetPackUpdate,
        },
    ] = useUpdatePackMutation();

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const [packId, setPackId] = useState(null);
    const [name, setName] = useState('');
    const [tokenCommonName, setTokenCommonName] = useState('Unique card #');
    const [numbering, setNumbering] = useState('1');
    const [tokenPrice, setTokenPrice] = useState(9900);
    const [tokenIdForPayment, setTokenIdForPayment] = useState();
    const [creatorRoyalty, setCreatorRoyalty] = useState(4.5);
    const [investorRoyalty, setInvestorRoyalty] = useState(0.5);
    const [isTokenNameEqualFileName, setIsTokenNameEqualFileName] = useState(false);
    const [isAuction, setIsAuction] = useState(false);
    const [isNoPrice, setIsNoPrice] = useState(false);
    const [properties, setProperties] = useState([]);
    const [description, setDescriprion] = useState('');
    const [levels, setLevels] = useState([]);
    const [stats, setStats] = useState([]);
    const [priceType, setPriceType] = useState('fixed');
    const [percent,setPercent] = useState(null)

    const [creatorRoyaltyDestribution, setCreatorRoyaltyDestribution] = useState([
        {
            percentage: '100',
            wallet: walletAddress,
        },
    ]);
    const [incomeRoyaltyDestribution, setIncomeRoyaltyDestribution] = useState([
        {
            percentage: '100',
            wallet: walletAddress,
        },
    ]);
    const [isSendData, setIsSendData] = useState(false);

    const [collectionId, setCollectionId] = useState('');

    const [unlockable, setUnlockable] = useState(false);
    const [unlockableContent, setUnlockableContent] = useState('');

    const [isTokenUploadStarted, setIsTokenUploadStarted] = useState(false);

    const selectedCollection = useMemo(() => {
        if (!collectionId || !collections || !collections.results) {
            return null;
        }

        return collections.results.find((c) => c.id === collectionId);
    }, [collections, collectionId]);

    const handleClose = () => {
        setName('');
        setTokenCommonName('Unique card #');
        setNumbering('1');
        setTokenPrice(9900);
        setInvestorRoyalty(4.5);
        setCreatorRoyalty(0.5);
        setIsTokenNameEqualFileName(false);
        setIsAuction(false);
        setIsNoPrice(false);
        setProperties([]);
        setDescriprion('');
        setLevels([]);
        setStats([]);
        setCreatorRoyaltyDestribution([
            {
                percentage: '100',
                wallet: walletAddress,
            },
        ]);
        setIncomeRoyaltyDestribution([
            {
                percentage: '100',
                wallet: walletAddress,
            },
        ]);
        setCollectionId('');
        setUnlockable(false);
        setIsTokenUploadStarted(false);
        setUnlockableContent('');
        setIsSendData(false);
    };

    const onSavePackHandler = useCallback(() => {
        setIsSendData(true);
        let status_price = 'price';

        if (isAuction) {
            status_price = 'auction';
        }

        if (isNoPrice) {
            status_price = 'no_price';
        }

        if (!collectionId || !name || !tokenPrice || !description || !creatorRoyalty) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: 'Fill all required fields',
            });

            return;
        }

        if (
            Number(selectedCollection.percentage_fee) !==
            Number(investorRoyalty) + Number(creatorRoyalty)
        ) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: 'The sum of investor and creator royalty should be equal to collection percentage fee',
            });
            return;
        }
        const incomeSumRoyaltyPerc = incomeRoyaltyDestribution.reduce(
            (a, c) => a + Number(c.percentage),
            0,
        );

        const creatorSumRoyaltyPerc = creatorRoyaltyDestribution.reduce(
            (a, c) => a + Number(c.percentage),
            0,
        );

        if (incomeSumRoyaltyPerc < 100) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: 'The total royalties of the income must be equal to 100%',
            });

            return;
        }

        if (creatorSumRoyaltyPerc < 100) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: 'The total royalties of the creators must be equal to 100%',
            });

            return;
        }
        
        const data = {
            collection: collectionId,
            name: name,
            price: Number(`${tokenPrice}00`),
            currency_token: tokenIdForPayment,
            status_price,
            investor_royalty: Number(investorRoyalty),
            creator_royalty: Number(creatorRoyalty),
            description,
            unlockable: unlockable,
           
            unlockable_content: unlockableContent,
            income_distribution: incomeRoyaltyDestribution.map((el) => ({
                wallet: el.wallet,
                percent: Number(el.percentage),
            })),
            creator_royalty_distribution: creatorRoyaltyDestribution.map((el) => ({
                wallet: el.wallet,
                percent: Number(el.percentage),
            })),
            properties: properties.map((p) => {
                return {
                    name: p.name,
                    type: p.type,
                };
            }),
        };


        if (percent) {
            data.percent = percent
        }

        // if (tokenPrice === 'percent') {
        //     data.price_type = 'percent';
        //     data.price =
        // } else {
        //     data.price_type = 'fixed';
        //     data.price = Number(`${tokenPrice}00`);
        // }

        if (isEdit) {
            onUpdatePackRequest({
                id,
                data,
            })
                .unwrap()
                .then(() => {
                    if (pageId && collId && accountId) {
                        localStorage.setItem('isDelay', 'true');
                        navigate(`/admin/statistics2/packs/${pageId}/${collId}/${accountId}`);
                    }
                })
                .catch((err) => {
                    handlerErrors(err.data);
                })
                .finally(() => {
                    setIsSendData(false);
                });
        } else {
            onCreatePackRequest(data)
                .unwrap()
                .then(() => {
                    // handleClose()
                })
                .catch((err) => {
                    handlerErrors(err.data);
                })
                .finally(() => {
                    setIsSendData(false);
                });
        }
    }, [
        isEdit,
        id,
        collectionId,
        name,
        tokenPrice,
        tokenIdForPayment,
        incomeRoyaltyDestribution,
        creatorRoyaltyDestribution,
        description,
        unlockable,
        unlockableContent,
        isAuction,
        isNoPrice,
        investorRoyalty,
        creatorRoyalty,
        properties,
        selectedCollection,
        tokenPrice,
        priceType,
        percent
    ]);

    const tokensDataToUpload = useMemo(() => {
        return {
            pack: packId,
            currency_token: tokenIdForPayment,
            investor_royalty: investorRoyalty,
            creator_royalty: creatorRoyalty,
        };
    }, [packId, tokenIdForPayment, investorRoyalty, creatorRoyalty]);

    useEffect(() => {
        if (isSuccess && createdPackData) {
            setPackId(createdPackData.id);

            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Pack successfuly created, now you are able to upload pack tokens',
            });
        }
    }, [isSuccess, createdPackData]);

    useEffect(() => {
        if (pack && isEdit) {
            setPackId(pack.id);
            setName(pack.name);
            setTokenPrice(String(Number(pack.price)).slice(0, String(pack.price).length - 2));
            setTokenIdForPayment(pack.currency_token);
            setInvestorRoyalty(String(Number(pack.investor_royalty)));
            setCreatorRoyalty(String(Number(pack.creator_royalty)));
            setIsAuction(pack.status_price === 'auction');
            setIsNoPrice(pack.status_price === 'no_price');
            setDescriprion(pack.description);
            setProperties(pack.properties || []);
            setLevels(pack.levels_stats || []);
            setStats(pack.levels_stats || []);
            setCreatorRoyaltyDestribution(
                pack.creator_royalty_distribution.map((rd) => ({
                    id: rd.id,
                    percentage: Number(rd.percent),
                    wallet: rd.wallet,
                })),
            );
            setIncomeRoyaltyDestribution(
                pack.income_distribution.map((ind) => ({
                    id: ind.id,
                    percentage: Number(ind.percent),
                    wallet: ind.wallet,
                })),
            );

            setCollectionId(pack.collection);

            setUnlockable(pack.unlockable);
            setUnlockableContent(pack.unlockable_content);
        }
    }, [pack, isEdit]);

    useEffect(() => {
        if (error) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(error),
            });
        }
    }, [error]);

    useEffect(() => {
        if (updatePackError) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(updatePackError),
            });
        }
    }, [updatePackError]);

    useEffect(() => {
        if (isPackUpdatingSuccessfully) {
            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Pack successfuly updated',
            });
        }
    }, [isPackUpdatingSuccessfully]);

    const handleReset = () => {
        reset();
        resetPackUpdate();
    };

    useEffect(
        () => () => {
            handleReset();
            setTokenIdForPayment();
        },
        [],
    );

    useEffect(() => {
        if (selectedCollection && selectedCollection.percentage_fee) {
            const result =
                Math.round(
                    (Number(selectedCollection.percentage_fee) -
                        Number(creatorRoyalty) +
                        Number.EPSILON) *
                        10,
                ) / 10;
            setInvestorRoyalty(result);
        }
    }, [creatorRoyalty]);

    useEffect(() => {
        if (selectedCollection && selectedCollection.percentage_fee) {
            const result =
                Math.round(
                    (Number(selectedCollection.percentage_fee) -
                        Number(investorRoyalty) +
                        Number.EPSILON) *
                        10,
                ) / 10;
            setCreatorRoyalty(result);
        }
    }, [investorRoyalty]);

    if (isCollectionsLoading || isPackLoading || isPackTokensLoading) {
        return (
            <CenteredContainer>
                <Loader />
            </CenteredContainer>
        );
    }

    return (
        <div className="default__padding createpage">
            <div className="container">
                <div className="createpage__inner">
                    <div className="createpage__content--wrap">
                        <h2 className="title left">{isEdit ? 'Обновить Пак' : 'Создать Пак'}</h2>

                        <div className="create__content">
                            <TokenCommonFieldsForm
                                // setPriceType={setPriceType}
                                type={'pack'}
                                percent={percent}
                                setPercent={setPercent}
                                isTokenUploadStarted={isTokenUploadStarted}
                                collections={collections}
                                tokenCommonName={tokenCommonName}
                                setTokenCommonName={setTokenCommonName}
                                numbering={numbering}
                                setNumbering={setNumbering}
                                tokenPrice={tokenPrice}
                                setTokenPrice={setTokenPrice}
                                tokenIdForPayment={tokenIdForPayment}
                                setTokenIdForPayment={setTokenIdForPayment}
                                investorRoyalty={investorRoyalty}
                                setInvestorRoyalty={setInvestorRoyalty}
                                creatorRoyalty={creatorRoyalty}
                                setCreatorRoyalty={setCreatorRoyalty}
                                isTokenNameEqualFileName={isTokenNameEqualFileName}
                                setIsTokenNameEqualFileName={setIsTokenNameEqualFileName}
                                isAuction={isAuction}
                                setIsAuction={setIsAuction}
                                isNoPrice={isNoPrice}
                                setIsNoPrice={setIsNoPrice}
                                properties={properties}
                                setProperties={setProperties}
                                description={description}
                                setDescriprion={setDescriprion}
                                levels={levels}
                                setLevels={setLevels}
                                stats={stats}
                                setStats={setStats}
                                creatorRoyaltyDestribution={creatorRoyaltyDestribution}
                                setCreatorRoyaltyDestribution={setCreatorRoyaltyDestribution}
                                incomeRoyaltyDestribution={incomeRoyaltyDestribution}
                                setIncomeRoyaltyDestribution={setIncomeRoyaltyDestribution}
                                collectionId={collectionId}
                                setCollectionId={setCollectionId}
                                unlockable={unlockable}
                                setUnlockable={setUnlockable}
                                unlockableContent={unlockableContent}
                                setUnlockableContent={setUnlockableContent}
                                name={name}
                                isCollectionsLoading={isCollectionsLoading}
                                setName={setName}
                                isSendData={isSendData}
                                errors={errors}
                                handelClearErrors={handelClearErrors}
                            />

                            <UploadManyTokensForm
                                numbering={numbering}
                                tokenCommonName={tokenCommonName}
                                tokenPrice={tokenPrice}
                                investorRoyalty={investorRoyalty}
                                creatorRoyalty={creatorRoyalty}
                                isTokenNameEqualFileName={isTokenNameEqualFileName}
                                setIsTokenUploadStarted={setIsTokenUploadStarted}
                                isAbleToUpload={Boolean(packId)}
                                requiredError={
                                    Boolean(packId)
                                        ? 'Upload at least one token'
                                        : 'Create pack first'
                                }
                                tokensDataToUpload={tokensDataToUpload}
                                isTokenUploadStarted={isTokenUploadStarted}
                                preloadedTokens={packTokens ? packTokens.results : []}
                                handlerErrors={handlerErrors}
                            />
                        </div>

                        <div className="create__button--content">
                            {isLoading || isPackUpdatingProccessing ? (
                                <button className="button create__button">Loading...</button>
                            ) : (
                                <button
                                    className="button create__button default__hover"
                                    onClick={onSavePackHandler}
                                >
                                    {isEdit ? 'Save changes' : 'Create pack'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CreatePack);
