import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import styles from './index.module.css';
import base from '../../styles/base.module.css';
import Telegram from '../../icons/Telegram';
import Search from '../../icons/Search';
import Wallet from '../../icons/Wallet';
import User from '../../icons/User';
import Menu from '../../icons/Menu';
import Cross from '../../icons/Cross';
import Vk from '../../icons/Vk';
import Telegram2 from '../../icons/Telegram2';
import Instagram from '../../icons/Instagram';
import Youtube from '../../icons/Youtube';
import Twitter from '../../icons/Twitter';
import ArrowLink from '../../icons/ArrowLink';
import { WalletModal } from './modal/Wallet';
// import { scrollToHash } from '../../pages/MainNew/components/Welcome/Welcome';
import { useLocation } from 'react-router-dom';

const HEADER_LINKS = [
    {
        title: 'Как это работает',
        path: '#how_it_work',
        id: 2,
    },
    {
        title: 'Этапы и сроки',
        path: '#stages_and_deadlines',
        id: 3,
    },
    {
        title: 'Тарифы',
        path: '#rate',
        id: 4,
    },
    {
        title: 'Отзывы',
        path: '#reviews',
        id: 5,
    },
    {
        title: 'Контакты',
        path: '#footer',
        id: 6,
    },
];

const HeaderNew = ({ withBackgraund = false }) => {
    const [menu, setMenu] = React.useState(false);
    const [profile, setProfile] = React.useState(false);
    const [dropdown, setDropdown] = React.useState(false);
    const [connect, setConnect] = React.useState(false);
    const [linksList, setLinksList] = React.useState(false);
    const [search, setSearch] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const pages = useSelector((state) => state.pages.entities);
    
    const email = useSelector((state) => state.auth.email);
    const isAuth = useSelector((state) => state.auth.isAuth);
    const auth = useSelector((state) => state.auth);
    // console.log(auth)
    const location = useLocation();
    // const user 
    const scrollToHash = (hash) => {
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                    top: elementPosition,
                    behavior: 'smooth',
                });
                location.hash = '';
            }
        }
    };

    const navigate = useNavigate();
    const searchBrand = () => {
        setMenu(false);
        navigate(`/brandsnew?search=${searchText}`);
    };

    return (
        <>
            <header
                className={cn(styles.header, {
                    [styles.withBackgraund]: withBackgraund,
                })}
            >
                <div className={base.container}>
                    <div className={styles.headerInner}>
                        <Link to="/" className={styles.headerLogoInner}>
                            <img
                                src="/assets/img/logo.svg"
                                alt="logo"
                                className={styles.headerLogo}
                            />
                        </Link>

                        <div className={styles.headerContent}>
                            <div className={styles.headerNav}>
                                <Link className={styles.headerDropdownTitle} to="/">
                                    Владельцы
                                </Link>

                                <div
                                    className={styles.headerDropdownInner}
                                    onMouseLeave={() => setLinksList(false)}
                                >
                                    <p
                                        className={styles.headerDropdownTitle}
                                        onMouseEnter={() => setLinksList(true)}
                                        onClick={() => navigate(`/brandsnew/collections`)}
                                    >
                                        Коллекции
                                        <svg
                                            width="8"
                                            height="8"
                                            viewBox="0 0 8 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M1.76462 2.76365C1.8948 2.63347 2.10585 2.63347 2.23603 2.76365L4.00033 4.52794L5.76462 2.76365C5.8948 2.63347 6.10585 2.63347 6.23603 2.76365C6.3662 2.89382 6.3662 3.10488 6.23603 3.23505L4.23603 5.23505C4.10585 5.36523 3.8948 5.36523 3.76462 5.23505L1.76462 3.23505C1.63445 3.10488 1.63445 2.89382 1.76462 2.76365Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </p>

                                    {linksList && (
                                        <div className={styles.headerDropdown}>
                                            <div className={styles.headerDropdownContentWrapper}>
                                                <div className={styles.headerDropdownContent}>
                                                    {Object.values(pages).map((page) => (
                                                        <p
                                                            // to={`/brandsnew/collections?cat=${page.id}`}
                                                            className={styles.headerDropdownLink}
                                                            onClick={() => {
                                                                setLinksList(false);

                                                                navigate(
                                                                    `/brandsnew/collections?cat=${page.id}`,
                                                                );
                                                            }}
                                                        >
                                                            {page.name}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <Link className={styles.headerDropdownTitle} to="/brands">
                                    Брендам
                                </Link>

                                {isAuth && auth.userRole == 'admin' && (
                                    <Link to="/admin" className={styles.headerLink}>
                                        Админ
                                    </Link>
                                )}
                            </div>

                            <div className={styles.headerPoints}>
                                <a
                                    href="https://t.me/check_brand"
                                    target="_blank"
                                    className={cn(styles.headerPoint, styles.headerMobilePoint)}
                                >
                                    <Telegram />
                                </a>

                                <div
                                    className={styles.headerPoint}
                                    onClick={() => setSearch((prev) => !prev)}
                                >
                                    <Search />
                                </div>

                                <div
                                    className={cn(styles.headerPoint, styles.headerPointMobile)}
                                    onClick={() => {
                                        if (isAuth) {
                                            setProfile(true);
                                        } else {
                                            setConnect(true);
                                        }
                                    }}
                                >
                                    <Wallet />
                                </div>

                                <div
                                    className={styles.headerPoint}
                                    onClick={() => {
                                        if (isAuth) {
                                            navigate(`/profileNew/${auth.walletAddress}`);
                                        } else {
                                            setConnect(true);
                                        }
                                    }}
                                >
                                    <User />
                                </div>

                                <div
                                    className={cn(styles.headerMenu, {
                                        [styles.active]: menu || profile,
                                    })}
                                    onClick={() => setMenu(true)}
                                >
                                    <Menu />
                                    <Cross className={styles.headerMenuCross} />

                                    <span>Меню</span>
                                </div>

                                <div
                                    className={cn(styles.headerSearchInner, {
                                        [styles.active]: search,
                                    })}
                                >
                                    <input
                                        type="text"
                                        placeholder="Введите запрос"
                                        className={styles.headerSearch}
                                        onChange={(e) => {
                                            setSearchText(e.target.value);
                                        }}
                                    />

                                    <button
                                        className={styles.headerSearchButton}
                                        onClick={searchBrand}
                                    >
                                        Найти
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div
                className={cn(styles.headerMenuOuter, {
                    [styles.active]: menu,
                })}
                onClick={() => setMenu(false)}
            >
                <div className={styles.headerMenuOuterWrapper} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.headerMenuContent}>
                        <Cross className={styles.headerCross} onClick={() => setMenu(false)} />

                        <div className={styles.headerMenuMobileInner}>
                            <button
                                className={cn(styles.headerMenuMobileLink, {
                                    [styles.active]: dropdown,
                                })}
                                onClick={() => setDropdown((prev) => !prev)}
                            >
                                Коллекции
                                <svg
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M1.76462 2.76365C1.8948 2.63347 2.10585 2.63347 2.23603 2.76365L4.00033 4.52794L5.76462 2.76365C5.8948 2.63347 6.10585 2.63347 6.23603 2.76365C6.3662 2.89382 6.3662 3.10488 6.23603 3.23505L4.23603 5.23505C4.10585 5.36523 3.8948 5.36523 3.76462 5.23505L1.76462 3.23505C1.63445 3.10488 1.63445 2.89382 1.76462 2.76365Z"
                                        fill="white"
                                        fillOpacity="0.5"
                                    />
                                </svg>
                            </button>

                            {dropdown && (
                                <div className={styles.dropdownMenu}>
                                    {Object.values(pages).map((page) => (
                                        <p
                                            // to={`/brandsnew/collections?cat=${page.id}`}
                                            className={styles.headerDropdownLink}
                                            onClick={() => {
                                                setLinksList(false);
                                                setMenu(false);
                                                navigate(`/brandsnew/collections?cat=${page.id}`);
                                            }}
                                        >
                                            {page.name}
                                        </p>
                                    ))}
                                </div>
                            )}
                            {isAuth && auth.userRole == 'admin' && (
                                <Link to="admin" className={styles.headerMenuMobileLink}>
                                    Админ
                                </Link>
                            )}

                            <div className={styles.headerMenuMobileWrap}>
                                <div
                                    className={cn(styles.headerPoint, {
                                        [styles.active]: search,
                                    })}
                                    onClick={() => setSearch((prev) => !prev)}
                                >
                                    <Search />
                                </div>

                                <div
                                    className={styles.headerPoint}
                                    onClick={() => {
                                        if (isAuth) {
                                            navigate(`/profileNew/${auth.id}`);
                                        } else {
                                            setConnect(true);
                                        }
                                    }}
                                >
                                    <User />
                                </div>
                            </div>

                            {search && (
                                <div className={styles.headerMenuSearchInner}>
                                    <input
                                        type="text"
                                        placeholder="Введите запрос"
                                        className={styles.headerMenuSearch}
                                        onChange={(e) => {
                                            setSearchText(e.target.value);
                                        }}
                                    />

                                    <button
                                        className={styles.headerMenuSearchButton}
                                        onClick={searchBrand}
                                    >
                                        Найти
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className={styles.headerMenuWrapper}>
                            <p className={styles.headerMenuTitle}>Меню</p>
                            <nav className={styles.headerMenuNav}>
                                <Link to="/" className={styles.headerMenuNavLink}>
                                    Владельцы и бренды
                                    <ArrowLink />
                                </Link>

                                <Link to="/brands" className={styles.headerMenuNavLink}>
                                    Брендам
                                    <ArrowLink />
                                </Link>

                                {HEADER_LINKS.map((link) => (
                                    <p
                                        // to={link.path}
                                        key={link.id}
                                        className={styles.headerMenuNavLink}
                                        onClick={() => {
                                            scrollToHash(link.path);
                                        }}
                                    >
                                        {link.title}
                                        <ArrowLink />
                                    </p>
                                ))}
                            </nav>
                            <div className={styles.headerSocial}>
                                <a href="#" className={styles.headerSocialLink} target="_blank">
                                    <Vk />
                                </a>

                                <a href="#" className={styles.headerSocialLink} target="_blank">
                                    <Twitter />
                                </a>

                                <a
                                    href="https://www.youtube.com/@bratouverie4787"
                                    className={styles.headerSocialLink}
                                    target="_blank"
                                >
                                    <Youtube />
                                </a>

                                <a
                                    href="https://www.instagram.com/bratouverie/"
                                    className={styles.headerSocialLink}
                                    target="_blank"
                                >
                                    <Instagram />
                                </a>

                                <a
                                    href="https://t.me/bratouverie"
                                    className={styles.headerSocialLink}
                                    target="_blank"
                                >
                                    <Telegram2 />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WalletModal
                connect={connect}
                profile={profile}
                setConnect={setConnect}
                setProfile={setProfile}
            />
        </>
    );
};

export default HeaderNew;
