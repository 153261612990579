import React, { useEffect, useState } from 'react';
import styles from '../../../index.module.css';
import { handleSendCodeByVerifyStep } from '../utils';
export const RepeatCode = ({ verifyStep, contacts, setError }) => {
    const [currentTime, setCurrentTime] = useState(45);

    useEffect(() => {
        if (currentTime <= 0) return;
        const timerId = setTimeout(function tick() {
            setCurrentTime((prev) => {
                if (prev <= 1) {
                    clearTimeout(timerId);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearTimeout(timerId);
    }, [currentTime]);

    const handleRepeatCode = () => {
        handleSendCodeByVerifyStep(verifyStep, contacts, setError);
        setCurrentTime(45);
    };

    return (
        <>
            <p className={styles.inputRequired}>
                *Новый код возможно получить через {currentTime} секунд
            </p>
            <button
                className={styles.verifyModalResend}
                onClick={handleRepeatCode}
                disabled={currentTime > 0}
                style={{
                    color: currentTime > 0 ? 'gray' : '#207AE1',
                }}
            >
                Отправить код повторно
            </button>
        </>
    );
};
